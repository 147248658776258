import React, { Component } from "react";
import { connect } from "react-redux";
import { isAndroid, isIOS } from "react-device-detect";
import Container from "react-bootstrap/Container";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Modal from "react-bootstrap/Modal";
import NavDropdown from "react-bootstrap/NavDropdown";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import "./style.css";
import {
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import moment from "moment";
import AdminNavBar from "../../components/UI/AdminNavBar/index";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import * as Sentry from "@sentry/react";

class StockAdminApprovedOrdersScreen extends Component {
  state = {
    isSuperAdminLoggedIn:
      getFromLocalStorage("isSuperAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != "" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != null
        ? getFromLocalStorage("isSuperAdminLoggedIn")
        : "false",
    isAccountantLoggedIn:
      getFromLocalStorage("isAccountantLoggedIn") != "undefined" &&
      getFromLocalStorage("isAccountantLoggedIn") != "" &&
      getFromLocalStorage("isAccountantLoggedIn") != null
        ? getFromLocalStorage("isAccountantLoggedIn")
        : "false",
    isStockAdminLoggedIn:
      getFromLocalStorage("isStockAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isStockAdminLoggedIn") != "" &&
      getFromLocalStorage("isStockAdminLoggedIn") != null
        ? getFromLocalStorage("isStockAdminLoggedIn")
        : "false",
    isEventCoordinatorLoggedIn:
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != null
        ? getFromLocalStorage("isEventCoordinatorLoggedIn")
        : "false",
    stockList: new Array(),
    user_id: getFromLocalStorage("stockUserId"),
    token: getFromLocalStorage("stockToken"),
    quantitiesArray: [],
    hasGSTChecked: [],
    email:
      getFromLocalStorage("stockAdminEmail") &&
      getFromLocalStorage("stockAdminEmail") != ""
        ? getFromLocalStorage("stockAdminEmail")
        : "",
    password:
      getFromLocalStorage("stockAdminPassword") &&
      getFromLocalStorage("stockAdminPassword") != ""
        ? getFromLocalStorage("stockAdminPassword")
        : "",
    superAdminEmail:
      getFromLocalStorage("superAdminEmail") &&
      getFromLocalStorage("superAdminEmail") != ""
        ? getFromLocalStorage("superAdminEmail")
        : "",
    superAdminPassword:
      getFromLocalStorage("superAdminPassword") &&
      getFromLocalStorage("superAdminPassword") != ""
        ? getFromLocalStorage("superAdminPassword")
        : "",
    stockAdminUserData:
      getFromLocalStorage("stockAdminUserData") &&
      JSON.parse(getFromLocalStorage("stockAdminUserData"))
        ? JSON.parse(getFromLocalStorage("stockAdminUserData"))
        : new Array(),
    availableStatus: ["enabled", "disabled"],
    pricesArray: [],
    isUpdatingStockList: false,
    statusArray: [],
    kitchacoLocations: [],
    countingAdviceArray: [],
    kitchacoLocation:
      getFromLocalStorage("kitchacoLocation") != "undefined" &&
      getFromLocalStorage("kitchacoLocation") != "" &&
      getFromLocalStorage("kitchacoLocation") != null
        ? getFromLocalStorage("kitchacoLocation")
        : 1,
    allLocationsArray: [],
    updatedMessage: [],
    showUpdateConfirmation: false,
  };

  logout = () => {
    deleteFromLocalStorage("isSuperAdminLoggedIn", "false");
    deleteFromLocalStorage("isEventCoordinatorLoggedIn", "false");
    deleteFromLocalStorage("isAccountantLoggedIn", "false");
    deleteFromLocalStorage("isStockAdminLoggedIn", "false");
    this.setState({ isEventCoordinatorLoggedIn: "false" });
    this.setState({ isAccountantLoggedIn: "false" });
    this.setState({ isStockAdminLoggedIn: "false" });
    this.setState({ isSuperAdminLoggedIn: "false" });
    window.location.href = "/admin";
  };

  getKitchacoLocationData() {
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_stock_vendors",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        console.log("KitchacoLocations=> ", dataResponse);
        if (dataResponse.success) {
          this.setState({ kitchacoLocations: dataResponse.vendors });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  fetchStockListFromServer = () => {
    const {
      superAdminEmail,
      superAdminPassword,
      kitchacoLocation,
    } = this.state;
  
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
  
    const payload = JSON.stringify({
      payload: {
        body: {
          email: superAdminEmail,
          password: superAdminPassword,
          query_type: "get_admin_stock_list",
          kitchaco_location: parseInt(kitchacoLocation),
        },
      },
    });
  
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: payload,
      redirect: "follow",
    };
  
    fetch(config.BASE_URI + apiPaths.adminData, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          const tempGSTArray = [];
          const tempExpiryArray = [];
          const tempPriceArray = [];
          const tempStatusArray = [];
          const tempCountingAdviceArray = []; // Initialize advice array
  
          // Populate arrays from result data
          result.data.forEach((item) => {
            tempGSTArray.push(item.has_gst);
            tempExpiryArray.push(item.has_expiry);
            tempPriceArray.push(item.price);
            tempStatusArray.push(item.status);
            tempCountingAdviceArray.push(item.counting_message || ""); // Default to an empty string if advice is missing
          });
  
          this.setState({
            stockList: result.data,
            hasGSTChecked: tempGSTArray,
            hasExpiryChecked: tempExpiryArray,
            pricesArray: tempPriceArray,
            statusArray: tempStatusArray,
            countingAdviceArray: tempCountingAdviceArray, // Set counting advice array
          });
        } else {
          alert(result.error);
        }
      })
      .catch((error) => {
        console.error("Error fetching stock list:", error);
      });
  };

  containsObject = (obj, list) => {
    if (list) {
      for (var i = 0; i < list.length; i++) {
        if (list[i].stock_list_id == obj.stock_list_id) {
          return true;
        }
      }
    }
    return false;
  };

  handleChange(i, e) {
    this.setState({
      quantitiesArray: { ...this.state.quantitiesArray, [i]: e.target.value },
    });
  }

  onChangeKitchacoLocation(e) {
    this.setState({ kitchacoLocation: e.target.value }, () => {
      saveToLocalStorage("kitchacoLocation", this.state.kitchacoLocation);
      window.location.reload();
    });
  }

  componentDidMount() {
    const {
      isSuperAdminLoggedIn,
      isAccountantLoggedIn,
      isStockAdminLoggedIn,
    } = this.state;
    if (isSuperAdminLoggedIn === "true" || isStockAdminLoggedIn === "true") {
      this.getKitchacoLocationData();
      this.fetchStockListFromServer();
    } else {
      window.location.href = "/admin";
    }
  }

 

  updateStockList = () => {
    const {
      hasGSTChecked,
      hasExpiryChecked,
      pricesArray,
      stockList,
      statusArray,
      countingAdviceArray, // Include advice array
      email,
      password,
      superAdminEmail,
      superAdminPassword,
    } = this.state;
  
    this.setState({ isUpdatingStockList: true });
    const stockData = stockList.map((item, i) => ({
      id: item.id,
      qty_type_id: item.qty_type__id,
      name: item.name,
      price: pricesArray[i],
      has_expiry: hasExpiryChecked[i],
      has_gst: hasGSTChecked[i],
      status: statusArray[i],
      counting_advice: countingAdviceArray[i], // Include advice
      change_all_locations: false
    }));
  
    const payload = {
      email: email || superAdminEmail,
      password: password || superAdminPassword,
      stockData,
      query_type: "update_admin_stock_list",
    };
  
    fetch(config.BASE_URI + apiPaths.adminData, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ payload: { body: payload } }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({ isUpdatingStockList: false });
        if (result.success) {
          this.setState({
            updatedMessage: result.message,
            showUpdateConfirmation: true,
          });
        }
      })
      .catch((error) => {
        this.setState({ isUpdatingStockList: false });
        console.error(error);
      });
  };

  handleChangeAdvice = (i, e) => {
    const newAdviceArray = [...this.state.countingAdviceArray];
    newAdviceArray[i] = e.target.value;
    this.setState({ countingAdviceArray: newAdviceArray });
  };

  render() {
    const {
      isSuperAdminLoggedIn,
      isEventCoordinatorLoggedIn,
      isAccountantLoggedIn,
      isStockAdminLoggedIn,
    } = this.state;
    return (
      <div>
        <AdminNavBar
          isSuperAdminLoggedIn={isSuperAdminLoggedIn}
          isEventCoordinatorLoggedIn={isEventCoordinatorLoggedIn}
          isAccountantLoggedIn={isAccountantLoggedIn}
          isStockAdminLoggedIn={isStockAdminLoggedIn}
          logout={this.logout}
        ></AdminNavBar>
        {!isSuperAdminLoggedIn || !isStockAdminLoggedIn
          ? (window.location.href = "/admin")
          : this.renderHome()}
      </div>
    );
  }

  renderHome() {
    const {
      stockList,
      availableStatus,
      hasGSTChecked,
      hasExpiryChecked,
      pricesArray,
      isUpdatingStockList,
      statusArray,
      countingAdviceArray,
      kitchacoLocations,
      kitchacoLocation,
    } = this.state;
  
    return (
      <Container>
        <Row>
          <Col xs={12} className="header">
            <h2>Stock Ordering System</h2>
            <h3>Stock List</h3>
            <Row>
              <Col xs={12}>
                <h6>1. Disabled items will not appear on the customer's platform.</h6>
                <h6>2. Items with GST enabled will add a 10% price as GST.</h6>
                <h6>3. Updates with "Change All Locations" will reflect globally.</h6>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                {kitchacoLocations.length > 0 && (
                  <Form.Group>
                    <Form.Label>Vendors ({kitchacoLocations.length})</Form.Label>
                    <Form.Control
                      as="select"
                      size="sm"
                      value={kitchacoLocation}
                      onChange={(e) => this.onChangeKitchacoLocation(e)}
                    >
                      <option value={0}>All</option>
                      {kitchacoLocations.map((location, i) => (
                        <option key={i} value={location.user_ptr_id}>
                          {location.title}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
  
        {/* Desktop Layout */}
        <div className="d-none d-md-block">
          <Row className="table-header-row">
            <Col xs={1} className="table-header-col">ID</Col>
            <Col xs={2} className="table-header-col">Name</Col>
            <Col xs={1} className="table-header-col">Qty Type</Col>
            <Col xs={1} className="table-header-col">Price</Col>
            <Col xs={2} className="table-header-col">Counting Advice</Col>
            <Col xs={1} className="table-header-col">Has Expiry</Col>
            <Col xs={1} className="table-header-col">Has GST</Col>
            <Col xs={2} className="table-header-col">Status</Col>
          </Row>
          {stockList.map((item, i) => (
            <Row key={i} className="table-row">
              <Col xs={1} className="table-col">{item.id}</Col>
              <Col xs={2} className="table-col">
                <span
                  title={item.name}
                  className="text-truncate"
                  style={{ maxWidth: "100%" }}
                >
                  {item.name}
                </span>
              </Col>
              <Col xs={1} className="table-col">{item.qty_type__qty_type}</Col>
              <Col xs={1} className="table-col">
                <Form.Control
                  type="number"
                  value={pricesArray[i]}
                  onChange={(e) => this.handleChangePrice(i, e)}
                />
              </Col>
              <Col xs={2} className="table-col">
                <Form.Control
                  type="text"
                  placeholder="Enter advice"
                  value={countingAdviceArray[i] || ""}
                  onChange={(e) => this.handleChangeAdvice(i, e)}
                />
              </Col>
              <Col xs={1} className="table-col">
                <Form.Check
                  type="checkbox"
                  checked={hasExpiryChecked[i]}
                  onChange={(e) => this.onChangeHasExpiry(i, e)}
                />
              </Col>
              <Col xs={1} className="table-col">
                <Form.Check
                  type="checkbox"
                  checked={hasGSTChecked[i]}
                  onChange={(e) => this.onChangeHasGST(i, e)}
                />
              </Col>
              <Col xs={2} className="table-col">
                <Form.Control
                  as="select"
                  value={statusArray[i]}
                  onChange={(e) => this.onChangeStockListStatus(e, i)}
                >
                  {availableStatus.map((status, idx) => (
                    <option key={idx} value={status}>
                      {status}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Row>
          ))}
        </div>
  
        {/* Mobile Layout */}
        <div className="d-md-none">
          {stockList.map((item, i) => (
            <Card key={i} className="mb-3">
              <Card.Body>
                <h5 className="mb-2">ID: {item.id}</h5>
                <p>
                  <strong>Name:</strong> {item.name}
                </p>
                <p>
                  <strong>Qty Type:</strong> {item.qty_type__qty_type}
                </p>
                <Form.Group>
                  <Form.Label>Price</Form.Label>
                  <Form.Control
                    type="number"
                    value={pricesArray[i]}
                    onChange={(e) => this.handleChangePrice(i, e)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Counting Advice</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter advice"
                    value={countingAdviceArray[i] || ""}
                    onChange={(e) => this.handleChangeAdvice(i, e)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Has Expiry</Form.Label>
                  <Form.Check
                    type="checkbox"
                    checked={hasExpiryChecked[i]}
                    onChange={(e) => this.onChangeHasExpiry(i, e)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Has GST</Form.Label>
                  <Form.Check
                    type="checkbox"
                    checked={hasGSTChecked[i]}
                    onChange={(e) => this.onChangeHasGST(i, e)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    as="select"
                    value={statusArray[i]}
                    onChange={(e) => this.onChangeStockListStatus(e, i)}
                  >
                    {availableStatus.map((status, idx) => (
                      <option key={idx} value={status}>
                        {status}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Card.Body>
            </Card>
          ))}
        </div>
  
        <Row className="button-row">
          <Col xs={12}>
            <Button onClick={() => !isUpdatingStockList && this.updateStockList()}>
              {isUpdatingStockList ? "Updating..." : "Update"}
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
  

  renderUpdateConfirmation() {
    const { showUpdateConfirmation, updatedMessage } = this.state;
    return (
      <Modal
        show={showUpdateConfirmation}
        onHide={() => {
          this.setState({ showUpdateConfirmation: false }, () => {
            window.location.reload();
          });
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Updated Successfully</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            The following shows only those items for which check all locations
            was checked. All the other indvidual items have also been updated
            succesfully!
          </p>
          {updatedMessage.map((item) => {
            return (
              <p>
                {Object.keys(item)} updated at {Object.values(item)} location
              </p>
            );
          })}
        </Modal.Body>
      </Modal>
    );
  }

  onChangeStockListStatus = (e, i) => {
    this.setState({
      statusArray: { ...this.state.statusArray, [i]: e.target.value },
    });
  };

  onChangeHasExpiry = (i, e) => {
    this.setState({
      hasExpiryChecked: {
        ...this.state.hasExpiryChecked,
        [i]: e.target.checked,
      },
    });
  };

  onChangeHasGST = (i, e) => {
    this.setState({
      hasGSTChecked: { ...this.state.hasGSTChecked, [i]: e.target.checked },
    });
  };

  handleChangePrice = (i, e) => {
    this.setState({
      pricesArray: { ...this.state.pricesArray, [i]: e.target.value },
    });
  };

  onChangeAllLocations = (i, e) => {
    this.setState({
      allLocationsArray: {
        ...this.state.allLocationsArray,
        [i]: e.target.checked,
      },
    });
  };
}
function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    stockAdminLoggedIn: state.appReducer.stockAdminLoggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
    paymentStatus: state.appReducer.paymentStatus,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(StockAdminApprovedOrdersScreen, {
    fallback: <ErrorFallbackComponent />,
  })
);
