import React, { useState } from "react";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import { getFromLocalStorage } from "../../store";
import * as Sentry from "@sentry/react";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";

import { useEffect } from "react";

import "./style.css";
import { Container, Row, Col } from "react-bootstrap";
import VendorNavBar from "../VendorApplyEventScreen/Component/VendorNavBar";

import ClosingStockFormModal from "./ClosingStockFormModal";
import EditClosingStock from "./EditClosingStock";
import Loader from "../../components/UI/Loader";

const VendorClosingStockScreen = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [eventId, setEventId] = useState(null);

  const [vendorId, setVendorId] = useState("");
  const [vendors, setVendors] = useState([]);

  const [fetchedEvents, setFetchedEvents] = useState([]);

  const [isOperatorLoggedIn, setIsOperatorLoggedIn] = useState(false);
  const [isTruckManager, setIsTruckManager] = useState(false);
  const [isOperatorOperations, setIsOperatorOperations] = useState(false);

  const [closingStockData, setClosingStockData] = useState([]);

  const getClosingStockData = (vendor_id, event_id) => {
    setIsLoading(true);

    const operator = JSON.parse(getFromLocalStorage("Operator"));
    if (!operator || operator.length === 0) {
      alert("Operator not found. Please Log In again to Continue.");
      return;
    }

    const operatorId = operator[0].id;

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "fetch_event_based_closing_stock",
          vendor: Number(vendor_id),
          event_id: Number(event_id),
          operator_id: operatorId,
        },
      },
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(config.BASE_URI + apiPaths.operatorStockData, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        setIsLoading(false);
        if (dataResponse.success === true) {
          setClosingStockData(dataResponse.closing_stock_details ?? []);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        Sentry.captureException(error);
        console.error(error);
      });
  };

  const fetchVendorIds = () => {
    setIsLoading(true);
    const operator = JSON.parse(getFromLocalStorage("Operator"));
    if (!operator || operator.length === 0) {
      alert("Operator not found. Please Log In again to Continue.");
      return;
    }

    const operatorId = operator[0].id;

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_operator_details_for_stall_ids",
          operator_id: operatorId,
        },
      },
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(config.BASE_URI + apiPaths.adminData, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setIsLoading(false);
        if (result.success === true) {
          setVendors(result.stalls ?? []);
        } else {
          alert(result.error);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
        Sentry.captureException(error);
      });
  };
  const getAllEvents = (vendorId) => {
    setIsLoading(true);

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_upcoming_events_for_operators",
          vendor_id: Number(vendorId),
        },
      },
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(config.BASE_URI + apiPaths.operatorData, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        setIsLoading(false);
        if (dataResponse.success === true) {
          setFetchedEvents(dataResponse.data ?? []);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        Sentry.captureException(error);
        console.error(error);
      });
  };

  useEffect(() => {
    fetchVendorIds();
  }, []);

  const renderNavBar = () => {
    return (
      <VendorNavBar
        isOperatorLoggedIn={isOperatorLoggedIn}
        isOperatorOperations={isOperatorOperations}
        isTruckManager={isTruckManager}
      ></VendorNavBar>
    );
  };

  return (
    <>
      {renderNavBar()}
      <div style={{ position: "relative" }}>
        <Loader loading={loading} />
        {!isLoading ? (
          <Container
            style={{
              borderWidth: 1,
              borderColor: "grey",
              borderStyle: "solid",
              borderRadius: 5,
              padding: 15,
            }}
          >
            <h2>Closing Stock</h2>
            <div style={{ marginBottom: 16 }}>
              <ClosingStockFormModal
                setLoading={setLoading}
                vendorId={vendorId}
                eventId={eventId}
              />
            </div>
            <div>
              <div>
                <label>Select Vendor:</label>
                <select
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    const selectedVendorId = e.target.value;
                    setVendorId(selectedVendorId);
                    if (selectedVendorId)
                      getAllEvents(Number(selectedVendorId));
                  }}
                  value={vendorId || ""}
                >
                  <option value="">Select Vendor</option>
                  {vendors.map((vendor, index) => (
                    <option key={index} value={vendor?.user_ptr_id}>
                      {vendor?.title}
                    </option>
                  ))}
                </select>
              </div>
              {vendorId && fetchedEvents && fetchedEvents.length ? (
                <div style={{ marginTop: 20, marginBottom: 32 }}>
                  <label>Select Event:</label>
                  <select
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      setEventId(e.target.value);
                      if (e.target.value)
                        getClosingStockData(vendorId, e.target.value);
                    }}
                    value={eventId || ""}
                  >
                    <option value="">Select Event</option>
                    {fetchedEvents.map((event, index) => (
                      <option key={event?.id} value={event?.id}>
                        {event?.name || ""}
                      </option>
                    ))}
                  </select>
                </div>
              ) : null}
            </div>
            {vendorId && eventId ? (
              <div style={{ marginBottom: 16 }}>
                <EditClosingStock
                  setLoading={setLoading}
                  vendorId={vendorId}
                  eventId={eventId}
                />
              </div>
            ) : null}

            {closingStockData && closingStockData.length ? (
              <Container>
                <Row className="table-header-row">
                  <Col xs={12} md={1} lg={1} className="table-header-col">
                    Sr.
                  </Col>
                  <Col xs={12} md={3} lg={3} className="table-header-col">
                    Item Name
                  </Col>
                  <Col xs={12} md={2} lg={2} className="table-header-col">
                    Closing Stock Qty
                  </Col>
                  <Col xs={12} md={2} lg={2} className="table-header-col">
                    Wasted Stock Qty
                  </Col>
                  <Col xs={12} md={2} lg={2} className="table-header-col">
                    Total Waste Cost
                  </Col>
                  <Col xs={12} md={2} lg={2} className="table-header-col">
                    Used Stock Total Cost
                  </Col>
                </Row>
                {closingStockData.map((item, idx) => {
                  return (
                    <Row key={item.id} className="table-row card-row">
                      <Col
                        xs={12}
                        md={1}
                        lg={1}
                        className="table-col closing-table-col"
                      >
                        <b className="mobile-only">Sr. No:</b> {idx + 1}
                      </Col>

                      <Col
                        xs={12}
                        md={3}
                        lg={3}
                        className="table-col closing-table-col text-green-500"
                      >
                        <b className="mobile-only">Item Name:</b>{" "}
                        {item.stocklist_item__name}
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        lg={2}
                        className="table-col closing-table-col text-green-500"
                      >
                        <b className="mobile-only">Closing Stock Quantity:</b>{" "}
                        {item.closing_stock_qty}
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        lg={2}
                        className="table-col closing-table-col text-green-500"
                      >
                        <b className="mobile-only">Wasted Stock Quantity:</b>{" "}
                        {item.stocked_wasted_qty}
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        lg={2}
                        className="table-col closing-table-col text-green-500"
                      >
                        <b className="mobile-only">Total Waste Cost:</b>{" "}
                        {item.total_waste_cost}
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        lg={2}
                        className="table-col closing-table-col text-green-500"
                      >
                        <b className="mobile-only"> Used Stock Total Cost:</b>{" "}
                        {item.total_cost_of_stock_used}
                      </Col>
                    </Row>
                  );
                })}
              </Container>
            ) : null}
          </Container>
        ) : (
          <div style={{ textAlign: "center" }}>
            <img src={require("../../assets/img/loading.gif")}></img>
          </div>
        )}
      </div>
    </>
  );
};

export default Sentry.withErrorBoundary(VendorClosingStockScreen, {
  fallback: <ErrorFallbackComponent />,
});
