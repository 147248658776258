import React, { Component } from "react";
import {
  Navbar,
  Nav,
  Form,
  Button,
  FormControl,
  NavDropdown,
  Image,
  Container,
  Row,
  Col,
  Carousel,
  Modal,
  Card,
  Media,
  ButtonGroup,
  FormGroup,
  Alert,
} from "react-bootstrap";
import * as Sentry from "@sentry/react";
import Footer from "../../components/UI/Footer";
import { theme } from "../../components/UI/theme";
import { connect } from "react-redux";
import "./style.css";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import { apiPaths } from "../../services/apiPath";

import api from "../../services/api";

import config from "../../services/apiConfig";
import moment from "moment";

import "react-phone-input-2/lib/style.css";
import { setBookingRequest } from "../../actions";

import {
  store,
  saveToLocalStorage,
  deleteFromLocalStorage,
  getFromLocalStorage,
} from "../../store";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import { withStyles } from "@material-ui/core/styles";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { applyToken } from "../../services/api";
import ResponsiveNavBar from "../../components/UI/ResponsiveNavBar";
import PlacesAutocomplete from "react-places-autocomplete";
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import ReadMoreReact from "read-more-react";
import TextField from "@material-ui/core/TextField";
import { AWS_URL } from "../../assets/Constants";
import { Helmet } from "react-helmet";
import Loader from "../../components/UI/Loader";

class BookATruckScreen extends Component {
  state = {
    eventType:
      getFromLocalStorage("bookingEventType") !== "" &&
      getFromLocalStorage("bookingEventType") !== "null"
        ? getFromLocalStorage("bookingEventType")
        : "",
    customerEventType:
      getFromLocalStorage("customerEventType") !== "" &&
      getFromLocalStorage("customerEventType") !== "null"
        ? getFromLocalStorage("customerEventType")
        : "",
    customerOtherEventType:
      getFromLocalStorage("customerOtherEventType") !== "" &&
      getFromLocalStorage("customerOtherEventType") !== "null"
        ? getFromLocalStorage("customerOtherEventType")
        : "",
    name:
      getFromLocalStorage("bookingName") !== "" &&
      getFromLocalStorage("bookingName") !== "null"
        ? getFromLocalStorage("bookingName")
        : "",
    email:
      getFromLocalStorage("bookingEmail") !== "" &&
      getFromLocalStorage("bookingEmail") !== "null"
        ? getFromLocalStorage("bookingEmail")
        : "",
    phone:
      getFromLocalStorage("bookingPhone") !== "" &&
      getFromLocalStorage("bookingPhone") !== "null"
        ? getFromLocalStorage("bookingPhone")
        : "",
    eventLocation:
      getFromLocalStorage("bookingLocation") !== "" &&
      getFromLocalStorage("bookingLocation") !== "null"
        ? getFromLocalStorage("bookingLocation")
        : "",
    eventStartDateTime:
      getFromLocalStorage("bookingStartDateTime") !== "" &&
      getFromLocalStorage("bookingStartDateTime") !== "null"
        ? getFromLocalStorage("bookingStartDateTime")
        : "",
    eventEndDateTime:
      getFromLocalStorage("bookingEndDateTime") !== "" &&
      getFromLocalStorage("bookingEndDateTime") !== "null"
        ? getFromLocalStorage("bookingEndDateTime")
        : "",
    numberOfGuests:
      getFromLocalStorage("bookingNumberOfGuests") !== "" &&
      getFromLocalStorage("bookingNumberOfGuests") !== "null"
        ? getFromLocalStorage("bookingNumberOfGuests")
        : "",
    totalBudget:
      getFromLocalStorage("bookingTotalBudget") !== "" &&
      getFromLocalStorage("bookingTotalBudget") !== "null"
        ? getFromLocalStorage("bookingTotalBudget")
        : "",

    showError: false,
    continueClicked: false,
    powerRequirements: "",
    availableCuisines: [],
    step:
      getFromLocalStorage("step") !== "" &&
      getFromLocalStorage("step") !== "null"
        ? getFromLocalStorage("step")
        : "1",
    minCallOutFee: false,
    invalidPhone: false,
    invalidEmail: false,
    emptyName: false,
    vendor_id: -1,
    specificTruck: false,
    vendor: {},
    showThankYouModal: false,
  };

  componentDidMount() {
    const { eventType } = this.state;
    var page = window.location.href.substring(
      window.location.href.lastIndexOf("/") + 1
    );
    if (page.includes("cuisine")) {
      this.setState({ continueClicked: true, step: "3" });
    } else if (page.includes("vendor")) {
      let vendor_id = page.split(":")[1];
      this.getSpecificVendor(vendor_id);
      this.setState({
        vendor_id: vendor_id,
        specificTruck: true,
        eventType: "ftims",
      });
    } else {
      if (eventType != "catering" && eventType != "ftims") {
        this.setState({ step: 1 }, () => {
          deleteFromLocalStorage("eventType", "");
        });
      }
    }
    this.getAvailableCuisines();
  }
  handleChange = (eventLocation) => {
    const { dispatch } = this.props;
    this.setState({ eventLocation: eventLocation }, () => {});
  };

  handleSelect = (eventLocation) => {
    const { dispatch } = this.props;
    geocodeByAddress(eventLocation)
      .then((results) => {
        this.setState(
          { eventLocation: results[0].formatted_address },
          () => {}
        );
        getLatLng(results[0]);
      })
      .then((latLng) => {})
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };

  bookATruck = (cuisine) => {
    const {
      eventType,
      name,
      email,
      phone,
      eventLocation,
      eventStartDateTime,
      eventEndDateTime,
      numberOfGuests,
      continueClicked,
      customerEventType,
      customerOtherEventType,
      totalBudget,
      powerRequirements,
    } = this.state;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.bookATruck;
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "book-a-truck",
          event_type: eventType,
          name: name,
          email: email,
          phone: phone,
          event_location: eventLocation,
          event_start_date_time: eventStartDateTime,
          event_end_date_time: eventEndDateTime,
          number_of_guests: numberOfGuests,
          cuisine: cuisine,
          total_budget: totalBudget,
          power_requirements: powerRequirements,
          customer_event_type:
            customerEventType == "Other - Please specify"
              ? customerOtherEventType
              : customerEventType,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.success == true) {
          saveToLocalStorage("bookingRequestId", response.booking_request_id);
          window.location.href = "/catering-pack:" + cuisine;
        }
      });
  };

  submitFtimsForm = () => {
    const {
      eventType,
      name,
      email,
      phone,
      eventLocation,
      eventStartDateTime,
      eventEndDateTime,
      numberOfGuests,
      continueClicked,
      vendor_id,
    } = this.state;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.bookATruck;
    this.setState({ bookLoading: true });

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "book-a-specific-truck",
          event_type: eventType,
          name: name,
          email: email,
          phone: phone,
          event_location: eventLocation,
          event_start_date_time: eventStartDateTime,
          event_end_date_time: eventEndDateTime,
          number_of_guests: numberOfGuests,
          vendor_id: vendor_id,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        this.setState({ bookLoading: false });

        if (response.success == true) {
          this.setState({ showThankYouModal: true });
        }
      });
  };

  getSpecificVendor = (vendor_id) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.bookATruck;
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get-specific-truck",

          vendor_id: vendor_id,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.success == true) {
          this.setState({ vendor: response.vendor });
        }
      });
  };

  getAvailableCuisines = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.bookATruck;
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get-available-cuisine",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.success == true) {
          this.setState(
            { availableCuisines: response.availableCuisines },
            () => {
              var hours = 24 * 14; // to clear the localStorage after 1 hour(if someone want to clear after 8hrs simply change hours=8)
              var now = new Date().getTime();
              var setupTime = getFromLocalStorage("setupTime");
              if (setupTime == null) {
                saveToLocalStorage("setupTime", now);
                this.resetShowOnly();
              } else {
                if (now - setupTime > hours * 60 * 60 * 1000) {
                  deleteFromLocalStorage("setupTime", null);
                  this.resetShowOnly();

                  saveToLocalStorage("setupTime", now);
                }
              }
            }
          );
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        alert("Something went wrong");
      });
  };

  resetShowOnly = () => {
    var showOnlyArr = [];
    for (var i = 0; i < this.state.availableCuisines.length; i++) {
      var cuisine = this.state.availableCuisines[i];
      showOnlyArr.push({
        cuisine: cuisine.cuisine.toLowerCase(),
        showOnly: "2",
      });
    }
    saveToLocalStorage("showOnlyArr", JSON.stringify(showOnlyArr));
  };

  sendVotingLink = (cuisine) => {
    const {
      eventType,
      name,
      email,
      phone,
      eventLocation,
      eventStartDateTime,
      eventEndDateTime,
      numberOfGuests,
      continueClicked,
    } = this.state;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.bookATruck;
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "create-voting-link",
          event_type: eventType,
          name: name,
          email: email,
          phone: phone,
          event_location: eventLocation,
          event_start_date_time: eventStartDateTime,
          event_end_date_time: eventEndDateTime,
          number_of_guests: numberOfGuests,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.success == true) {
          alert(
            "We have sent you the voting link. You can share it with friends and start voting."
          );
          deleteFromLocalStorage("bookingName", "");
          deleteFromLocalStorage("bookingEmail", "");
          deleteFromLocalStorage("bookingLocation", "");
          deleteFromLocalStorage("bookingPhone", "");
          deleteFromLocalStorage("bookingNumberOfGuests", 0);
          deleteFromLocalStorage("bookingStartDateTime");
          deleteFromLocalStorage("bookingEndDateTime");
          deleteFromLocalStorage("bookingEventType");

          window.location.href = "/poll:" + response.pollId;
        }
      });
  };

  render() {
    const { appReducer } = store.getState();
    const { eventTicket, products } = appReducer;
    const { loggedIn, username, userId, dispatch } = this.props;
    const {
      eventType,
      name,
      email,
      phone,
      eventLocation,
      eventStartDateTime,
      eventEndDateTime,
      numberOfGuests,
      continueClicked,
      step,
      availableCuisines,
      minCallOutFee,
      invalidPhone,
      invalidEmail,
      emptyName,
      specificTruck,
      customerEventType,
      customerOtherEventType,
      totalBudget,
      powerRequirements,
      showError,
    } = this.state;

    return (
      <div>
        <Helmet>
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=AW-11409585940"
          ></script>
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'AW-11409585940');
              gtag('event', 'conversion', {'send_to': 'AW-11409585940/l63tCNvsjfYYEJTuwcAq'});
            `}
          </script>
        </Helmet>
        <ResponsiveNavBar
          showBack={continueClicked}
          backUrl={"/book-a-truck"}
          ticketState={eventTicket}
          products={products}
          loggedIn={loggedIn}
          username={username}
          userd={userId}
          dispatch={dispatch}
        ></ResponsiveNavBar>
        <Container>
          <Loader loading={this.state.bookLoading} />
          {!specificTruck ? (
            <Row>
              <Col xs={12} md={6} lg={6}>
                {step == "1" ? (
                  <Row>
                    <Col xs={12} md={12} lg={12} className="left-container">
                      <h1>Book a Truck!</h1>
                      <p
                        style={{ whiteSpace: "pre-line" }}
                      >{`Here to book the truck for your next event? \nLet us help you...`}</p>
                    </Col>
                  </Row>
                ) : null}

                {step == "2" ? (
                  <Row>
                    <Col xs={12} md={12} lg={12} className="left-container">
                      <h1>Tell us more!</h1>
                      <p
                        style={{ whiteSpace: "pre-line" }}
                      >{`Alright! Food is coming your street! Now give us some clue on how the party will look like!`}</p>
                    </Col>
                  </Row>
                ) : null}
                {step == "3" || continueClicked == true ? (
                  <Row>
                    <Col xs={12} md={12} lg={12} className="left-container">
                      <h1>Tell us about the cuisine</h1>
                      <p
                        style={{ whiteSpace: "pre-line" }}
                      >{`Tell us your preferred cuisine so that we can get you the best trucks around!`}</p>
                    </Col>
                  </Row>
                ) : null}
              </Col>
              <Col xs={12} md={6} lg={6} className="right-container">
                {step == "1" ? (
                  <Row>
                    <Col xs={6} md={6} lg={6}>
                      <Card style={{ width: "100%" }}>
                        <Card.Img
                          variant="top"
                          src={require("../../assets/img/catering.png")}
                        />
                        <Card.Body>
                          <Card.Title>Private Catering</Card.Title>
                          <Card.Text style={{ whiteSpace: "pre-line" }}>
                            <ReadMoreReact
                              text={
                                "Private events are where the truck will be coming exclusively for you and your guests."
                              }
                              min={67}
                              ideal={67}
                              max={67}
                              readMoreText={"Read More"}
                            />
                          </Card.Text>
                          <Button
                            style={{
                              width: "100%",
                              marginBottom: 20,
                              marginTop: 10,
                              backgroundColor: "#ef3f6d",
                            }}
                            className="button-enabled-pink-style"
                            onClick={() => {
                              this.setState({
                                eventType: "catering",
                                step: "2",
                              });
                              saveToLocalStorage(
                                "bookingEventType",
                                "catering"
                              );
                              saveToLocalStorage("step", "2");
                            }}
                          >
                            Select
                          </Button>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col xs={6} md={6} lg={6}>
                      <Card style={{ width: "100%" }}>
                        <Card.Img
                          variant="top"
                          src={require("../../assets/img/ftims.png")}
                        />
                        <Card.Body>
                          <Card.Title>Food Truck In My Street</Card.Title>
                          <Card.Text style={{ whiteSpace: "pre-line" }}>
                            <ReadMoreReact
                              text={
                                "Introducing… Food Truck In My Street™ \n We've found a way to bring what you love, to you, with contactless payment and pickup, we’re making it easy to get the Street Food experience you love! \nYou can order any participating food truck to come to your street within your family budget. All you need to do is spread the word on your street and ask everyone to palce an order on our app and we will arrange the rest. Your Personal Min Spend  for this event won't exceed your dinner budget. However for the truck to come out to your street the min spend of this event is around 600$"
                              }
                              min={40}
                              ideal={40}
                              max={40}
                              readMoreText={"Read More"}
                            />
                          </Card.Text>
                          <Button
                            style={{
                              width: "100%",
                              marginBottom: 20,
                              marginTop: 10,
                              backgroundColor: "#ef3f6d",
                            }}
                            className="button-enabled-pink-style"
                            onClick={() => {
                              this.setState({ eventType: "ftims", step: "2" });
                              saveToLocalStorage("bookingEventType", "ftims");
                              saveToLocalStorage("step", "2");
                            }}
                          >
                            Select
                          </Button>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                ) : null}

                {step == "2" ? (
                  <Row>
                    <Col xs={12} md={12} lg={12} className="book-a-truck">
                      <FormGroup>
                        <p className="right">
                          <span className="required">*</span> Required
                          Information
                        </p>
                      </FormGroup>
                      <Form.Group className="mb-3" controlId="formBasicGuests">
                        <Form.Label>
                          Category <span className="required">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          style={{ textTransform: "capitalize" }}
                          value={eventType}
                          readOnly
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicLocation"
                      >
                        <Form.Label>
                          Location <span className="required">*</span>
                        </Form.Label>
                        <Form.Text className="text-muted">
                          Where the event will take place?
                        </Form.Text>
                        <PlacesAutocomplete
                          value={this.state.eventLocation}
                          onChange={this.handleChange}
                          onSelect={this.handleSelect}
                          searchOptions={{
                            componentRestrictions: { country: "au" },
                          }}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div>
                              <input
                                {...getInputProps({
                                  placeholder: "Enter your location ",
                                  className: "location-search-input",
                                })}
                              />
                              <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map((suggestion) => {
                                  const className = suggestion.active
                                    ? "suggestion-item--active"
                                    : "suggestion-item";
                                  // inline style for demonstration purpose
                                  const style = suggestion.active
                                    ? {
                                        backgroundColor: "#fafafa",
                                        cursor: "pointer",
                                      }
                                    : {
                                        backgroundColor: "#ffffff",
                                        cursor: "pointer",
                                      };
                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                    >
                                      <span>{suggestion.description}</span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicGuests">
                        <Form.Label>
                          Number of Guests <span className="required">*</span>
                        </Form.Label>
                        <Form.Text className="text-muted">
                          What is the estimated attendance for the event?
                        </Form.Text>
                        <Form.Control
                          type="text"
                          placeholder="Estimated Number of Guests"
                          value={numberOfGuests}
                          onChange={(e) => {
                            if (Number(e.target.value) < 50) {
                              this.setState({ minCallOutFee: true });
                            } else {
                              this.setState({ minCallOutFee: false });
                            }
                            this.setState({ numberOfGuests: e.target.value });
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicGuests">
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicGuests"
                        ></Form.Group>
                        {minCallOutFee && eventType == "catering" ? (
                          <Form.Text className="text-muted">
                            <i>
                              *Since your guest numbers are below the minimum
                              requirement for catering events, certain vendors
                              may have a minimum callout fee that may apply.
                            </i>
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicDate">
                        <Form.Label>
                          Date and Time <span className="required">*</span>
                        </Form.Label>
                        <Row>
                          <Col xs={12} md={6} lg={6}>
                            <Form.Text className="text-muted">Start</Form.Text>
                            <form noValidate>
                              <TextField
                                id="datetime-local"
                                type="datetime-local"
                                value={
                                  eventStartDateTime &&
                                  eventStartDateTime != null &&
                                  eventStartDateTime.toString()
                                }
                                onChange={(event) => {
                                  this.setState(
                                    { eventStartDateTime: event.target.value },
                                    () => {
                                      var end = moment(
                                        this.state.eventStartDateTime,
                                        "YYYY-MM-DDTHH:mm"
                                      ).add(1, "hours");
                                      end = moment(end).format(
                                        "YYYY-MM-DDTHH:mm"
                                      );
                                      this.setState({ eventEndDateTime: end });
                                    }
                                  );
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </form>
                          </Col>
                          <Col xs={12} md={6} lg={6}>
                            <Form.Text className="text-muted">End</Form.Text>
                            <form noValidate>
                              <TextField
                                id="datetime-local"
                                type="datetime-local"
                                value={
                                  eventEndDateTime &&
                                  eventEndDateTime != null &&
                                  eventEndDateTime.toString()
                                }
                                onChange={(event) => {
                                  this.setState(
                                    { eventEndDateTime: event.target.value },
                                    () => {}
                                  );
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </form>
                          </Col>
                        </Row>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Name <span className="required">*</span>
                        </Form.Label>
                        <Form.Text className="text-muted">Full Name</Form.Text>
                        <Form.Control
                          type="text"
                          placeholder="Enter your name"
                          value={name}
                          onChange={this.handleNameChange}
                        />
                        {showError && (
                          <Alert variant="danger">
                            Name should not contain numbers.
                          </Alert>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Email <span className="required">*</span>
                        </Form.Label>
                        <Form.Text className="text-muted">
                          Please enter your email so that we can communicate
                          with you regarding your booking
                        </Form.Text>
                        <Form.Control
                          type="email"
                          placeholder="Enter your email"
                          value={email}
                          onChange={(e) => {
                            if (
                              /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                                e.target.value
                              )
                            ) {
                              this.setState({ invalidEmail: false });
                            } else {
                              this.setState({ invalidEmail: true });
                            }
                            this.setState({ email: e.target.value });
                          }}
                        />
                        {invalidEmail && eventType == "catering" ? (
                          <Form.Text className="text-muted">
                            <i className="required">
                              *please enter a valid Email.
                            </i>
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Text className="text-muted">
                          Please enter your phone number so that we can
                          communicate with you regarding your booking
                        </Form.Text>
                        <Form.Label>
                          Phone <span className="required">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter your phone number"
                          value={phone}
                          onChange={(e) => {
                            if (
                              Number(e.target.value).toString().length < 9 ||
                              Number(e.target.value).toString().length > 10
                            ) {
                              this.setState({ invalidPhone: true });
                            } else {
                              this.setState({ invalidPhone: false });
                            }
                            this.setState({ phone: e.target.value });
                          }}
                        />
                        {invalidPhone && eventType == "catering" ? (
                          <Form.Text className="text-muted">
                            <i className="required">
                              *please enter a valid Phone Number.
                            </i>
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                      {/* <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Text className="text-muted">
                          Please enter your phone number so that we can
                          communicate with you regarding your booking
                        </Form.Text>
                        <Form.Label>
                          Phone <span className="required">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter your phone number"
                          value={phone}
                          onChange={(e) => {
                            this.setState({ phone: e.target.value });
                          }}
                        />
                      </Form.Group> */}
                      <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                        <Form.Label>
                          Type of event <span className="required">*</span>
                        </Form.Label>
                        <Form.Control
                          style={{
                            backgroundColor: "#edf0f3",
                            padding: 5,
                            height: 40,
                            color: "black",
                            fontSize: 16,
                          }}
                          as="select"
                          size="sm"
                          onChange={(e) => {
                            this.setState({
                              customerEventType: e.target.value,
                            });
                          }}
                        >
                          <option selected={customerEventType == ""} value={""}>
                            Select type of event
                          </option>
                          <option
                            selected={customerEventType == "Birthday Party"}
                            value={"Birthday Party"}
                          >
                            {"Birthday Party"}
                          </option>
                          <option
                            selected={customerEventType == "Christmas Function"}
                          >
                            {"Christmas Function"}
                          </option>
                          <option
                            selected={
                              customerEventType == "Coroporate Function"
                            }
                            value={"Coroporate Function"}
                          >
                            {"Corporate Function"}
                          </option>
                          <option
                            selected={customerEventType == "EOFY Function"}
                            value={"EOFY Function"}
                          >
                            {"EOFY Function"}
                          </option>
                          <option
                            selected={customerEventType == "Engagement Party"}
                            value={"Engagement Party"}
                          >
                            {"Engagement Party"}
                          </option>
                          <option
                            selected={customerEventType == "Festival"}
                            value={"Festival"}
                          >
                            {"Festival"}
                          </option>
                          <option
                            selected={customerEventType == "Market Trading"}
                            value={"Market Trading"}
                          >
                            {"Market Trading"}
                          </option>
                          <option
                            selected={customerEventType == "School Fair"}
                            value={"School Fair"}
                          >
                            {"School Fair"}
                          </option>
                          <option
                            selected={customerEventType == "Sporting Event"}
                            value={"Sporting Event"}
                          >
                            {"Sporting Event"}
                          </option>
                          <option
                            selected={customerEventType == "Wedding"}
                            value={"Wedding"}
                          >
                            {"Wedding"}
                          </option>
                          <option
                            selected={
                              customerEventType == "Other - Please specify"
                            }
                            value={"Other - Please specify"}
                          >
                            {"Other - Please specify"}
                          </option>
                        </Form.Control>
                      </Form.Group>
                      {customerEventType == "Other - Please specify" ? (
                        <Form.Group className="mb-3" controlId="formBasicName">
                          <Form.Control
                            type="text"
                            placeholder="Other - Please specify"
                            value={customerOtherEventType}
                            onChange={(e) => {
                              this.setState({
                                customerOtherEventType: e.target.value,
                              });
                            }}
                          />
                        </Form.Group>
                      ) : null}
                      <Button
                        style={{
                          width: "40%",
                          marginBottom: 20,
                          marginTop: 10,
                          backgroundColor: "#ef3f6d",
                          marginRight: 20,
                        }}
                        className="button-enabled-pink-style"
                        onClick={() => {
                          this.setState({ continueClicked: false, step: "1" });
                          saveToLocalStorage("step", "1");
                        }}
                      >
                        Back
                      </Button>
                      <Button
                        style={{
                          width: "40%",
                          marginBottom: 20,
                          marginTop: 10,
                          backgroundColor: "#ef3f6d",
                        }}
                        className="button-enabled-pink-style"
                        onClick={() => {
                          if (
                            eventType == 0 ||
                            name == "" ||
                            email == "" ||
                            phone == "" ||
                            eventLocation == "" ||
                            eventStartDateTime == "" ||
                            eventEndDateTime == "" ||
                            numberOfGuests == 0 ||
                            customerEventType == "" ||
                            (customerEventType == "Other - Please specify" &&
                              customerOtherEventType == "")
                          ) {
                            alert("Please enter all the required fields");
                          } else {
                            var diffEndDate = moment(
                              this.state.eventEndDateTime
                            ).diff(
                              moment(this.state.eventStartDateTime),
                              "hours"
                            );

                            var currentDate = moment(new Date()).format(
                              "YYYY-MM-DDTHH:mm"
                            );
                            var diffStartDate = moment(
                              this.state.eventStartDateTime
                            ).diff(moment(currentDate), "days");

                            if (diffEndDate > 0 && diffStartDate > 1) {
                              var obj = {
                                event_type: eventType,
                                name: name,
                                email: email,
                                phone: phone,
                                location: eventLocation,
                                start_date_time: eventStartDateTime,
                                end_date_time: eventEndDateTime,
                                number_of_guests: numberOfGuests,
                                customer_event_type: customerEventType,
                                customer_other_event_type: customerOtherEventType,
                              };
                              this.props.dispatch(setBookingRequest(obj));
                              this.setState({
                                continueClicked: true,
                                step: "3",
                              });
                            } else {
                              alert(
                                "The booking can only be done for events starting atleast 2 days from now. Also, the booking can only be done if the end date time is atleast 1 hour ahead of the start date time"
                              );
                            }
                          }
                        }}
                      >
                        Next
                      </Button>
                    </Col>
                  </Row>
                ) : null}
                {step == "3" || continueClicked == true ? (
                  <>
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <h6>
                          <b>Select Cuisine</b>
                        </h6>
                        {availableCuisines &&
                          availableCuisines.map((cuisine) => {
                            return (
                              <Button
                                style={{
                                  marginBottom: 20,
                                  marginTop: 10,
                                  backgroundColor: "#edf0f3",
                                  color: "black",
                                  borderColor: "#edf0f3",
                                  marginLeft: 5,
                                  marginRight: 5,
                                }}
                                className="button-enabled-pink-style"
                                onClick={() => {
                                  this.bookATruck(
                                    cuisine.cuisine.toLowerCase()
                                  );
                                }}
                              >
                                {cuisine.cuisine}
                              </Button>
                            );
                          })}
                      </Col>
                    </Row>
                    <hr
                      style={{
                        height: 2,
                        color: "black",
                        backgroundColor: "black",
                      }}
                    ></hr>
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <h6>
                          <b>Can't decide? Ask your friends!</b>
                        </h6>
                        <p style={{ fontSize: 13 }}>
                          We will send you a voting link to share with your
                          friends, so you will know what everyone wants!
                        </p>
                        <Button
                          style={{
                            width: "100%",
                            marginBottom: 20,
                            marginTop: 10,
                            backgroundColor: "#ef3f6d",
                          }}
                          className="button-enabled-pink-style"
                          onClick={() => {
                            this.sendVotingLink();
                          }}
                        >
                          Send Link
                        </Button>
                      </Col>
                    </Row>
                  </>
                ) : null}
              </Col>
            </Row>
          ) : (
            this.renderSpecificTruck()
          )}
        </Container>
      </div>
    );
  }

  handleNameChange = (e) => {
    const inputValue = e.target.value;
    // Use a regular expression to check if the input contains any numbers
    if (!/\d/.test(inputValue)) {
      // If it doesn't contain numbers, update the state and hide the error message
      this.setState({ name: inputValue, showError: false });
    } else {
      // If it contains numbers, show the error message
      this.setState({ showError: true });
    }
  };

  renderSpecificTruck() {
    const { appReducer } = store.getState();
    const {
      eventType,
      name,
      showError,
      email,
      phone,
      eventLocation,
      eventStartDateTime,
      eventEndDateTime,
      numberOfGuests,
      minCallOutFee,
      invalidPhone,
      invalidEmail,
      emptyName,
      vendor,
    } = this.state;
    var img = undefined;
    var logo = undefined;
    if (vendor && vendor.image) {
      img = vendor.image;
    }
    if (vendor && vendor.logo) {
      logo = vendor.logo;
    }
    return (
      <Row>
        <Col xs={12} md={6} lg={6}>
          <Row>
            <Col xs={12} md={12} lg={12} className="left-container">
              <Card>
                <Card.Body>
                  {img ? (
                    <div style={{ maxHeight: 200, overflow: "hidden" }}>
                      <Card.Img src={AWS_URL + "/" + img} alt="First slide" />
                    </div>
                  ) : (
                    <div style={{ maxHeight: 200, overflow: "hidden" }}>
                      <Card.Img
                        src={require("../../assets/img/food-truck-cover.png")}
                        alt="First slide"
                      />
                    </div>
                  )}

                  <Card.ImgOverlay style={{ maxHeight: 200 }}>
                    {logo ? (
                      <div style={{ maxHeight: 200, overflow: "hidden" }}>
                        <Card.Img
                          src={AWS_URL + "/" + logo}
                          style={{
                            position: "absolute",
                            top: 30,
                            left: 30,
                            color: "black",
                            whiteSpace: "pre-line",
                            width: 70,
                          }}
                          alt="First slide"
                        />
                      </div>
                    ) : (
                      <div style={{ maxHeight: 200, overflow: "hidden" }}>
                        <Card.Img
                          style={{
                            position: "absolute",
                            top: 30,
                            left: 30,
                            color: "black",
                            whiteSpace: "pre-line",
                            width: 70,
                          }}
                          src={require("../../assets/img/empty_logo.png")}
                          alt="First slide"
                        />
                      </div>
                    )}
                  </Card.ImgOverlay>
                  <Card.Title
                    style={{
                      color: "black",
                      whiteSpace: "pre-line",
                      marginTop: 20,
                    }}
                  >
                    Let us help you book - {vendor.title} for "Food Truck In My
                    Street<span>&trade;</span>"
                    <Button
                      style={{
                        width: "100%",
                        marginBottom: 20,
                        marginTop: 10,
                        backgroundColor: "#ef3f6d",
                      }}
                      className="button-enabled-pink-style"
                      onClick={() => {
                        this.setState({ isViewMenuOpen: true });
                      }}
                    >
                      View Menu
                    </Button>
                  </Card.Title>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={6} lg={6} className="right-container">
          <Row>
            <Col xs={12} md={12} lg={12} className="book-a-truck">
              <Form.Group className="mb-3" controlId="formBasicGuests">
                <Form.Label>Event Type</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Estimated Number of Guests"
                  readOnly={true}
                  value={"Food Truck In My Street"}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicLocation">
                <Form.Label>Location</Form.Label>
                <Form.Text className="text-muted">
                  Where the event will take place?
                </Form.Text>
                <PlacesAutocomplete
                  value={this.state.eventLocation}
                  onChange={this.handleChange}
                  onSelect={this.handleSelect}
                  searchOptions={{
                    componentRestrictions: { country: "au" },
                  }}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <input
                        {...getInputProps({
                          placeholder: "Enter your location ",
                          className: "location-search-input",
                        })}
                      />
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? { backgroundColor: "#fafafa", cursor: "pointer" }
                            : { backgroundColor: "#ffffff", cursor: "pointer" };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicGuests">
                <Form.Label>Number of Guests</Form.Label>
                <Form.Text className="text-muted">
                  What is the estimated attendance for the event?
                </Form.Text>
                <Form.Control
                  type="text"
                  placeholder="Estimated Number of Guests"
                  value={numberOfGuests}
                  onChange={(e) => {
                    if (Number(e.target.value) < 50) {
                      this.setState({ minCallOutFee: true });
                    } else {
                      this.setState({ minCallOutFee: false });
                    }
                    this.setState({ numberOfGuests: e.target.value });
                  }}
                />
                {minCallOutFee && eventType == "catering" ? (
                  <Form.Text className="text-muted">
                    <i>
                      *Since your guest numbers are below the minimum
                      requirement for catering events, certain vendors may have
                      a minimum callout fee that may apply.
                    </i>
                  </Form.Text>
                ) : null}
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicDate">
                <Form.Label>Date and Time</Form.Label>
                <Row>
                  <Col xs={12} md={6} lg={6}>
                    <Form.Text className="text-muted">Start</Form.Text>
                    <form noValidate>
                      <TextField
                        id="datetime-local"
                        type="datetime-local"
                        value={
                          eventStartDateTime &&
                          eventStartDateTime != null &&
                          eventStartDateTime.toString()
                        }
                        onChange={(event) => {
                          this.setState(
                            { eventStartDateTime: event.target.value },
                            () => {
                              var end = moment(
                                this.state.eventStartDateTime,
                                "YYYY-MM-DDTHH:mm"
                              ).add(1, "hours");
                              end = moment(end).format("YYYY-MM-DDTHH:mm");
                              this.setState({ eventEndDateTime: end });
                            }
                          );
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </form>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <Form.Text className="text-muted">End</Form.Text>
                    <form noValidate>
                      <TextField
                        id="datetime-local"
                        type="datetime-local"
                        value={
                          eventEndDateTime &&
                          eventEndDateTime != null &&
                          eventEndDateTime.toString()
                        }
                        onChange={(event) => {
                          this.setState(
                            { eventEndDateTime: event.target.value },
                            () => {}
                          );
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </form>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label>Name</Form.Label>
                <Form.Text className="text-muted">Full Name</Form.Text>
                <Form.Control
                  type="text"
                  placeholder="Enter your name"
                  value={name}
                  onChange={this.handleNameChange}
                />
              </Form.Group>

              {showError && (
                <Alert variant="danger">Name should not contain numbers.</Alert>
              )}
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label>Email</Form.Label>
                <Form.Text className="text-muted">
                  Please enter your email so that we can communicate with you
                  regarding your booking
                </Form.Text>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => {
                    this.setState({ email: e.target.value });
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Text className="text-muted">
                  Please enter your phone number so that we can communicate with
                  you regarding your booking
                </Form.Text>
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your phone number"
                  value={phone}
                  onChange={(e) => {
                    this.setState({ phone: e.target.value });
                  }}
                />
              </Form.Group>
              {/* <Button style={{ width: '40%', marginBottom: 20, marginTop: 10, backgroundColor: '#ef3f6d', marginRight: 20 }} className="button-enabled-pink-style"
                                onClick={() => {
                                    this.setState({ continueClicked: false, step: "1" })
                                    saveToLocalStorage("step", "1");
                                }}>Back</Button> */}
              <Button
                style={{
                  width: "40%",
                  marginBottom: 20,
                  marginTop: 10,
                  backgroundColor: "#ef3f6d",
                }}
                className="button-enabled-pink-style"
                onClick={() => {
                  if (
                    eventType == 0 ||
                    name == "" ||
                    email == "" ||
                    phone == "" ||
                    eventLocation == "" ||
                    eventStartDateTime == "" ||
                    eventEndDateTime == "" ||
                    numberOfGuests == 0
                  ) {
                    alert("Please enter all the required fields");
                  } else {
                    var diffEndDate = moment(this.state.eventEndDateTime).diff(
                      moment(this.state.eventStartDateTime),
                      "hours"
                    );
                    if (name == "") {
                      emptyName = true;
                    }

                    var currentDate = moment(new Date()).format(
                      "YYYY-MM-DDTHH:mm"
                    );
                    var diffStartDate = moment(
                      this.state.eventStartDateTime
                    ).diff(moment(currentDate), "days");

                    if (diffEndDate > 0 && diffStartDate > 1) {
                      var obj = {
                        event_type: eventType,
                        name: name,
                        email: email,
                        phone: phone,
                        location: eventLocation,
                        start_date_time: eventStartDateTime,
                        end_date_time: eventEndDateTime,
                        number_of_guests: numberOfGuests,
                      };
                      this.props.dispatch(setBookingRequest(obj));
                      this.submitFtimsForm();
                      // window.location.href = "/catering-pack:vendor_id=" + this.state.vendor_id
                    } else {
                      alert(
                        "The booking can only be done for events starting atleast 2 days from now. Also, the booking can only be done if the end date time is atleast 1 hour ahead of the start date time"
                      );
                    }
                  }
                }}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Col>
        {this.renderThankYouModal()}
        {this.renderViewMenuModal()}
      </Row>
    );
  }

  renderSelectOrVote() {
    const { availableCuisines } = this.state;
    return (
      <div>
        <Row>
          <Col xs={12}>
            <h3>Select cuisine or vote for your favorite</h3>
            <p>
              Great! Now select your prefered cuisine or you can ask your
              friends and family to vote for their favorites.
            </p>
            <Form.Group controlId="exampleForm.SelectCustomSizeSm">
              <Form.Control
                as="select"
                size="sm"
                custom
                onChange={(e) => {
                  this.bookATruck(e.target.value);
                }}
              >
                <option value={0}>Select a cuisine</option>
                {availableCuisines &&
                  availableCuisines.map((cuisine) => {
                    return (
                      <option value={cuisine.cuisine.toLowerCase()}>
                        {cuisine.cuisine}
                      </option>
                    );
                  })}
              </Form.Control>
            </Form.Group>
            <h4>OR</h4>
            <p>
              Click the button below and we will email you the voting link that
              you can forward to your friends.
            </p>
            <Button
              variant="primary"
              type="button"
              onClick={() => {
                this.sendVotingLink();
              }}
            >
              Send me the voting link
            </Button>
          </Col>
        </Row>
      </div>
    );
  }

  renderEventTypeConditions() {
    const { eventType } = this.state;
    var conditions = "";
    if (eventType == "public") {
      conditions =
        "Public events are generally over 500 people.Eg: Community Festivals";
    } else if (eventType == "catering") {
      conditions =
        "Private events are where the truck will be coming exclusively for you and your guests.";
    } else if (eventType == "ftims") {
      conditions =
        "Introducing… Food Truck In My Street™\n We've found a way to bring what you love, to you, with contactless payment and pickup, we’re making it easy to get the Street Food experience you love!\n";
      conditions =
        conditions +
        "You can order any participating food truck to come to your street within your family budget. All you need to do is spread the word on your street and ask everyone to palce an order on our app and we will arrange the rest.";
      conditions =
        conditions +
        "Your Personal Min Spend  for this event won't exceed your dinner budget. However for the truck to come out to your street the min spend of this event is around 600$";
    }
    return (
      <div>
        <Row>
          <Col xs={12}>
            <p>{conditions}</p>
            {conditions != "" ? (
              <p>
                <i>
                  *The min spend varies based on the location and the food truck
                  vendor.
                </i>
              </p>
            ) : null}
          </Col>
        </Row>
      </div>
    );
  }

  renderThankYouModal() {
    const { showThankYouModal } = this.state;
    return (
      <Modal
        show={showThankYouModal}
        onHide={() => {
          this.setState({ showThankYouModal: false }, () => {
            deleteFromLocalStorage("bookingName", "");
            deleteFromLocalStorage("bookingEmail", "");
            deleteFromLocalStorage("bookingLocation", "");
            deleteFromLocalStorage("bookingPhone", "");
            deleteFromLocalStorage("bookingNumberOfGuests", 0);
            deleteFromLocalStorage("bookingStartDateTime", "");
            deleteFromLocalStorage("bookingEndDateTime", "");
            deleteFromLocalStorage("bookingEventType", "");
            deleteFromLocalStorage("bookingRequestId", "");
            window.location.reload();
          });
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Submitted Successfully</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card>
            <Card.Body variant="bottom" style={{ padding: 5 }}>
              <Row>
                <Col xs={12}>
                  <p>
                    Thank you for submitting the request. Once we have confirmed
                    the availability of the truck, we will confirm your booking.{" "}
                  </p>
                  <p>
                    Have More Questions? Email us on hello@wherethetruck.com.au
                  </p>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  }

  renderViewMenuModal() {
    const { isViewMenuOpen, vendor } = this.state;
    return (
      <Modal
        show={isViewMenuOpen}
        onHide={() => {
          this.setState({ isViewMenuOpen: false });
        }}
      >
        {vendor && vendor.meals ? (
          <>
            <Modal.Header closeButton>
              <Modal.Title>{vendor.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ alignSelf: "center" }}>
              {vendor.meals.map((meal, index) => {
                return (
                  <>
                    {index % 2 == 0 ? (
                      <Row>
                        <Col xs={6} md={6} lg={6}>
                          <Card>
                            {vendor.meals[index].image &&
                            vendor.meals[index].image ? (
                              <Card.Img
                                variant="top"
                                src={AWS_URL + "/" + vendor.meals[index].image}
                              />
                            ) : (
                              <Card.Img
                                variant="top"
                                src={require("../../assets/img/empty_logo.png")}
                              />
                            )}
                            <Card.Body>
                              <Card.Title>
                                {vendor.meals[index].name}
                              </Card.Title>
                              <Card.Text>
                                <ReadMoreReact
                                  text={vendor.meals[index].description}
                                  min={86}
                                  ideal={86}
                                  max={90}
                                  readMoreText={"Read More"}
                                />
                              </Card.Text>
                              <Card.Text>
                                ${Number(vendor.meals[index].price).toFixed(2)}
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col xs={6} md={6} lg={6}>
                          {vendor.meals[index + 1] ? (
                            <Card>
                              {vendor.meals[index + 1].image &&
                              vendor.meals[index + 1].image ? (
                                <Card.Img
                                  variant="top"
                                  src={
                                    AWS_URL +
                                    "/" +
                                    vendor.meals[index + 1].image
                                  }
                                />
                              ) : (
                                <Card.Img
                                  variant="top"
                                  src={require("../../assets/img/empty_logo.png")}
                                />
                              )}
                              <Card.Body>
                                <Card.Title>
                                  {vendor.meals[index + 1].name}
                                </Card.Title>
                                <Card.Text>
                                  <ReadMoreReact
                                    text={vendor.meals[index + 1].description}
                                    min={86}
                                    ideal={86}
                                    max={90}
                                    readMoreText={"Read More"}
                                  />
                                </Card.Text>
                                <Card.Text>
                                  $
                                  {Number(
                                    vendor.meals[index + 1].price
                                  ).toFixed(2)}
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          ) : null}
                        </Col>
                        <p>&nbsp;</p>
                      </Row>
                    ) : null}
                  </>
                );
              })}
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </>
        ) : null}
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(BookATruckScreen, {
    fallback: <ErrorFallbackComponent />,
  })
);
