import React, { useState, useEffect } from "react";
import config from "../../../services/apiConfig";
import { apiPaths } from "../../../services/apiPath";
import * as Sentry from "@sentry/react";
import LogoImage from "../../../assets/img/sub-form-logo.png";

import "./style.css";
import ReactDatePicker from "react-datepicker";
import { format } from "date-fns";
import Axios from "axios";
import Loader from "../../../components/UI/Loader";

const SubscriptionForm = () => {
  const [active, setActive] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const toggleMenu = () => {
    setActive(!active);
    setIsActive(!isActive);
  };

  const [logoFile, setlogofile] = useState([]);
  const [stallId, set_stallId] = useState("");
  const [companyName, set_CompanyName] = useState("");
  const [companyNameError, set_companyNameError] = useState("");
  const [powerOption, setPowerOption] = useState("");
  const [gasCompliance, setGasCompliance] = useState("");
  const [businessType, setBusinessType] = useState("");
  const [interestedProducts, setInterestedProducts] = useState([]);
  const [stateOfOps, setStateOfOps] = useState([]);
  const [otherBusinessType, setOtherBusinessType] = useState("");

  const [webMediaTypeOne, set_webMediaTypeOne] = useState("");
  const [webMediaTypeTwo, set_webMediaTypeTwo] = useState("");
  const [webMediaTypeThree, set_webMediaTypeThree] = useState("");
  const [webMediaOne, set_webMediaOne] = useState("");
  const [webMediaOneError, set_webMediaOneError] = useState("");
  const [webMediaTwo, set_webMediaTwo] = useState("");
  const [webMediaThree, set_webMediaThree] = useState("");

  const [companyAddress, set_CompanyAddress] = useState("");
  const [companyAddressError, set_companyAddressError] = useState("");
  const [suburb, set_suburb] = useState("");
  const [suburbError, set_suburbError] = useState("");
  const [state, set_state] = useState("");
  const [stateError, set_stateError] = useState("");
  const [postCode, set_postCode] = useState("");
  const [postCodeError, set_postCodeError] = useState("");
  const [bankAccountName, set_bankAccountName] = useState("");
  const [bankAccountNameError, set_bankAccountNameError] = useState("");
  const [bankBSB, set_bankBSB] = useState("");
  const [bankBSBError, set_bankBSBError] = useState("");
  const [bankAccountNumber, set_bankAccountNumber] = useState("");
  const [bankAccountNumberError, set_bankAccountNumberError] = useState("");
  const [outletDimensions, set_outletDimensions] = useState("");
  const [outletDimensionsError, set_outletDimensionsError] = useState("");
  const [outletPhoto, set_outletPhoto] = useState(null);
  const [outletPhotoError, set_outletPhotoError] = useState("");
  const [councilCertificate, set_councilCertificate] = useState(null);
  const [councilCertificateError, set_councilCertificateError] = useState("");
  const [foodSafety, set_foodSafety] = useState(null);
  const [foodSafetyError, set_foodSafetyError] = useState("");
  const [publicLiability, set_publicLiability] = useState(null);
  const [publicLiabilityExpDate, set_publicLiabilityExpDate] = useState(null);
  const [publicLiabilityError, set_publicLiabilityError] = useState("");
  const [workCover, set_workCover] = useState(null);
  const [workCoverExpDate, set_workCoverExpDate] = useState(null);
  const [workCoverError, set_workCoverError] = useState("");
  const [menuPhoto, set_menuPhoto] = useState(null);
  const [menuPhotoError, set_menuPhotoError] = useState("");
  const [menuProductPhoto, set_menuProductPhoto] = useState(null);
  const [menuProductPhotoError, set_menuProductPhotoError] = useState("");

  const [vendorLogo, set_vendorLogo] = useState(null);
  const [vendorLogoError, set_vendorLogoError] = useState("");

  const [tradingName, setTradingName] = useState("");
  const [contactName, setContactName] = useState("");
  const [availableCuisines, setAvailableCuisines] = useState([]);
  const [outletTypes, setOutletTypes] = useState([]);
  const [cuisine_type, set_cuisine_type] = useState("");
  const [outlet_type, set_outlet_type] = useState("");
  const [service_side, set_service_side] = useState("");
  const [nameError, set_nameError] = useState("");
  const [tradingNameError, set_tradingNameError] = useState("");
  const [contactNameError, set_contactNameError] = useState("");
  const [mobileNumber, set_mobileNumber] = useState("");
  const [mobileNumberError, set_mobileNumberError] = useState("");
  const [phoneNumber, set_phoneNumber] = useState("");
  const [phoneNumberError, set_phoneNumberError] = useState("");
  const [email, set_email] = useState("");
  const [emailError, set_emailError] = useState("");
  const [currentState, set_currentState] = useState("");
  const [currentStateError, set_currentStateError] = useState("");
  const [city, set_city] = useState("");
  const [cityError, set_cityError] = useState("");
  const [abnNumber, set_abnNumber] = useState("");
  const [abnError, set_abnError] = useState("");
  const [stallDescription, setStallDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [eventPhotos, set_eventPhotos] = useState([]);
  const [siteLayoutFile, set_siteLayoutFile] = useState(null);
  const [vansWidth, set_vansWidth] = useState("");
  const [vansHeight, set_vansHeight] = useState("");
  const [vansDepth, set_vansDepth] = useState("");
  const [exitDoorPos, set_exitDoorPos] = useState("");
  const [coolRoomWidth, set_coolRoomWidth] = useState("");
  const [coolRoomHeight, set_coolRoomHeight] = useState("");
  const [coolRoomDepth, set_coolRoomDepth] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isCoolRoomRequired, setIsCoolRoomRequired] = useState("");
  const [fireSource, set_fireSource] = useState("");
  const [waterAccess, set_waterAccess] = useState("");
  const [numberOf10Amp, set_numberOf10Amp] = useState("");
  const [numberOf15Amp, set_numberOf15Amp] = useState("");
  const [numberOf20Amp, set_numberOf20Amp] = useState("");
  const [numberOf32Amp, set_numberOf32Amp] = useState("");
  const [doesDelivery, set_doesDelivery] = useState("");
  const [deliveryFee, set_deliveryFee] = useState("");
  const [isPricesAlteredAllowed, set_isPricesAlteredAllowed] = useState("");
  const [instagram_username, set_instagram_username] = useState("");
  const [facebook_username, set_facebook_username] = useState("");
  const [website, set_website] = useState("");
  const [otherComments, set_otherComments] = useState("");

  const postVendors = (values) => {
    values.preventDefault();

    const formData = new FormData();

    formData.append("company_name", companyName);
    formData.append("trading_name", tradingName);
    formData.append("contact_name", contactName);
    formData.append("mobile_number", mobileNumber);
    formData.append("phone", phoneNumber);
    formData.append("email", email);
    formData.append("company_address", companyAddress);
    formData.append("suburb", suburb);
    formData.append("state", state);
    formData.append("postcode", postCode);
    formData.append("type_of_truck", outlet_type);
    formData.append("outlet_dimensions", outletDimensions);
    formData.append("power_requirements", powerOption);
    formData.append("photo_of_outlet", outletPhoto);
    formData.append("logo", vendorLogo);
    formData.append("bank_account_name", bankAccountName);
    formData.append("bsb", bankBSB);
    formData.append("account_number", bankAccountNumber);
    formData.append(
      "type_of_buisness",
      businessType !== "Other" ? businessType : otherBusinessType
    );
    formData.append("instagram", instagram_username);
    formData.append("Facebook", facebook_username);
    formData.append("website", website);
    formData.append("products_intrested_in", interestedProducts.join(", "));
    formData.append("state_of_operations", stateOfOps.join(", "));
    formData.append("work_cover", workCover);
    formData.append(
      "work_cover_expiry",
      format(new Date(workCoverExpDate), "dd MM yyyy")
    );
    formData.append("public_liablity", publicLiability);
    formData.append(
      "public_liablity_expiry_date",
      format(new Date(publicLiabilityExpDate), "dd MM yyyy")
    );
    formData.append("food_safety", foodSafety);
    formData.append("council_certificate", councilCertificate);
    formData.append("gas_compliance", gasCompliance);
    formData.append("current_state", currentState);
    setIsLoading(true);
    Axios.post(config.BASE_URI + apiPaths.operatorSubscription, formData)
      .then((res) => {
        setIsLoading(false);
        if (res.success == true) {
          alert("Subscription Form submitted Successfully!");
          window.location.reload();
        } else {
          alert("Unable to submit the form. Please try again!");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        Sentry.captureException(error);
        console.error(error);
        alert("Unable to submit the form. Please try again!");
      });
  };

  const getOutletTypes = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.operatorData;
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_all_outlet_types",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.success === true) {
          setOutletTypes(response.outlet_type);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };

  const POWER_OPTIONS = [
    { id: "1x10", value: "1x10", htmlFor: "powerOption", label: "1 x 10 Amps" },
    { id: "2x10", value: "2x10", htmlFor: "powerOption", label: "2 x 10 Amps" },
    { id: "3x15", value: "3x15", htmlFor: "powerOption", label: "3 x 15 Amps" },
    { id: "1x3", value: "1x3", htmlFor: "powerOption", label: "1 x 3 Amps" },
    { id: "2x3", value: "2x3", htmlFor: "powerOption", label: "2 x 3 Amps" },
    {
      id: "Generator",
      value: "Generator",
      htmlFor: "powerOption",
      label: "Generator preferred",
    },
  ];

  const BUSINESS_TYPE_OPTIONS = [
    {
      id: "Mobile Caterer",
      value: "Mobile Caterer",
      htmlFor: "businessType",
      label: "Mobile Caterer",
    },
    {
      id: "Event & Venue Organisers",
      value: "Event & Venue Organisers",
      htmlFor: "businessType",
      label: "Event & Venue Organisers",
    },
    {
      id: "Corporate",
      value: "Corporate",
      htmlFor: "businessType",
      label: "Corporate",
    },
    {
      id: "Manufacturers",
      value: "Manufacturers",
      htmlFor: "businessType",
      label: "Manufacturers",
    },
    {
      id: "Wholesalers",
      value: "Wholesalers",
      htmlFor: "businessType",
      label: "Wholesalers",
    },
    {
      id: "Distributors",
      value: "Distributors",
      htmlFor: "businessType",
      label: "Distributors",
    },
    {
      id: "Trade Suppliers",
      value: "Trade Suppliers",
      htmlFor: "businessType",
      label: "Trade Suppliers",
    },
    {
      id: "Government Agency",
      value: "Government Agency",
      htmlFor: "businessType",
      label: "Government Agency",
    },
    {
      id: "Insurance & Financial Services",
      value: "Insurance & Financial Services",
      htmlFor: "businessType",
      label: "Insurance & Financial Services",
    },
    {
      id: "Consultants",
      value: "Consultants",
      htmlFor: "businessType",
      label: "Consultants",
    },
    {
      id: "Sales & Marketing Companies",
      value: "Sales & Marketing Companies",
      htmlFor: "businessType",
      label: "Sales & Marketing Companies",
    },
    {
      id: "Individuals",
      value: "Individuals",
      htmlFor: "businessType",
      label: "Individuals",
    },
    {
      id: "Other",
      value: "Other",
      htmlFor: "businessType",
      label: "Other (please Specify)",
    },
  ];

  const INTERESTED_PRODUCTS_OPTIONS = [
    "National Buying Power",
    "Educational Courses",
    "Wrokshops",
    "Insurance Providers",
    "Wholesalers",
    "IT and POS Systems",
    "Equipment & Business Finance",
    "Trade Services",
    "Publications & News",
    "Cleaning and Chemical Supplier",
    "Packaging Specialist",
    "AMFVG Industry Night Event",
    "Gas Supply",
    "Automotive",
  ];

  const handleOutletTypeChange = (event) => {
    set_outlet_type(Number(event.target.value));
  };

  const handleCompanyNameChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^[a-zA-Z\s]*$/;
    if (!regex.test(inputValue)) {
      set_nameError("Please enter only letters and spaces");
    } else {
      set_CompanyName(inputValue);
      set_nameError("");
    }
  };
  const handleTradingNameChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^[a-zA-Z\s]*$/;
    if (!regex.test(inputValue)) {
      set_tradingNameError("Please enter only letters and spaces");
    } else {
      setTradingName(inputValue);
      set_tradingNameError("");
    }
  };
  const handleContactNameChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^[a-zA-Z\s]*$/;
    if (!regex.test(inputValue)) {
      set_contactNameError("Please enter only letters and spaces");
    } else {
      setContactName(inputValue);
      set_contactNameError("");
    }
  };
  const handleCompanyAddressChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^[a-zA-Z\s]*$/;
    if (!regex.test(inputValue)) {
      set_companyAddressError("Please enter only letters and spaces");
    } else {
      set_CompanyAddress(inputValue);
      set_companyAddressError("");
    }
  };
  const handleSuburbChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^[a-zA-Z\s]*$/;
    if (!regex.test(inputValue)) {
      set_suburbError("Please enter only letters and spaces");
    } else {
      set_suburb(inputValue);
      set_suburbError("");
    }
  };
  const handleStateChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^[a-zA-Z\s]*$/;
    if (!regex.test(inputValue)) {
      set_stateError("Please enter only letters and spaces");
    } else {
      set_state(inputValue);
      set_stateError("");
    }
  };
  const handlePostCodeChange = (event) => {
    const inputValue = event.target.value;
    if (!inputValue) {
      set_postCodeError("This field is required");
    } else {
      set_postCode(inputValue);
      set_postCodeError("");
    }
  };
  const handleBankAccountNameChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^[a-zA-Z\s]*$/;
    if (!regex.test(inputValue)) {
      set_bankAccountNameError("Please enter only letters and spaces");
    } else {
      set_bankAccountName(inputValue);
      set_bankAccountNameError("");
    }
  };
  const handleBankBSBChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^[0-9]*$/;
    if (!regex.test(inputValue)) {
      set_bankBSBError("Please enter a valid BSB Number");
    } else {
      set_bankBSB(inputValue);
      set_bankBSBError("");
    }
  };
  const handleBankAccountNumberChange = (event) => {
    const inputValue = event.target.value;
    if (!inputValue) {
      set_bankAccountNumberError("This field is required");
    } else {
      set_bankAccountNumber(inputValue);
      set_bankAccountNumberError("");
    }
  };
  const handleOutletDimensionsChange = (event) => {
    const inputValue = event.target.value;

    if (!inputValue) {
      set_outletDimensionsError("This field is required.");
    } else {
      set_outletDimensions(inputValue);
      set_outletDimensionsError("");
    }
  };
  const handleOutletPhotoChange = (event) => {
    const inputValue = event.target.files;
    if (inputValue.length > 0) {
      set_outletPhotoError("");
      set_outletPhoto(inputValue[0]);
    } else {
      set_outletPhoto(null);
      set_outletPhotoError("This field is required.");
    }
  };
  const handleCouncilCertificateChange = (event) => {
    const inputValue = event.target.files;
    if (inputValue.length > 0) {
      set_councilCertificateError("");
      set_councilCertificate(inputValue[0]);
    } else {
      set_councilCertificate(null);
      set_councilCertificateError("This field is required.");
    }
  };
  const handleFoodSafetyChange = (event) => {
    const inputValue = event.target.files;
    if (inputValue.length > 0) {
      set_foodSafetyError("");
      set_foodSafety(inputValue[0]);
    } else {
      set_foodSafety(null);
      set_foodSafetyError("This field is required.");
    }
  };
  const handlePublicLiabilityChange = (event) => {
    const inputValue = event.target.files;
    if (inputValue.length > 0) {
      set_publicLiabilityError("");
      set_publicLiability(inputValue[0]);
    } else {
      set_publicLiability(null);
      set_publicLiabilityError("This field is required.");
    }
  };
  const handleWorkCoverChange = (event) => {
    const inputValue = event.target.files;
    if (inputValue.length > 0) {
      set_workCoverError("");
      set_workCover(inputValue[0]);
    } else {
      set_workCover(null);
      set_workCoverError("This field is required.");
    }
  };

  const handleVendorLogoChange = (event) => {
    const inputValue = event.target.files;
    if (inputValue.length > 0) {
      set_vendorLogoError("");
      set_vendorLogo(inputValue[0]);
    } else {
      set_vendorLogo(null);
      set_vendorLogoError("This field is required.");
    }
  };

  const handleMobileNumberChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^[0-9]*$/;
    if (!regex.test(inputValue)) {
      set_mobileNumberError("Please enter a valid contact number");
    } else {
      set_mobileNumber(inputValue);
      set_mobileNumberError("");
    }
  };
  const handlePhoneNumberChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^[0-9]*$/;
    if (!regex.test(inputValue)) {
      set_phoneNumberError("Please enter a valid contact number");
    } else {
      set_phoneNumber(inputValue);
      set_phoneNumberError("");
    }
  };
  const handleEmailChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^[a-zA-Z0-9@.]*$/;
    if (!regex.test(inputValue)) {
      set_emailError("Please Enter a valid Email");
    } else {
      set_email(inputValue);
      set_emailError("");
    }
  };
  const handleCurrentStateChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^[a-zA-Z\s]*$/;
    if (!regex.test(inputValue)) {
      set_currentStateError("Please Enter a valid State");
    } else {
      set_currentState(inputValue);
      set_currentStateError("");
    }
  };
  const handleABNNumber = (event) => {
    const inputValue = event.target.value;
    const regex = /^[0-9]*$/;
    if (!regex.test(inputValue)) {
      set_abnError("Please enter a ABN number");
    } else {
      set_abnNumber(inputValue);
      set_abnError("");
    }
  };
  const handleInstagramUsernameChange = (event) => {
    set_instagram_username(event.target.value);
  };
  const handleFacebookUsernameChange = (event) => {
    set_facebook_username(event.target.value);
  };
  const handleWebsiteChange = (event) => {
    set_website(event.target.value);
  };

  useEffect(() => {
    getOutletTypes();
  }, []);
  return (
    <>
      <section id="header">
        <div className="header containerl">
          <div className="nav-bar">
            <div className="brand">
              <a href="/operator-landing#hero">
                <h1 style={{ fontSize: "2em" }}>
                  <span>Home</span>
                </h1>
              </a>
            </div>
            <div className="nav-list">
              <div className="hamburger" onClick={toggleMenu}>
                <div className="bar"></div>
              </div>
              <ul className={active ? "active" : ""}>
                <li>
                  <a
                    href="/operator-landing#services"
                    data-after="Service"
                    style={{ fontSize: "1.5em" }}
                    onClick={toggleMenu}
                  >
                    Services
                  </a>
                </li>
                <li>
                  <a
                    href="/operator-landing#solutions"
                    data-after="Solution"
                    style={{ fontSize: "1.5em" }}
                    onClick={toggleMenu}
                  >
                    Solutions
                  </a>
                </li>

                <li>
                  <a
                    href="/operator-login"
                    data-after="About"
                    style={{ fontSize: "1.5em" }}
                    onClick={toggleMenu}
                  >
                    Login
                  </a>
                </li>
                <li>
                  <a
                    href="/register"
                    data-after="Contact"
                    style={{ fontSize: "1.5em" }}
                    onClick={toggleMenu}
                  >
                    Register
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section
        style={{ margin: "auto", marginTop: 150, maxWidth: "1200px" }}
        className="sub-form"
      >
        <img src={LogoImage} style={{ maxWidth: 270 }} />
        <h1 className="section-title sub-form-title">
          <span>Where The Truck Subscription Form</span>
        </h1>
        <p className="pl">
          We invite you to become a subscriber of Where The Truck!
          <br />
          To apply, please fill out the application form below making sure to
          complete each section.
          <br />
          Once you submit your application, please allow 2-7 business days for
          review.
          <br />
          Fields with asterisk* are required
        </p>
        <div className="sub-form-style-5" style={{ position: "relative" }}>
          <Loader loading={isLoading} />
          <form onSubmit={postVendors}>
            <div>
              <legend>
                <span className="number">Contact Details:</span>
              </legend>
              <label>Company Name*</label>
              <input
                placeholder="Company Name*:"
                type="text"
                id="companyName"
                name="companyName"
                value={companyName}
                onChange={handleCompanyNameChange}
              />
              {nameError && <div style={{ color: "red" }}>{nameError}</div>}
              <label>ABN*</label>
              <input
                placeholder="ABN*:"
                type="number"
                id="abnNumber"
                name="abnNumber"
                value={abnNumber}
                onChange={handleABNNumber}
              />
              {abnError && <div style={{ color: "red" }}>{abnError}</div>}
              <label>Trading Name*</label>
              <input
                placeholder="Trading Name*:"
                type="text"
                id="tradingName"
                name="tradingName"
                value={tradingName}
                onChange={handleTradingNameChange}
              />
              {tradingNameError && (
                <div style={{ color: "red" }}>{tradingNameError}</div>
              )}
              <label>Contact Name*</label>
              <input
                placeholder="Contact Name*(s):"
                type="text"
                id="contactName"
                name="contactName"
                value={contactName}
                onChange={handleContactNameChange}
              />
              {contactNameError && (
                <div style={{ color: "red" }}>{contactNameError}</div>
              )}
              <label>Mobile*</label>
              <input
                placeholder="Mobile*:"
                type="number"
                id="mobileNumber"
                name="mobileNumber"
                value={mobileNumber}
                onChange={handleMobileNumberChange}
              />
              {mobileNumberError && (
                <div style={{ color: "red" }}>{mobileNumberError}</div>
              )}
              <label>Phone*</label>
              <input
                placeholder="Phone*:"
                type="number"
                id="phoneNumber"
                name="phoneNumber"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
              />
              {phoneNumberError && (
                <div style={{ color: "red" }}>{phoneNumberError}</div>
              )}
              <label>Email*</label>
              <input
                placeholder="Email*:"
                type="text"
                id="email"
                name="email"
                value={email}
                onChange={handleEmailChange}
              />
              {emailError && <div style={{ color: "red" }}>{emailError}</div>}
              <label>Current State*</label>
              <input
                placeholder="Current State*:"
                type="text"
                id="currentState"
                name="currentState"
                value={currentState}
                onChange={handleCurrentStateChange}
              />
              {currentStateError && (
                <div style={{ color: "red" }}>{currentStateError}</div>
              )}
            </div>
            <div>
              <legend>
                <span className="number">Address Details:</span>
              </legend>
              <label>Company Address*</label>
              <input
                placeholder="Company Address*"
                type="text"
                id="companyAddress"
                name="companyAddress"
                value={companyAddress}
                onChange={handleCompanyAddressChange}
              />
              {companyAddressError && (
                <div style={{ color: "red" }}>{companyAddressError}</div>
              )}
              <label>Suburb*</label>
              <input
                placeholder="Suburb*"
                type="text"
                id="suburb"
                name="suburb"
                value={suburb}
                onChange={handleSuburbChange}
              />
              {suburbError && <div style={{ color: "red" }}>{suburbError}</div>}
              <label>State*</label>
              <input
                placeholder="State*"
                type="text"
                id="state"
                name="state"
                value={state}
                onChange={handleStateChange}
              />
              {stateError && <div style={{ color: "red" }}>{stateError}</div>}
              <label>Postcode*</label>
              <input
                placeholder="Postcode*"
                type="number"
                id="postCode"
                name="postCode"
                value={postCode}
                onChange={handlePostCodeChange}
              />
              {postCodeError && (
                <div style={{ color: "red" }}>{postCodeError}</div>
              )}
            </div>
            <div>
              <legend>
                <span className="number">About Your Outlet:</span>
              </legend>
              <label>Outlet Type*</label>
              <select value={outlet_type} onChange={handleOutletTypeChange}>
                <option value="" disabled>
                  Select outlet type
                </option>
                {outletTypes &&
                  outletTypes.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.outlet_type}
                    </option>
                  ))}
              </select>
              <br />
              <label>Outlet Dimensions (HxWxL)*</label>
              <input
                placeholder="HxWxL"
                type="text"
                id="outletDimensions"
                name="outletDimensions"
                value={outletDimensions}
                onChange={handleOutletDimensionsChange}
              />
              {outletDimensionsError && (
                <div style={{ color: "red" }}>{outletDimensionsError}</div>
              )}

              <p>
                All applicants must possess a cash register and/or a POS system.
              </p>

              <label>Power Requirements</label>
              {POWER_OPTIONS.map((opt) => (
                <div
                  key={opt.id}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "24px",
                  }}
                >
                  <input
                    style={{ width: "auto" }}
                    checked={opt.value === powerOption}
                    onChange={(e) => {
                      setPowerOption(e.target.value);
                    }}
                    type="radio"
                    id={opt.id}
                    key={opt.id}
                    name={opt.htmlFor}
                    value={opt.value}
                  />
                  <label style={{ marginBottom: 0 }} htmlFor={opt.id}>
                    {opt.label}
                  </label>
                </div>
              ))}
              <br />
              <label>Photo of your outlet*(file size max 2mb):</label>
              <input
                placeholder="Outlet Photo"
                type="file"
                id="outletPhoto"
                name="outletPhoto"
                onChange={handleOutletPhotoChange}
              />
              {outletPhotoError && (
                <div style={{ color: "red" }}>{outletPhotoError}</div>
              )}
              <br />
              <label>Please Upload Your Logo*(file size max 2mb):</label>
              <input
                placeholder="Logo"
                type="file"
                id="vendorLogo"
                name="vendorLogo"
                onChange={handleVendorLogoChange}
              />
              {vendorLogoError && (
                <div style={{ color: "red" }}>{vendorLogoError}</div>
              )}
              <br />
            </div>
            <div>
              <legend>
                <span className="number">
                  Bank Account Details for Reimbursement of Sales:
                </span>
              </legend>

              <label>Bank Account Name*</label>
              <input
                placeholder="Bank Account Name*"
                type="text"
                id="bankAccountName"
                name="bankAccountName"
                value={bankAccountName}
                onChange={handleBankAccountNameChange}
              />
              {bankAccountNameError && (
                <div style={{ color: "red" }}>{bankAccountNameError}</div>
              )}
              <label>BSB*</label>
              <input
                placeholder="BSB*"
                type="number"
                id="bankBSB"
                name="bankBSB"
                value={bankBSB}
                onChange={handleBankBSBChange}
              />
              {bankBSBError && (
                <div style={{ color: "red" }}>{bankBSBError}</div>
              )}
              <label>Bank Account Number*</label>
              <input
                placeholder="Bank Account Number*"
                type="number"
                id="bankAccountNumber"
                name="bankAccountNumber"
                value={bankAccountNumber}
                onChange={handleBankAccountNumberChange}
              />
              {bankAccountNumberError && (
                <div style={{ color: "red" }}>{bankAccountNumberError}</div>
              )}
            </div>
            <div>
              <legend>
                <span className="number">Type of business /organisation*:</span>
              </legend>

              {BUSINESS_TYPE_OPTIONS.map((opt) => (
                <div
                  key={opt.id}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "24px",
                  }}
                >
                  <input
                    style={{ width: "auto" }}
                    checked={opt.value === businessType}
                    onChange={(e) => {
                      setBusinessType(e.target.value);
                    }}
                    type="radio"
                    id={opt.id}
                    key={opt.id}
                    name={opt.htmlFor}
                    value={opt.value}
                  />
                  <label style={{ marginBottom: 0 }} htmlFor={opt.id}>
                    {opt.label}
                  </label>
                </div>
              ))}
              {businessType === "Other" ? (
                <>
                  <input
                    placeholder="Other"
                    type="text"
                    id="otherBusinessType"
                    name="otherBusinessType"
                    value={otherBusinessType}
                    onChange={(e) => {
                      setOtherBusinessType(e.target.value);
                    }}
                  />
                </>
              ) : null}
              <br />
            </div>
            <div>
              <legend>
                <span className="number">
                  What products & services are you interested in*:
                </span>
              </legend>

              {INTERESTED_PRODUCTS_OPTIONS.map((opt) => (
                <div
                  key={opt}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "24px",
                  }}
                >
                  <input
                    style={{ width: "auto" }}
                    checked={interestedProducts.includes(opt)}
                    onChange={(e) => {
                      const value = e.target.value;

                      setInterestedProducts((st) => {
                        let temp = [...st];
                        if (temp.includes(value)) {
                          temp = temp.filter((val) => val != value);
                        } else {
                          temp.push(value);
                        }
                        return temp;
                      });
                    }}
                    type="checkbox"
                    id={opt}
                    key={opt}
                    name={opt}
                    value={opt}
                  />
                  <label style={{ marginBottom: 0 }} htmlFor={opt}>
                    {opt}
                  </label>
                </div>
              ))}
              <br />
            </div>
            <div>
              <legend>
                <span className="number">Web Media Links:</span>
              </legend>
              <p>Please provide atleast one Web Media Link</p>
              <input
                placeholder="Website URL: "
                type="text"
                id="website"
                name="website"
                value={website}
                onChange={handleWebsiteChange}
              />
              <input
                placeholder="Facebook Username: "
                type="text"
                id="facebook_username"
                name="facebook_username"
                value={facebook_username}
                onChange={handleFacebookUsernameChange}
              />
              <input
                placeholder="Instagram Username: "
                type="text"
                id="instagram_username"
                name="instagram_username"
                value={instagram_username}
                onChange={handleInstagramUsernameChange}
              />
            </div>
            <div>
              <legend>
                <span className="number">Food Handling Documents:</span>
              </legend>
              <label>
                Attach Council Registration Certificate (file size max 2mb):
              </label>
              <input
                placeholder="Council Registration Certificate"
                type="file"
                id="councilCertificate"
                name="councilCertificate"
                onChange={handleCouncilCertificateChange}
              />
              {councilCertificateError && (
                <div style={{ color: "red" }}>{councilCertificateError}</div>
              )}
              <br />
              <label>Attach Food Safety Certificate (file size max 2mb):</label>
              <input
                placeholder="Attach Food Safety Certificate"
                type="file"
                id="foodSafety"
                name="foodSafety"
                onChange={handleFoodSafetyChange}
              />
              {foodSafetyError && (
                <div style={{ color: "red" }}>{foodSafetyError}</div>
              )}
              <br />
            </div>
            <div>
              <legend>
                <span className="number">
                  Liability and Insurance Documents:
                </span>
              </legend>

              <label>State of Operation*</label>
              {["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"].map(
                (opt) => (
                  <div
                    key={opt}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "24px",
                    }}
                  >
                    <input
                      style={{ width: "auto" }}
                      checked={stateOfOps.includes(opt)}
                      onChange={(e) => {
                        const value = e.target.value;

                        setStateOfOps((st) => {
                          let temp = [...st];
                          if (temp.includes(value)) {
                            temp = temp.filter((val) => val != value);
                          } else {
                            temp.push(value);
                          }
                          return temp;
                        });
                      }}
                      type="checkbox"
                      id={opt}
                      key={opt}
                      name={opt}
                      value={opt}
                    />
                    <label style={{ marginBottom: 0 }} htmlFor={opt}>
                      {opt}
                    </label>
                  </div>
                )
              )}
              <br />
              <label>
                Public Liability Insurance: (Certificate of currency) (file size
                max 2mb):
              </label>
              <input
                placeholder="Public Liability Insurance:"
                type="file"
                id="publicLiability"
                name="publicLiability"
                onChange={handlePublicLiabilityChange}
              />
              {publicLiabilityError && (
                <div style={{ color: "red" }}>{publicLiabilityError}</div>
              )}
              <label>Public Liability Insurance Expiration Date</label>
              <ReactDatePicker
                className="date-picker"
                dateFormat={"dd/MM/yyyy"}
                selected={publicLiabilityExpDate}
                onChange={(date) => set_publicLiabilityExpDate(date)}
              />
              <br />
              <label>Workers Compensation Insurance (file size max 2mb):</label>
              <input
                placeholder="Workers Compensation Insurance:"
                type="file"
                id="workCover"
                name="workCover"
                onChange={handleWorkCoverChange}
              />
              {workCoverError && (
                <div style={{ color: "red" }}>{workCoverError}</div>
              )}
              <label>Work Cover Insurance Expiration Date</label>
              <ReactDatePicker
                className="date-picker"
                dateFormat={"dd/MM/yyyy"}
                selected={workCoverExpDate}
                onChange={(date) => set_workCoverExpDate(date)}
              />
              <br />
              <label>Mobile Caterer Vehicle Gas Compliance*</label>
              {["Yes", "No", "NA"].map((opt) => (
                <div
                  key={opt}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "24px",
                  }}
                >
                  <input
                    style={{ width: "auto" }}
                    checked={opt === gasCompliance}
                    onChange={(e) => {
                      setGasCompliance(e.target.value);
                    }}
                    type="radio"
                    id={opt}
                    key={opt}
                    name={opt}
                    value={opt}
                  />
                  <label style={{ marginBottom: 0 }} htmlFor={opt}>
                    {opt}
                  </label>
                </div>
              ))}
            </div>
            <br />
            <button type="submit">Submit</button>
          </form>
        </div>
      </section>
    </>
  );
};
export default SubscriptionForm;
