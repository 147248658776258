import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Table,
  Alert,
  Modal,
  Accordion,
  Card,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import Form from "react-bootstrap/Form";
import * as Sentry from "@sentry/react";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import { getFromLocalStorage } from "../../store";
import VendorNavBar from "../VendorApplyEventScreen/Component/VendorNavBar";
import "./style.css";
import { format } from "date-fns";

const PreEventChecklist = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [gasBottlesFull, setGasBottlesFull] = useState(false);
  const [runsheetSeen, setRunsheetSeen] = useState(false);
  const [openingStock, setOpeningStock] = useState(false);
  const [fuelChecked, setFuelChecked] = useState(false);
  const [vendorId, setVendorId] = useState(null);
  const [eventId, setEventId] = useState(null);
  const [fetchedEvents, setFetchedEvents] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isEventCoordinatorLoggedIn, setIsEventCoordinatorLoggedIn] = useState(
    false
  );
  const [isOperatorLoggedIn, setIsOperatorLoggedIn] = useState(false);
  const [isTruckManager, setIsTruckManager] = useState(false);
  const [isOperatorOperations, setIsOperatorOperations] = useState(false);

  const { register, handleSubmit } = useForm({
    defaultValues: {
      gasBottlesFull: false,
      videoOfTheTruck: null,
      runsheetSeen: false,
      openingStock: false,
      fuelChecked: false,
      operatorId: null,
    },
  });

  const fetchVendorIds = () => {
    const operator = JSON.parse(getFromLocalStorage("Operator"));
    if (!operator || operator.length === 0) {
      alert("Operator not found. Please Log In again to Continue.");
      return;
    }

    const operatorId = operator[0].id;

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_operator_details_for_stall_ids",
          operator_id: operatorId,
        },
      },
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(config.BASE_URI + apiPaths.adminData, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          setVendors(result.stalls ?? []);
        } else {
          alert(result.error);
        }
      })
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
      });
  };

  useEffect(() => {
    fetchVendorIds();
  }, []);

  useEffect(() => {
    if (vendorId) {
      getAllEvents(vendorId);
    }
  }, [vendorId]);

  const getAllEvents = (vendorId) => {
    setIsLoading(true);

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_upcoming_events_for_operators",
          vendor_id: Number(vendorId),
        },
      },
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(config.BASE_URI + apiPaths.operatorData, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        setIsLoading(false);
        if (dataResponse.success === true) {
          setFetchedEvents(dataResponse.data ?? []);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        Sentry.captureException(error);
        console.error(error);
      });
  };

  const onSubmit = async (data) => {
    const operator = JSON.parse(getFromLocalStorage("Operator"));
    if (!operator || operator.length === 0) {
      alert("Operator not found. Please Log In again to Continue.");
      return;
    }

    const operatorId = operator[0].id;

    const formData = new FormData();
    formData.append("operator_id", operatorId);
    formData.append("timestamp", format(new Date(), "dd MMM yyyy"));
    formData.append("vendor_id", vendorId);
    formData.append("event_id", eventId);
    formData.append("is_fuel_checked_done", fuelChecked);
    formData.append("is_opening_stock_done", openingStock);
    formData.append("is_runsheet_read", runsheetSeen);

    if (!data.videoOfTheTruck) {
      alert("Please upload the video of the truck to continue.");
      return;
    }

    function appendFileField(key, fieldName) {
      if (data[fieldName] instanceof FileList && data[fieldName].length > 0) {
        const file = data[fieldName][0];
        const modifiedFile = new File(
          [file],
          `${fieldName}_${JSON.parse(getFromLocalStorage("Operator"))[0].id}`,
          {
            type: file.type,
          }
        );
        formData.append(key, modifiedFile);
      }
    }

    appendFileField("pre_event_checklist_video", "videoOfTheTruck");
    setIsLoading(true);
    try {
      const response = await axios.post(
        config.BASE_URI + "/api/v1" + apiPaths.preEventsChecklist,
        formData
      );
      setIsLoading(false);

      if (response.data.success === true) {
        alert("Bump In Checklist Details have been submitted successfully!");
        window.location.reload();
      } else {
        alert(
          "Bump In Checklist Details could not be added. Please try again."
        );
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      alert("Vendor Details could not be added. Please try again.");
    }
  };

  const renderNavBar = () => {
    return (
      <VendorNavBar
        isOperatorLoggedIn={isOperatorLoggedIn}
        isOperatorOperations={isOperatorOperations}
        isTruckManager={isTruckManager}
      ></VendorNavBar>
    );
  };

  const renderLogin = () => {
    return (
      <div>
        <Container>
          <Form.Group>
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </Form.Group>
        </Container>
      </div>
    );
  };

  return (
    <>
      {renderNavBar()}
      {!isLoading ? (
        <Container
          style={{
            borderWidth: 1,
            borderColor: "grey",
            borderStyle: "solid",
            borderRadius: 5,
            padding: 15,
          }}
        >
          <Typography>Pre Event Checklist Form </Typography>
          <div>
            <label>Select Vendor:</label>
            <select
              onChange={(e) => {
                const selectedVendorId = e.target.value;
                setVendorId(selectedVendorId);
              }}
              value={vendorId || ""}
            >
              <option value="">Select Vendor</option>
              {vendors.map((vendor, index) => (
                <option key={index} value={vendor?.user_ptr_id}>
                  {vendor?.title}
                </option>
              ))}
            </select>

            {vendorId && (
              <div>
                <label>Select Event:</label>
                <select
                  onChange={(e) => {
                    setEventId(e.target.value);
                  }}
                  value={eventId || ""}
                >
                  <option value="">Select Event</option>
                  {fetchedEvents.map((event, index) => (
                    <option key={index} value={event?.id}>
                      {event?.name || ""}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
          {vendorId && eventId ? (
            <Box>
              <form
                className="event-form-container"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div>
                  Note: Please fill all the fields marked as{" "}
                  <span style={{ color: "red" }}>*</span>
                </div>
                <Divider />

                <Divider />
                <div className="event-form-item">
                  <label
                    htmlFor="gasBottlesFull"
                    className="event-form-control"
                  >
                    Are the Gas Bottles Full?{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <Switch
                    checked={gasBottlesFull}
                    onChange={(e) => {
                      setGasBottlesFull(e.target.checked);
                    }}
                    color="primary"
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </div>

                <div className="event-form-item">
                  <label
                    htmlFor="videoOfTheTruck"
                    className="event-form-control"
                  >
                    Upload the video of the truck{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    className="event-form-control"
                    type="file"
                    accept=".mp4, .mov, .avi"
                    id="videoOfTheTruck"
                    {...register("videoOfTheTruck")}
                  />
                </div>

                <div className="event-form-item">
                  <label htmlFor="runsheetSeen" className="event-form-control">
                    Has the runsheet been seen?{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <Switch
                    checked={runsheetSeen}
                    onChange={(e) => {
                      setRunsheetSeen(e.target.checked);
                    }}
                    color="primary"
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </div>

                <div className="event-form-item">
                  <label htmlFor="openingStock" className="event-form-control">
                    Has the stock been opened?{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <Switch
                    checked={openingStock}
                    onChange={(e) => {
                      setOpeningStock(e.target.checked);
                    }}
                    color="primary"
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </div>

                <div className="event-form-item">
                  <label htmlFor="fuelChecked" className="event-form-control">
                    Have you checked the fuel?{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <Switch
                    checked={fuelChecked}
                    onChange={(e) => {
                      setFuelChecked(e.target.checked);
                    }}
                    color="primary"
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </div>

                <button className="form-button" type="submit">
                  Submit
                </button>
              </form>
            </Box>
          ) : null}
        </Container>
      ) : (
        <div style={{ textAlign: "center" }}>
          <img src={require("../../assets/img/loading.gif")} alt="loading" />
        </div>
      )}
    </>
  );
};

export default Sentry.withErrorBoundary(PreEventChecklist, {
  fallback: <ErrorFallbackComponent />,
});
