import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Container } from "@material-ui/core";
import Footer from "../../components/UI/Footer";
import {
  store,
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import { Form, Button, Alert } from "react-bootstrap";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import "./style.css";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import "react-datepicker/dist/react-datepicker.css";
import { Redirect } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ReCAPTCHA from "react-google-recaptcha";
import * as Sentry from "@sentry/react";
import Loader from "../../components/UI/Loader";
const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validateEmail = (email) => {
    const regex_pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex_pattern.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      alert("Please enter a valid email");
      return;
    }
    setLoading(true);
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "forget_password",
          adminEmailID: email,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        setLoading(false);
        if (dataResponse.success == true) {
          alert(
            "Your new Password has been sent to your Email. Please check your Email to Continue."
          );
          window.location.href = getFromLocalStorage(
            "LOGIN_ROUTE" ?? "/operator-login"
          );
        }
      })
      .catch((error) => {
        setLoading(false);
        Sentry.captureException(error);
        console.error(error);
        alert("Something went wrong");
      });
  };

  return (
    <>
      <div className="organiser">
        <Form onSubmit={handleSubmit}>
          <Loader loading={loading} />
          <Form.Group className="mb-3" size="lg" controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              autofocus
              type="email"
              placeholder="Enter Your Email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </Form.Group>
          <ReCAPTCHA
            style={{ marginTop: 20 }}
            sitekey="6Leq8AskAAAAAD-JPvpPqtR6vgeBCq_fajZvghoJ"
          />
          <Button block size="lg" type="submit">
            Submit
          </Button>
        </Form>
        <a
          href={getFromLocalStorage("LOGIN_ROUTE") ?? "/operator-login"}
          className="_97w4"
          style={{ marginTop: 16, textAlign: "center" }}
          target=""
        >
          Remember your Passowrd? Login
        </a>
      </div>
    </>
  );
};

export default Sentry.withErrorBoundary(ForgotPassword, {
  fallback: <ErrorFallbackComponent />,
});
