import React, { useState, useEffect } from "react";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import {
  getFromLocalStorage,
  deleteFromLocalStorage,
  saveToLocalStorage,
} from "../../store";
import "./style.css";
import useFecth from "../../hook/useFecth";
import DatePicker from "react-datepicker";
import moment from "moment";
import Reload from "../VendorApplyEventScreen/Component/Reload.js";
import * as Sentry from "@sentry/react";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import Loader from "../../components/UI/Loader/index.js";

function RegisterStall() {
  const [stallName, set_StallName] = useState("");
  const [availableCuisines, setAvailableCuisines] = useState([]);
  const [outletTypes, setOutletTypes] = useState([]);
  const [cuisine_type, set_cuisine_type] = useState("");
  const [outlet_type, set_outlet_type] = useState("");
  const [service_side, set_service_side] = useState("");
  const [nameError, set_nameError] = useState("");
  const [logoFile, set_logoFile] = useState([]);
  const [contactNumber, set_contactNumber] = useState("");
  const [contactError, set_contactError] = useState("");
  const [email, set_email] = useState("");
  const [emailError, set_emailError] = useState("");
  const [city, set_city] = useState("");
  const [cityError, set_cityError] = useState("");
  const [abnNumber, set_abnNumber] = useState("");
  const [abnError, set_abnError] = useState("");
  const [stallDescription, setStallDescription] = useState("");
  const [sustanablityPolicy, setSustanablityPolicy] = useState("");
  const [majorEventsDescription, SetMajorEventsDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [eventPhotos, set_eventPhotos] = useState([]);
  const [siteLayoutFile, set_siteLayoutFile] = useState(null);
  const [vansWidth, set_vansWidth] = useState("");
  const [vansHeight, set_vansHeight] = useState("");
  const [vansDepth, set_vansDepth] = useState("");
  const [exitDoorPos, set_exitDoorPos] = useState("");
  const [coolRoomWidth, set_coolRoomWidth] = useState("");
  const [coolRoomHeight, set_coolRoomHeight] = useState("");
  const [coolRoomDepth, set_coolRoomDepth] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isCoolRoomRequired, setIsCoolRoomRequired] = useState(false);
  const [fireSource, set_fireSource] = useState("");
  const [waterAccess, set_waterAccess] = useState("");
  const [numberOf10Amp, set_numberOf10Amp] = useState("");
  const [numberOf15Amp, set_numberOf15Amp] = useState("");
  const [numberOf20Amp, set_numberOf20Amp] = useState("");
  const [numberOf32Amp, set_numberOf32Amp] = useState("");
  const [doesDelivery, set_doesDelivery] = useState(false);
  const [deliveryFee, set_deliveryFee] = useState("");
  const [isPricesAlteredAllowed, set_isPricesAlteredAllowed] = useState(false);
  const [instagram_username, set_instagram_username] = useState("");
  const [facebook_username, set_facebook_username] = useState("");
  const [website, set_website] = useState("");
  const [otherComments, set_otherComments] = useState("");
  const [liability_certificate, set_liability_certificate] = useState([]);
  const [electrical_certificate, set_electrical_certificate] = useState([]);
  const [gas_certificate, set_gas_certificate] = useState([]);
  const [food_registration_docs, set_food_registration_docs] = useState([]);
  const [other_uploads, set_other_uploads] = useState([]);
  const [
    electrical_certificate_expiry_date,
    set_electrical_certificate_expiry_date,
  ] = useState(new Date());
  const [
    liability_certificate_expiry_date,
    set_liability_certificate_expiry_date,
  ] = useState(new Date());
  const [
    gas_certificate_expiry_date,
    set_gas_certificate_expiry_date,
  ] = useState(new Date());
  const [
    food_registration_certificate_expiry_date,
    set_food_registration_certificate_expiry_date,
  ] = useState(new Date());
  const [other_uploads_expiry_date, set_other_uploads_expiry_date] = useState(
    new Date()
  );

  const postVendors = (values) => {
    values.preventDefault();
    if (numberOf10Amp === "") {
      set_numberOf10Amp("0");
    }
    if (numberOf15Amp === "") {
      set_numberOf15Amp("0");
    }
    if (numberOf20Amp === "") {
      set_numberOf20Amp("0");
    }
    if (numberOf32Amp === "") {
      set_numberOf32Amp("0");
    }
    setIsLoading(true);
    const url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    // console.log(logoFile.file);
    // const logoMetadata = {
    //   Name: logoFile.file.name,
    //   LastModified: logoFile.file.lastModified,
    //   LastModifiedDate: logoFile.file.lastModifiedDate,
    //   Size: logoFile.file.size,
    //   Type: logoFile.file.type,
    //   WebkitRelativePath: logoFile.file.webkitRelativePath,
    // };
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "add_new_operator_stalls",
          operator_id: JSON.parse(getFromLocalStorage("Operator"))[0].id,
          title: stallName,
          // logo: logoMetadata,
          email: email,
          hometown: city,
          bio: stallDescription,
          contact: contactNumber,
          // photo: eventPhotos,
          location: "SRID=4326;POINT (144.5929945 -37.8969398)",
          accepting_orders: true,
          abn_number: abnNumber,
          truck_width: parseFloat(vansWidth),
          truck_depth: parseFloat(vansDepth),
          truck_height: parseFloat(vansHeight),
          // site_layout: siteLayoutFile,
          truck_exit: exitDoorPos,
          is_cooler_required: isCoolRoomRequired,
          cooler_width: parseFloat(coolRoomWidth),
          cooler_height: parseFloat(coolRoomHeight),
          cooler_depth: parseFloat(coolRoomDepth),
          fire_source: fireSource,
          water_access: waterAccess,
          number_of_10_amp: numberOf10Amp ? parseInt(numberOf10Amp) : 0,
          number_of_15_amp: numberOf15Amp ? parseInt(numberOf15Amp) : 0,
          number_of_20_amp: numberOf20Amp ? parseInt(numberOf20Amp) : 0,
          number_of_32_amp: numberOf32Amp ? parseInt(numberOf32Amp) : 0,
          delivery_fee: parseFloat(deliveryFee),
          instagram_username: instagram_username,
          facebook_username: facebook_username,
          website_url: website,
          is_prices_altered_allowed: isPricesAlteredAllowed,
          other_comments: otherComments,
          cuisine_type: cuisine_type,
          service_side: service_side,
          outlet_type: outlet_type,
          majorEventsDescription: majorEventsDescription,
          sustanablityPolicy: sustanablityPolicy,
          // electrical_certificate: electrical_certificate,
          // liability_certificate: liability_certificate,
          // liablity_certificate_expiry_date: moment(
          //   liability_certificate_expiry_date
          // ).format("DD MMM YYYY"),
          // gas_certificate: gas_certificate,
          // food_registration_certificate: food_registration_docs,
          // food_registration_certificate_expiry_date: moment(
          //   food_registration_certificate_expiry_date
          // ).format("DD MMM YYYY"),
          // other_uploads: other_uploads,
          // other_uploads_expiry_date: moment(other_uploads_expiry_date).format(
          //   "DD MMM YYYY"
          // ),
        },
      },
    });
    console.log(raw);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        setIsLoading(false);
        if (dataResponse.success == true) {
          alert("New Vendor has been added successfully!");
          handleReload();
        } else {
          alert("Please Use Unique email to create a new Truck");
          window.location.reload();
        }
      })
      .catch((error) => {
        setIsLoading(false);
        Sentry.captureException(error);
        console.error(error);
        alert("Vendor could not be added please try again");
        window.location.reload();
      });
  };
  const handleReload = () => {
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "admin_login",
          email: JSON.parse(getFromLocalStorage("Operator"))[0].email,
          password: JSON.parse(getFromLocalStorage("Operator"))[0].password,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (
          dataResponse.success == true &&
          dataResponse.data[0].is_operator == true
        ) {
          saveToLocalStorage("Operator", JSON.stringify(dataResponse.data));
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      })
      .finally(() => {
        // Finally, reload the page
        window.location.reload();
      });
  };

  const getAvailableCuisines = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.events;
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_cuisines",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.success === true) {
          setAvailableCuisines(response.cuisines);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };

  const getOutletTypes = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.operatorData;
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_all_outlet_types",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.success === true) {
          setOutletTypes(response.outlet_type);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };

  const handleCuisineTypeChange = (event) => {
    set_cuisine_type(event.target.value);
  };

  const handleOutletTypeChange = (event) => {
    set_outlet_type(event.target.value);
  };

  const handleStallNameChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^[a-zA-Z\s]*$/;
    if (!regex.test(inputValue)) {
      set_nameError("Please enter only letters and spaces");
    } else {
      set_StallName(inputValue);
      set_nameError("");
    }
  };

  const handleContactNumberChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^[0-9]*$/;
    if (!regex.test(inputValue)) {
      set_contactError("Please enter a valid contact number");
    } else {
      set_contactNumber(inputValue);
      set_contactError("");
    }
  };
  const handleEmailChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^[a-zA-Z0-9@.]*$/;
    if (!regex.test(inputValue)) {
      set_emailError("Please Enter a valid Email");
    } else {
      set_email(inputValue);
      set_emailError("");
    }
  };
  const handleCityChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^[a-zA-Z\s]*$/;
    if (!regex.test(inputValue)) {
      set_cityError("Please enter only letters and spaces");
    } else {
      set_city(inputValue);
      set_cityError("");
    }
  };
  const handleABNNumber = (event) => {
    const inputValue = event.target.value;
    const regex = /^[0-9]*$/;
    if (!regex.test(inputValue)) {
      set_abnError("Please enter a ABN number");
    } else {
      set_abnNumber(inputValue);
      set_abnError("");
    }
  };
  const handleMajorEventsDescription = (event) => {
    const inputDescription = event.target.value;
    SetMajorEventsDescription(inputDescription);

    if (inputDescription.length > 255) {
      setDescriptionError(
        "Please enter the description in 256 words or fewer."
      );
    } else {
      setDescriptionError("");
    }
  };

  const handleSustanablityPolicy = (event) => {
    const inputDescription = event.target.value;
    setSustanablityPolicy(inputDescription);

    if (inputDescription.length > 255) {
      setDescriptionError(
        "Please enter the description in 256 words or fewer."
      );
    } else {
      setDescriptionError("");
    }
  };

  const handleDescription = (event) => {
    const inputDescription = event.target.value;
    setStallDescription(inputDescription);

    if (inputDescription.length > 255) {
      setDescriptionError(
        "Please enter the description in 256 words or fewer."
      );
    } else {
      setDescriptionError("");
    }
  };

  const handleSiteLayoutFileChange = (fileItems3) => {
    set_siteLayoutFile(fileItems3[0].file);
  };
  const handlevansWidthChange = (event) => {
    set_vansWidth(event.target.value);
  };
  const handlevansHeightChange = (event) => {
    set_vansHeight(event.target.value);
  };
  const handlevansDepthChange = (event) => {
    set_vansDepth(event.target.value);
  };
  const handleCoolRoomWidthChange = (event) => {
    set_coolRoomWidth(event.target.value);
  };
  const handleCoolRoomHeightChange = (event) => {
    set_coolRoomHeight(event.target.value);
  };
  const handleCoolRoomDepthChange = (event) => {
    set_coolRoomDepth(event.target.value);
  };
  const handleFireSourceChange = (event) => {
    set_fireSource(event.target.value);
  };
  const handleExitDoorPosChange = (event) => {
    set_exitDoorPos(event.target.value);
  };
  const handleWaterAccessChange = (event) => {
    set_waterAccess(event.target.value);
  };
  const handleNumberOf10AmpChange = (event) => {
    set_numberOf10Amp(event.target.value);
  };
  const handleNumberOf15AmpChange = (event) => {
    set_numberOf15Amp(event.target.value);
  };
  const handleNumberOf20AmpChange = (event) => {
    set_numberOf20Amp(event.target.value);
  };
  const handleNumberOf32AmpChange = (event) => {
    set_numberOf32Amp(event.target.value);
  };
  const handleDeliveryFeeChange = (event) => {
    set_deliveryFee(event.target.value);
  };
  const handleInstagramUsernameChange = (event) => {
    set_instagram_username(event.target.value);
  };
  const handleFacebookUsernameChange = (event) => {
    set_facebook_username(event.target.value);
  };
  const handleWebsiteChange = (event) => {
    set_website(event.target.value);
  };
  const handleOtherCommentsChange = (event) => {
    set_otherComments(event.target.value);
  };
  const pull_data = (selectedFiles, fieldName) => {
    const updateField = eval(fieldName);
    updateField(selectedFiles[0]);
  };
  useEffect(() => {
    console.log(logoFile.file);
  }, [logoFile]);
  useEffect(() => {
    getAvailableCuisines();
    getOutletTypes();
  }, []);

  return (
    <div className="form-style-5">
      <form onSubmit={postVendors} style={{ position: "relative" }}>
        <Loader loading={isLoading} />
        <div>
          <legend>
            <span className="number">Vendor DETAILS:</span>
          </legend>
          <label>Please Enter Your Trading Name for eg: Burger Splash</label>
          <input
            placeholder="Vendor Name*:"
            type="text"
            id="stallName"
            name="stallName"
            value={stallName}
            onChange={handleStallNameChange}
          />
          {nameError && <div style={{ color: "red" }}>{nameError}</div>}
          <label>Please Enter Your cuisine of food for example: Mexican</label>
          <select defaultValue="" onChange={handleCuisineTypeChange}>
            <option value="" disabled>
              Select cuisine type
            </option>
            {availableCuisines &&
              availableCuisines.map((cuisine) => (
                <option key={cuisine.label} value={cuisine.value}>
                  {cuisine.value}
                </option>
              ))}
          </select>
          <label>
            Please Select Your Outlet Type for example: Trailer, Truck, Van
          </label>
          <select defaultValue="" onChange={handleOutletTypeChange}>
            <option value="" disabled>
              Select outlet type
            </option>
            {outletTypes &&
              outletTypes.map((type) => (
                <option key={type.id} value={type.id}>
                  {type.outlet_type}
                </option>
              ))}
          </select>
          <label>
            Please Enter Your Phone number for example: +61223671893
          </label>
          <input
            placeholder="Contact Number*:"
            type="number"
            id="contactNumber"
            name="contactNumber"
            value={contactNumber}
            onChange={handleContactNumberChange}
          />
          {contactError && <div style={{ color: "red" }}>{contactError}</div>}
          <label>Please Enter Your Email for example: johnsimth@test.com</label>
          <input
            placeholder="Email*:"
            type="text"
            id="email"
            name="email"
            value={email}
            onChange={handleEmailChange}
          />
          {emailError && <div style={{ color: "red" }}>{emailError}</div>}
          <input
            placeholder="City*:"
            type="text"
            id="city"
            name="city"
            value={city}
            onChange={handleCityChange}
          />
          {cityError && <div style={{ color: "red" }}>{cityError}</div>}

          <input
            placeholder="ABN Number:"
            type="number"
            id="abnNumber"
            name="abnNumber"
            value={abnNumber}
            onChange={handleABNNumber}
          />
          {abnError && <div style={{ color: "red" }}>{abnError}</div>}
        </div>
        {/* <div class>
          <label htmlFor="logoFile">Upload your Logo*:</label>
          <FileUploader fieldName={"set_logoFile"} func={pull_data} />
        </div>
        <div>
          <label htmlFor="logoFile">Upload photos of similar events*:</label>
          <FileUploader fieldName={"set_eventPhotos"} func={pull_data} />
        </div> */}

        <label>Write your sustanable policy</label>
        <textarea
          placeholder="Description of your sustanable policy *:"
          type="text"
          rows="4"
          cols="75"
          value={sustanablityPolicy}
          onChange={handleSustanablityPolicy}
        ></textarea>

        <label>Major Events the truck has been to</label>
        <textarea
          placeholder="Description of major events you have done with this outlet *:"
          type="text"
          rows="4"
          cols="75"
          value={majorEventsDescription}
          onChange={handleMajorEventsDescription}
        ></textarea>

        <label>Write your bio in 300 words about your stall</label>
        <textarea
          placeholder="Description of what type of food/drink/service you want to sell/provide(less than 300 words)*:"
          type="text"
          rows="4"
          cols="75"
          value={stallDescription}
          onChange={handleDescription}
        ></textarea>
        {descriptionError && (
          <div style={{ color: "red" }}>{descriptionError}</div>
        )}
        {/* <div>
          <label htmlFor="laibility_certificate">
            Upload your Public and Product liability Certificate*:
          </label>
          <FileUploader
            fieldName={"set_liability_certificate"}
            func={pull_data}
          />
        </div>
        <p>Expiry Date</p>
        <DatePicker
          dateFormat={"dd/MM/yyyy"}
          selected={liability_certificate_expiry_date}
          onChange={(date) => set_liability_certificate_expiry_date(date)}
        /> */}
        <legend>
          <span className="number">STALL REQUIREMENTS:</span>
        </legend>

        {/* <div>
          <label htmlFor="logoFile">Upload the site layout file*:</label>
          <FileUploader fieldName={"set_siteLayoutFile"} func={pull_data} />
        </div> */}
        <label htmlFor="logoFile">
          Clearly indicate width(service frontage), depth(from left indicator to
          right indicator) and height(from floor to any extraction) in meters *.
        </label>
        <input
          placeholder="Service Frontage (in meters):"
          id="vansWidth"
          name="vansWidth"
          value={vansWidth}
          onChange={handlevansWidthChange}
          type="number"
        />
        <input
          placeholder="Depth (in meters):"
          id="vansHeight"
          name="vansHeight"
          value={vansHeight}
          onChange={handlevansHeightChange}
          type="number"
        />
        <input
          placeholder="Height (in meters):"
          id="vansDepth"
          name="vansDepth"
          value={vansDepth}
          onChange={handlevansDepthChange}
          type="number"
        />
        <label>Door Positions* </label>
        <label className="paddingPlease">
          What is the position of your exit door at your stall?
        </label>
        <select defaultValue="" onChange={handleExitDoorPosChange}>
          <option value="" disabled>
            Please select
          </option>
          <option value="front">Front</option>
          <option value="back">Back</option>
          <option value="right">Right</option>
          <option value="left">Left</option>
        </select>
        <label className="paddingPlease">
          What is the service side of your stall?
        </label>
        <select defaultValue="" onChange={handleExitDoorPosChange}>
          <option value="" disabled>
            Please select
          </option>
          <option value="front">Not Required</option>
          <option value="back">Driver Side</option>
          <option value="right">Passenger Side</option>
          <option value="left">Rear</option>
        </select>
        <label>
          <input
            className="paddingPlease"
            type="checkbox"
            checked={isCoolRoomRequired}
            onChange={(e) => setIsCoolRoomRequired(e.target.checked)}
          />
          <span className="paddingPlease">I need a cool room/freezer room</span>
        </label>
        {isCoolRoomRequired && (
          <div>
            <label>Enter the size in meters *</label>
            <label>Size of the coolroom*</label>
            <label className="paddingPlease">Height (in meters):</label>
            <input
              placeholder="Height"
              id="coolRoomHeight"
              name="coolRoomHeight"
              value={coolRoomHeight}
              onChange={handleCoolRoomHeightChange}
              type="number"
            />
            <label className="paddingPlease">Width (in meters):</label>
            <input
              placeholder="Width"
              id="coolRoomWidth"
              name="coolRoomWidth"
              value={coolRoomWidth}
              onChange={handleCoolRoomWidthChange}
              type="number"
            />
            <label className="paddingPlease">Depth (in meters):</label>
            <input
              placeholder="Depth"
              id="coolRoomDepth"
              name="coolRoomDepth"
              value={coolRoomDepth}
              onChange={handleCoolRoomDepthChange}
              type="number"
            />
          </div>
        )}
        <label className="paddingPlease">
          What is your ignition source of fire?*
        </label>
        <select defaultValue="" onChange={handleFireSourceChange}>
          <option value="" disabled>
            Please select
          </option>
          <option value="Gas">Gas</option>
          <option value="Coal">Coal</option>
          <option value="Wood Fire">Wood Fire</option>
          <option value="None">None</option>
        </select>
        <legend>
          <span className="number">Water Requirements:</span>
        </legend>
        <label>Water Access*</label>
        <select defaultValue="" onChange={handleWaterAccessChange}>
          <option value="" disabled>
            Please select
          </option>
          <option value="Not Required">Not Required</option>
          <option value="Self Sufficient">Self Sufficient</option>
          <option value="Bucket / Barrel Fill">Bucket / Barrel Fill</option>
          <option value="Hose Connection">Hose Connection</option>
          <option value="Tap Access">Tap Access</option>
        </select>
        <label>
          <strong>NOTE: </strong>retail cannot apply for Water Access
        </label>
        <div>
          <legend>
            <span className="number">Power Requirements:</span>
          </legend>
          <label>
            2400 watts is 10 amp and 3600 watts is 15 amp, 3 phase 20amp is
            10,800 watts
          </label>
          <input
            placeholder="Number of 10 Amp Outlet/s:"
            type="number"
            id="numberOf10Amp"
            name="numberOf10Amp"
            value={numberOf10Amp}
            onChange={handleNumberOf10AmpChange}
          />
          <input
            placeholder="Number of 15 Amp Outlet/s:"
            type="number"
            id="numberOf15Amp"
            name="numberOf15Amp"
            value={numberOf15Amp}
            onChange={handleNumberOf15AmpChange}
          />
          <input
            placeholder="Number of 20 amps 3 phase Outlet/s:"
            type="number"
            id="numberOf20Amp"
            name="numberOf20Amp"
            value={numberOf20Amp}
            onChange={handleNumberOf20AmpChange}
          />
          <input
            placeholder="Number of 32 amps 3 phase Outlet/s:"
            type="number"
            id="numberOf32Amp"
            name="numberOf32Amp"
            value={numberOf32Amp}
            onChange={handleNumberOf32AmpChange}
          />
          <label>
            <strong>NOTE: </strong>Some events prohibits catering operators to
            bring generators on site & must use event power.
          </label>
          {/* <label htmlFor="electrical_certificate">
            Upload your Electrical Certificate:
          </label>
          <FileUploader
            fieldName={"set_electrical_certificate"}
            func={pull_data}
          /> */}
        </div>
        {/* <div>
          <legend>
            <span className="number">Gas Certificate</span>
          </legend>
          <label htmlFor="gas_certificate">Upload your Gas Certificate:</label>
          <FileUploader
            fieldName={"set_electrical_certificate"}
            func={pull_data}
          />
        </div> */}
        {/* <div>
          <legend>
            <span className="number">Food Registration Certificate*</span>
          </legend>
          <label htmlFor="food_registration_docs">
            Upload your Food Registration Certificate*:
          </label>
          <FileUploader
            fieldName={"set_food_registration_docs"}
            func={pull_data}
          />
          <p>Expiry Date</p>
          <DatePicker
            dateFormat={"dd/MM/yyyy"}
            selected={food_registration_certificate_expiry_date}
            onChange={(date) =>
              set_food_registration_certificate_expiry_date(date)
            }
          />
        </div> */}
        <legend>
          <span className="number">Other Services:</span>
        </legend>
        <label>
          <input
            className="paddingPlease"
            type="checkbox"
            checked={doesDelivery}
            onChange={(e) => set_doesDelivery(e.target.checked)}
          />
          <span className="paddingPlease">
            Does the vendor provide delivery service?
          </span>
        </label>
        {doesDelivery && (
          <div>
            <input
              placeholder="Delivey Fee*:"
              id="deliveryFee"
              name="deliveryFee"
              value={deliveryFee}
              onChange={handleDeliveryFeeChange}
              type="number"
            />
          </div>
        )}
        <label>
          <strong>
            Where The Truck™ reserves the right to set retail prices
          </strong>
        </label>
        <label>
          <input
            className="paddingPlease"
            type="checkbox"
            checked={isPricesAlteredAllowed}
            onChange={(e) => set_isPricesAlteredAllowed(e.target.checked)}
          />
          <span className="paddingPlease">
            I am willing to have my prices altered.
          </span>
        </label>
        {/* <div>
          <legend>
            <span className="number">Other Uploads:</span>
          </legend>
          <label htmlFor="electrical_certificate">
            Upload your Extra Documents:
          </label>
          <FileUploader fieldName={"set_siteLayoutFile"} func={pull_data} />
        </div> */}
        <legend>
          <span className="number">Social Handles:</span>
        </legend>
        <input
          placeholder="Website URL: "
          type="text"
          id="website"
          name="website"
          value={website}
          onChange={handleWebsiteChange}
        />
        <input
          placeholder="Facebook Username: "
          type="text"
          id="facebook_username"
          name="facebook_username"
          value={facebook_username}
          onChange={handleFacebookUsernameChange}
        />
        <input
          placeholder="Instagram Username: "
          type="text"
          id="instagram_username"
          name="instagram_username"
          value={instagram_username}
          onChange={handleInstagramUsernameChange}
        />
        <legend>
          <span className="number">Additional Comments:</span>
        </legend>
        <textarea
          placeholder="Comments:"
          type="text"
          rows="4"
          cols="75"
          onChange={handleOtherCommentsChange}
          value={otherComments}
        ></textarea>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default Sentry.withErrorBoundary(RegisterStall, {
  fallback: <ErrorFallbackComponent />,
});
