import React, { useEffect, useRef, useState } from "react";
import ModalDate from "./ModalDate";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { Box, Modal } from "@mui/material";
import "./style.css";
import { Card } from "react-bootstrap";
import CardMedia from "@mui/material/CardMedia";
import "react-phone-input-2/lib/style.css";
import { AWS_URL } from "../../../assets/Constants";
import { ItemTypes } from "../constant/Constant";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import RegModal from "./RegModal";
import config from "../../../services/apiConfig";
import { apiPaths } from "../../../services/apiPath";
import moment from "moment";
import "moment-timezone";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { getFromLocalStorage } from "../../../store";
import Divider from "@mui/joy/Divider";
import Stack from "@mui/material/Stack";

import {
  dropTargetForElements, // NEW
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import invariant from "tiny-invariant";

const applySuccess = () => {
  alert("Eoi submitted successfully!");
  window.location.reload();
};

const style = {
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "550px",
  height: "550px",
  overflow: "auto",
  padding: "20px",
};

const ComfirmationComponent = ({
  openNotice,
  setopenNotice,
  vendorList,
  event,
  url,
  setVendorList,
  isSingleDayEvent,
}) => {
  return (
    <Dialog
      open={openNotice}
      onClose={() => setopenNotice(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
      <DialogContent>
        {isSingleDayEvent ? (
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to add these vendors to this event?
          </DialogContentText>
        ) : (
          <DialogContentText id="alert-dialog-description">
            Please note: This is a multi-day event. Please make sure to review
            all event dates and select your preferred date/s available before
            submitting your application. Vendors who submit without review will
            be applying for all active event dates and committing your
            availability. Click the 'select dates' link above to review or
            continue to 'submit' to apply all dates. WTT team.
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            let myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            const vendorId = [];
            vendorList.map((item) => {
              if (
                item.logo === null ||
                item.liablity_certificate === null ||
                item.food_refistration_docs === null
              ) {
                alert(
                  "Please upload all the documents before submitting application"
                );
                window.location.reload();
              } else if (item && item.meals.length === 0) {
                alert(
                  "Please add meals with images in the system before submitting application"
                );
                window.location.reload();
              } else {
                vendorId.push(item.id);
                const selectedEoiDates = localStorage.getItem(
                  "selected_eoi_dates"
                );
                const raw = JSON.stringify({
                  payload: {
                    body: {
                      query_type: "apply-for-event",
                      eoi_id: event.eventDetails.id,
                      operator_id: JSON.parse(
                        getFromLocalStorage("Operator")
                      )[0].id,
                      vendor_outlets: vendorId,
                      selected_dates: selectedEoiDates,
                    },
                  },
                });
                var requestOptions = {
                  method: "POST",
                  headers: myHeaders,
                  body: raw,
                  redirect: "follow",
                };
                try {
                  fetch(url, requestOptions).then((response) => {
                    if (response.status == 200) {
                      applySuccess();
                      setopenNotice(false);
                    }
                  });
                } catch (error) {}
              }
            });
          }}
        >
          Confirm
        </Button>
        <Button onClick={() => setopenNotice(false)}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

const ApplyModal = ({ open, onClose, vendors = [], handleSelectVendor }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>{"Vendors"}</DialogTitle>
    <DialogContent>
      {vendors.length > 0 ? (
        vendors.map((vendor, index) => (
          <Paper key={index} elevation={12} style={{ margin: 10, padding: 10 }}>
            <div>{vendor.title}</div>
            <Button
              variant="contained"
              style={{ margin: 10 }}
              onClick={() => {
                handleSelectVendor(vendor);
                onClose();
              }}
            >
              Select
            </Button>
          </Paper>
        ))
      ) : (
        <div>No vendors available</div>
      )}
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Close</Button>
    </DialogActions>
  </Dialog>
);
const EventCardMobile = (props) => {
  const { event, vendors } = props;

  const cardRef = useRef(null); // Create a ref for the card

  const url = config.devUrl + apiPaths.vendorApplication;
  const [vendorList, setVendorList] = useState([]);
  const [openNotice, setopenNotice] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const [showDetails, setShowDetails] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedVendors, setSelectedVendors] = useState([]);
  const handleCardClose = () => setShowDetails(false);

  const handleSelectVendor = (vendor) => {
    setSelectedVendors((prevSelected) => [...prevSelected, vendor]);
    setVendorList((prevVendors) => [...prevVendors, vendor]);
    //setOpenModal(false);
  };

  const removeVendor = (index, vendor) => {
    setSelectedVendors((st) => {
      const temp = [...st];

      const removedItem = temp.splice(index, 1);

      if (removedItem.find((item) => item.user_ptr_id === vendor.user_ptr_id))
        return [...temp];
      else return st;
    });
    setVendorList((st) => {
      const temp = [...st];

      const removedItem = temp.splice(index, 1);

      if (removedItem.find((item) => item.user_ptr_id === vendor.user_ptr_id))
        return [...temp];
      else return st;
    });
  };

  const handleCanDrop = (item) => {
    if (
      event &&
      event.eventDetails &&
      event.eventDetails.pending_applications &&
      event.eventDetails.pending_applications
        .map((vendor) => vendor.vendor_outlets__id)
        .find((vndr) => vndr === item.id)
    ) {
      return false;
    }
    if (
      event &&
      event.eventDetails &&
      event.eventDetails.accepted_vendors &&
      event.eventDetails.accepted_vendors
        .map((vendor) => vendor.accepted_outlets__id)
        .find((vndr) => vndr === item.id)
    ) {
      return false;
    }
    if (
      event &&
      event.eventDetails &&
      event.eventDetails.rejected_vendors &&
      event.eventDetails.rejected_vendors
        .map((vendor) => vendor.rejected_outlets__id)
        .find((vndr) => vndr === item.id)
    ) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    const cardEl = cardRef.current;
    invariant(cardEl); // Ensure the card element exists

    return dropTargetForElements({
      element: cardEl,
      onDrop: (args) => {
        const { source } = args;

        if (
          source.data &&
          source.data.type === "stall_item" &&
          source.data.stall_item &&
          handleCanDrop(source.data.stall_item)
        ) {
          handleSelectVendor(source.data.stall_item);
        }
      },
      getIsSticky: () => true, // To make a drop target "sticky"
      onDragEnter: (args) => {
        console.log("onDragEnter", args);
      },
    });
  }, []);

  return (
    <>
      <div>
        <Card
          style={{
            display: "flex",
            justifyItems: "center",
            backgroundColor: "#f3f1ef",
          }}
          ref={cardRef}
        >
          <img
            style={{
              marginTop: 10,
              height: 250,
              pointerEvents: "none",
              touchAction: "none",
            }}
            className="d-block w-100"
            src={
              event.eventDetails && event.eventDetails.eoi_cover
                ? AWS_URL + "/" + event.eventDetails.eoi_cover
                : "path/to/default/image.jpg"
            }
          ></img>
          <Card.Body
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Card.Title>
              {event.eventDetails && event.eventDetails.eoi_event_name
                ? event.eventDetails.eoi_event_name
                : ""}
            </Card.Title>
            <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />

            <Card.Text>EOI APPLICATIONS</Card.Text>
            <Stack
              direction="row"
              spacing={2}
              style={{ maxWidth: 200, overflowX: "auto" }}
            >
              {event &&
              event.eventDetails &&
              event.eventDetails.pending_applications
                ? event.eventDetails.pending_applications
                    .filter((vendor) => vendor.vendor_outlets__id)
                    .map((vendor, idx) => {
                      const statusColor = {
                        pending: "#ffcc00",
                        accepted: "#2db92d",
                        rejected: "#e62e00",
                      };

                      const statusText = {
                        pending: "Pending",
                        accepted: "Accepted",
                        rejected: "Rejected",
                      };

                      return (
                        <div
                          key={idx}
                          style={{
                            position: "relative",
                            textAlign: "center",
                          }}
                        >
                          <div
                            style={{
                              width: 50,
                              position: "relative",
                              height: 50,
                              borderRadius: "50%",
                              overflow: "hidden",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              border: "2px solid #ccc",
                            }}
                          >
                            <img
                              src={AWS_URL + "/" + vendor?.vendor_outlets__logo}
                              alt="vendor_logo"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              top: 0,
                              right: 0,
                              width: 10,
                              height: 10,
                              borderRadius: "50%",
                              backgroundColor: statusColor.pending,
                              margin: "5px auto 0",
                            }}
                          ></div>
                          <div
                            style={{
                              visibility: "hidden",
                              backgroundColor: "#555",
                              color: "#fff",
                              textAlign: "center",
                              borderRadius: "6px",
                              padding: "5px",
                              position: "absolute",
                              zIndex: 1,
                              bottom: "70px",
                              left: "50%",
                              transform: "translateX(-50%)",
                              whiteSpace: "nowrap",
                              opacity: 0,
                              transition: "opacity 0.3s",
                            }}
                            className="tooltip"
                          >
                            <strong> {vendor?.vendor_outlets__title}</strong>
                            <br />
                            Pending
                          </div>
                        </div>
                      );
                    })
                : null}
              {event &&
              event.eventDetails &&
              event.eventDetails.accepted_vendors
                ? event.eventDetails.accepted_vendors
                    .filter((vendor) => vendor.accepted_outlets__id)
                    .reduce((accumulator, current) => {
                      // Check if the ID is already in the accumulator
                      if (
                        !accumulator.some(
                          (item) =>
                            item.accepted_outlets__id ===
                            current.accepted_outlets__id
                        )
                      ) {
                        accumulator.push(current); // Add the current object if ID is unique
                      }
                      return accumulator; // Return the accumulator for the next iteration
                    }, [])
                    .map((vendor, idx) => {
                      const statusColor = {
                        pending: "#ffcc00",
                        accepted: "#2db92d",
                        rejected: "#e62e00",
                      };

                      const statusText = {
                        pending: "Pending",
                        accepted: "Accepted",
                        rejected: "Rejected",
                      };

                      return (
                        <div
                          key={idx}
                          style={{
                            position: "relative",
                            textAlign: "center",
                          }}
                        >
                          <div
                            style={{
                              width: 50,
                              position: "relative",
                              height: 50,
                              borderRadius: "50%",
                              overflow: "hidden",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              border: "2px solid #ccc",
                            }}
                          >
                            <img
                              src={
                                AWS_URL + "/" + vendor?.accepted_outlets__logo
                              }
                              alt="vendor_logo"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              top: 0,
                              right: 0,
                              width: 10,
                              height: 10,
                              borderRadius: "50%",
                              backgroundColor: statusColor.accepted,
                              margin: "5px auto 0",
                            }}
                          ></div>
                          <div
                            style={{
                              visibility: "hidden",
                              backgroundColor: "#555",
                              color: "#fff",
                              textAlign: "center",
                              borderRadius: "6px",
                              padding: "5px",
                              position: "absolute",
                              zIndex: 1,
                              bottom: "70px",
                              left: "50%",
                              transform: "translateX(-50%)",
                              whiteSpace: "nowrap",
                              opacity: 0,
                              transition: "opacity 0.3s",
                            }}
                            className="tooltip"
                          >
                            <strong> {vendor?.accepted_outlets__title}</strong>
                            <br />
                            Accepted
                          </div>
                        </div>
                      );
                    })
                : null}
              {event &&
              event.eventDetails &&
              event.eventDetails.rejected_vendors
                ? event.eventDetails.rejected_vendors
                    .filter((vendor) => vendor.rejected_outlets__id)
                    .reduce((accumulator, current) => {
                      // Check if the ID is already in the accumulator
                      if (
                        !accumulator.some(
                          (item) =>
                            item.rejected_outlets__id ===
                            current.rejected_outlets__id
                        )
                      ) {
                        accumulator.push(current); // Add the current object if ID is unique
                      }
                      return accumulator; // Return the accumulator for the next iteration
                    }, [])
                    .map((vendor, idx) => {
                      const statusColor = {
                        pending: "#ffcc00",
                        accepted: "#2db92d",
                        rejected: "#e62e00",
                      };

                      const statusText = {
                        pending: "Pending",
                        accepted: "Accepted",
                        rejected: "Rejected",
                      };

                      return (
                        <div
                          key={idx}
                          style={{
                            position: "relative",
                            textAlign: "center",
                          }}
                        >
                          <div
                            style={{
                              width: 50,
                              position: "relative",
                              height: 50,
                              borderRadius: "50%",
                              overflow: "hidden",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              border: "2px solid #ccc",
                            }}
                          >
                            <img
                              src={
                                AWS_URL + "/" + vendor?.rejected_outlets__logo
                              }
                              alt="vendor_logo"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              top: 0,
                              right: 0,
                              width: 10,
                              height: 10,
                              borderRadius: "50%",
                              backgroundColor: statusColor.rejected,
                              margin: "5px auto 0",
                            }}
                          ></div>
                          <div
                            style={{
                              visibility: "hidden",
                              backgroundColor: "#555",
                              color: "#fff",
                              textAlign: "center",
                              borderRadius: "6px",
                              padding: "5px",
                              position: "absolute",
                              zIndex: 1,
                              bottom: "70px",
                              left: "50%",
                              transform: "translateX(-50%)",
                              whiteSpace: "nowrap",
                              opacity: 0,
                              transition: "opacity 0.3s",
                            }}
                            className="tooltip"
                          >
                            <strong> {vendor?.rejected_outlets__title}</strong>
                            <br />
                            Rejected
                          </div>
                        </div>
                      );
                    })
                : null}
            </Stack>
            <div style={{ marginBottom: "20px" }}></div>
            <Button
              color="primary"
              variant="contained"
              style={{ margin: 10 }}
              onClick={() => setShowDetails(true)}
            >
              View Event
            </Button>
            <div>
              <Button
                color="primary"
                variant="contained"
                style={{ margin: 10 }}
                onClick={() => setOpenModal(true)}
              >
                Apply
              </Button>
              <ApplyModal
                open={openModal}
                onClose={() => setOpenModal(false)}
                vendors={vendors}
                handleSelectVendor={handleSelectVendor}
              />
            </div>
            {selectedVendors.length > 0 && (
              <Button
                variant="contained"
                style={{ margin: 10, backgroundColor: "#ef3f6d" }}
                onClick={() => setopenNotice(true)}
              >
                Submit Application
              </Button>
            )}
            {event.eventDetails && (
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={showDetails}
                onClose={handleCardClose}
              >
                <Box sx={style}>
                  <h1 style={{ textAlign: "center" }}>
                    {event.eventDetails.eoi_event_name}
                  </h1>
                  <h3>{event.eventDetails.eoi_description}</h3>
                  <div>
                    <strong>Event Location: </strong>
                    {event.eventDetails.event_organiser_address}
                  </div>
                  <div>
                    <strong>Event Bump in Date: </strong>
                    {" " +
                      moment
                        .utc(event.eventDetails.bump_in_date_time)
                        .tz("Australia/Melbourne")
                        .format("DD-MMM-YYYY HH:mm:ss")}
                  </div>
                  <div>
                    <strong>Event Bump Out Date: </strong>
                    {" " +
                      moment
                        .utc(event.eventDetails.bump_out_date_time)
                        .tz("Australia/Melbourne")
                        .format("DD-MMM-YYYY HH:mm:ss")}
                  </div>
                  <div>
                    <strong>Event Start Date:</strong>
                    {" " +
                      moment
                        .utc(event.eventDetails.event_start_date_time)
                        .tz("Australia/Melbourne")
                        .format("DD-MMM-YYYY HH:mm:ss")}
                  </div>
                  <div>
                    <strong>Event End Date: </strong>
                    {" " +
                      moment
                        .utc(event.eventDetails.event_end_date_time)
                        .tz("Australia/Melbourne")
                        .format("DD-MMM-YYYY HH:mm:ss")}
                  </div>

                  <div>
                    <strong>Email: </strong>
                    {event.eventDetails.eoi_email_id}
                  </div>

                  <div>
                    <strong>Water Access: </strong>
                    {event.eventDetails.event_water_access}
                  </div>
                  <div>
                    <strong>Service Type:</strong>
                    {event.eventDetails.services}
                  </div>
                  <div>
                    <strong>Members Commission: </strong>
                    {` ${event.eventDetails.members_commission}  %`}
                  </div>
                  <div>
                    <strong>Non Members Commission: </strong>
                    {` ${event.eventDetails.non_members_commission} %`}
                  </div>
                  <div>
                    <strong>Up Front Site Fees: </strong>
                    {event.eventDetails.site_fees}
                  </div>
                  <div>
                    <strong>Bond Amount: </strong>
                    {event.eventDetails.bond_amount}
                  </div>
                  <div>
                    <a
                      href={
                        AWS_URL +
                        "/" +
                        event.eventDetails.event_terms_and_conditions
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      EOI Terms and Conditions
                    </a>
                  </div>
                  <div>
                    <Button
                      color="primary"
                      variant="contained"
                      style={{ margin: 10 }}
                      onClick={handleCardClose}
                    >
                      Close
                    </Button>
                  </div>
                </Box>
              </Modal>
            )}
          </Card.Body>
          <div
            style={{
              display: "flex",
              height: "100%",
              width: "auto",
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: "#fff",
              textAlign: "center",
              overflowX: "scroll",
            }}
          >
            {(vendorList || []).map((vendor, index) => (
              <div key={index}>
                {" "}
                <Paper elevation={12} style={{ margin: 10 }}>
                  <CardMedia
                    onClick={() => setShowModal(true)}
                    style={{
                      width: 100,
                      height: 80,
                      overflow: "hidden",
                    }}
                    image={AWS_URL + "/" + vendor.logo}
                    title="vendor_logo"
                  />
                  <RegModal
                    show={showModal}
                    handleClose={handleClose}
                    itemDetail={vendor}
                    viewOnly={true}
                  />
                  {event.eventDetails.is_single_date_event === false ? (
                    <ModalDate eoi_id={event.eventDetails.id} />
                  ) : (
                    <div></div>
                  )}
                  <DeleteForeverOutlinedIcon
                    onClick={() => removeVendor(index, vendor)} // Assuming 'keyIndex' should be 'index' based on context
                    style={{ position: "relative", top: "0px", right: "0px" }}
                  />
                </Paper>
              </div>
            ))}
          </div>
          <ComfirmationComponent
            openNotice={openNotice}
            setopenNotice={setopenNotice}
            vendorList={vendorList}
            event={event}
            setVendorList={setVendorList}
            url={url}
            isSingleDayEvent={event.eventDetails?.is_single_date_event || false}
          />
        </Card>
        {vendorList?.length > 0 && (
          <div className="submit-button">
            <Button
              variant="contained"
              style={{ margin: 10 }}
              onClick={() => setopenNotice(true)}
            >
              Submit Application
            </Button>
          </div>
        )}
      </div>
    </>
  );
};
export default EventCardMobile;
