import React, { Component, useEffect, useMemo, useState } from "react";
import Container from "@mui/material/Container";
import Button from "react-bootstrap/Button";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import * as Sentry from "@sentry/react";

import { getFromLocalStorage } from "../../store";
import { Alert, InputNumber, Select, Switch, Table } from "antd";

// import CalendarView from "./CalendarView";
import VendorNavBar from "../VendorApplyEventScreen/Component/VendorNavBar";
import ErrorFallbackComponent from "../ErrorFallBackScreen";
import Column from "antd/es/table/Column";
import "react-datepicker/dist/react-datepicker.css";
import ReactDatePicker from "react-datepicker";

const VendorStockUsageScreen = () => {
  const [vendors, setVendors] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedVendorId, setSelectedVendorId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [usageData, setUsageData] = useState([]);

  const fetchVendorIds = () => {
    const operator = JSON.parse(getFromLocalStorage("Operator"));
    if (!operator || operator.length === 0) {
      alert("Operator not found. Please Log In again to Continue.");
      return;
    }

    const operatorId = operator[0].id;
    setIsLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_operator_details_for_stall_ids",
          operator_id: operatorId,
        },
      },
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(config.BASE_URI + apiPaths.adminData, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setIsLoading(false);
        if (result.success === true) {
          setVendors(result.stalls ?? []);
        } else {
          alert(result.error);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
        Sentry.captureException(error);
      });
  };

  useEffect(() => {
    fetchVendorIds();
  }, []);

  const handleGetStockUsage = () => {
    //     if (isNaN(targetAmount) || targetAmount <= 0) {
    //       alert("Please enter valid Target Amount.");
    //       return;
    //     }
    setFetchLoading(true);

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "fetch_stock_useage_by_dates",

          vendor_id: selectedVendorId,
          start_date: startDate,
          end_date: endDate,
        },
      },
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(config.BASE_URI + apiPaths.operatorData, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setFetchLoading(false);

        if (result && result.success && result.usage_data) {
          setUsageData(result.usage_data);
        } else {
          alert(
            result.message
              ? result.message
              : "Something went wrong while Getting Usage Data.\nPlease try again later."
          );
        }
      })
      .catch((error) => {
        setFetchLoading(false);
        console.error(error);
        alert(
          "Something went wrong while Getting Usage Data.\nPlease try again later."
        );
        Sentry.captureException(error);
      });
  };

  return (
    <div>
      <VendorNavBar />
      <Container
        //     maxWidth={!isCalendarView ? 1110 : 2000}
        style={{
          borderWidth: 1,
          borderColor: "grey",
          borderStyle: "solid",
          borderRadius: 5,
          padding: 15,
        }}
      >
        <h1 style={{ fontSize: "30px", textAlign: "left", fontWeight: 600 }}>
          Stock Usage{" "}
        </h1>

        {isLoading ? (
          <div
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",

              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={require("../../assets/img/loading.gif")}></img>
            <p>Loading...</p>
          </div>
        ) : fetchLoading ? (
          <div
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",

              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={require("../../assets/img/loading.gif")}></img>
            <p>Getting Stock Usage Data...</p>
          </div>
        ) : (
          <>
            <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 24,
                  marginBottom: 24,
                }}
              >
                <label>Select Vendor:</label>

                <Select
                  style={{ marginRight: 16, marginLeft: 16 }}
                  value={selectedVendorId}
                  size="large"
                  defaultValue={""}
                  onChange={(id) => {
                    console.log(id);
                    setSelectedVendorId(id);
                  }}
                >
                  <Select.Option value="">Select Vendor</Select.Option>
                  {vendors.map((vendor, index) => (
                    <Select.Option key={index} value={vendor?.user_ptr_id}>
                      {vendor?.title}
                    </Select.Option>
                  ))}
                </Select>
              </div>

              {selectedVendorId && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {/* <label>Select Event:</label>
                  <Select
                    value={selectedEventId}
                    size="large"
                    defaultValue={""}
                    onChange={(id) => {
                      setSelectedEventId(id);
                    }}
                  >
                    <Select.Option value="">Select Event</Select.Option>
                    {events.map((event, index) => (
                      <Select.Option key={index} value={event?.id}>
                        {event?.name || ""}
                      </Select.Option>
                    ))}
                  </Select> */}
                  <label>Select Start Date</label>
                  <ReactDatePicker
                    className="date-picker"
                    dateFormat={"dd/MM/yyyy"}
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                      setEndDate((st) =>
                        st.getTime() < date.getTime() ? null : new Date()
                      );
                    }}
                  />
                </div>
              )}
              {selectedVendorId && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <label>Select End Date</label>
                  <ReactDatePicker
                    className="date-picker"
                    dateFormat={"dd/MM/yyyy"}
                    selected={endDate}
                    onChange={(date) => {
                      if (
                        date &&
                        startDate &&
                        date.getTime() < startDate.getTime() - 100
                      ) {
                        console.log(
                          "end",
                          date.getTime(),
                          startDate.getTime(),
                          date.getTime() < startDate.getTime() - 100
                        );
                        alert(
                          "Error: End Date should be greater than Start Date"
                        );
                        return;
                      }
                      setEndDate(date);
                    }}
                  />
                </div>
              )}
            </div>
            <div
              style={{
                marginTop: 24,
              }}
            >
              <Button
                style={{ backgroundColor: "rgb(40, 89, 165)" }}
                variant="primary"
                disabled={!selectedVendorId || !startDate || !endDate}
                onClick={handleGetStockUsage}
              >
                Get Stock Usage
              </Button>
            </div>
            {usageData && usageData.length ? (
              <div style={{ marginTop: 40 }}>
                <h3
                  style={{
                    fontSize: "20px",
                    marginBottom: 32,
                    textAlign: "left",
                    fontWeight: 600,
                  }}
                >
                  Stock Usage Data
                </h3>
                <Table dataSource={usageData} bordered>
                  <Column
                    align="left"
                    title="Stock Item Name"
                    dataIndex="stock_name"
                    key="stock_name"
                  />
                  <Column
                    align="left"
                    title="Measurement Unit"
                    dataIndex="unit"
                    key="unit"
                  />
                  <Column
                    align="left"
                    title="Total Qty. Used"
                    dataIndex="total_quantity_used"
                    key="total_quantity_used"
                    sorter={(a, b) =>
                      a.total_quantity_used - b.total_quantity_used
                    }
                    render={(rev) => rev.toFixed(2)}
                  />
                  <Column
                    align="left"
                    title="Total Cost"
                    sorter={(a, b) => a.total_cost - b.total_cost}
                    dataIndex="total_cost"
                    key="total_cost"
                    render={(rev) => rev.toFixed(2)}
                  />
                </Table>
              </div>
            ) : null}
          </>
        )}
      </Container>
    </div>
  );
};

export default Sentry.withErrorBoundary(VendorStockUsageScreen, {
  fallback: <ErrorFallbackComponent />,
});
