import React, { useState, useEffect } from "react";
import { Form } from "semantic-ui-react/";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { Button } from "@mui/material";
import Image from "react-bootstrap/Image";
import { useForm } from "react-hook-form";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import ProfileImage from "@daym3l/react-profile-image";
import Media from "react-bootstrap/Media";
import * as Sentry from "@sentry/react";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import TextField from "@mui/material/TextField";
import {
  getFromLocalStorage,
  deleteFromLocalStorage,
  saveToLocalStorage,
} from "../../store";
import "./style.css";
import Loader from "../../components/UI/Loader";
const AWS_URL = "https://wtt-aws-bucket.s3.amazonaws.com";

function OrganiserEditPrice(props) {
  console.log(props);
  const getImages = (base64Image, fileImage) => {
    set_meal_image(base64Image);
  };
  const [meal_name, set_meal_name] = useState(props.menu.item.name);
  const [meal_name_error, set_meal_name_error] = useState("");
  const [meal_price, set_meal_price] = useState(props.menu.item.price);
  const [meal_price_error, set_meal_price_error] = useState("");
  const [meal_image, set_meal_image] = useState(props.menu.item.image);
  const [meal_Description, set_Description] = useState(
    props.menu.item.description
  );
  const [cuisine_type, set_cuisine_type] = useState(props.menu.item.category);
  const [meal_status, set_status] = useState(props.menu.item.status);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [availableCuisines, setAvailableCuisines] = useState([]);
  const getAvailableCuisines = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.adminData;
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_meal_categories",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.success === true) {
          setAvailableCuisines(response.meal_categories);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };

  const postAddMeal = async (values) => {
    values.preventDefault();
    const url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    setIsLoading(true);
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "operator_edit_price",
          // organiser_id: JSON.parse(getFromLocalStorage("Organiser"))[0].id,
          meal_name: meal_name,
          meal_price: parseFloat(meal_price),
          id: props.menu.item.id,
          vendor_id: props.vendor_id,
          // eoi_id: JSON.parse(getFromLocalStorage("SelectedEOI")).id,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        setIsLoading(false);
        if (dataResponse.success == true) {
          alert("Price Edited successfully!");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        alert("Please Try Again!");
        Sentry.captureException(error);
        console.error(error);
      });
  };
  const handleMealPriceChange = (event) => {
    set_meal_price(event.target.value);
  };
  useEffect(() => {
    getAvailableCuisines();
  }, []);

  return (
    <Container style={{ position: "relative" }}>
      <Loader loading={isLoading} />
      <div className="form-style-5">
        <form onSubmit={postAddMeal}>
          <Row>
            <Col lg={6} md={6} sm={12}>
              <TextField
                disabled
                id="outlined-disabled"
                defaultValue={meal_name}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} sm={12}>
              <label htmlFor="logoFile">Enter the new Price:</label>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <input
                placeholder="Meal Price:"
                type="text"
                id="meal_price"
                name="meal_price"
                value={meal_price}
                onChange={handleMealPriceChange}
              />
            </Col>
          </Row>
          <div style={{ width: "100%", textAlign: "right" }}>
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    </Container>
  );
}
export default Sentry.withErrorBoundary(OrganiserEditPrice, {
  fallback: <ErrorFallbackComponent />,
});
