import React, { useState, useEffect } from "react";
import { Form } from "semantic-ui-react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { Button } from "@mui/material";
import { useForm } from "react-hook-form";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import ImageUploader from "react-images-upload";
import moment from "moment";
import {
  getFromLocalStorage,
  deleteFromLocalStorage,
  saveToLocalStorage,
} from "../../store";
import * as Sentry from "@sentry/react";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import Loader from "../../components/UI/Loader";

function UserFeedbackModal({ onClose, selectedVendorId, selectedEventId }) {
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [jobRole, setJobRole] = useState("");
  const jobRoles = [
    "Truck Manager",
    "Event Coordinator",
    "Owner",
    "Operations",
    "IT",
  ];
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const postUsereedback = async (event) => {
    event.preventDefault();
    const url = config.BASE_URI + apiPaths.operatorData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    setLoading(true);
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "add_user_feedback",
          event_id: selectedEventId,
          feedback: feedback,
          operator_id: JSON.parse(getFromLocalStorage("Operator"))[0].id,
          vendor_id: selectedVendorId,
          job_role: jobRole,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        setLoading(false);
        if (dataResponse.success) {
          alert("User Feedback was added successfully");
          onClose();
        } else {
          alert("User Feedback was unsuccessful");
        }
      })
      .catch((error) => {
        setLoading(false);
        Sentry.captureException(error);
        console.error(error);
        alert("Adding User Feedback was unsuccessful. Please try again later.");
      });
  };

  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value);
  };

  const handleJobRoleChange = (event) => {
    setJobRole(event.target.value);
  };

  return (
    <Container style={{ position: "relative" }}>
      <Loader loading={loading} />
      <div className="form-style-5">
        <form onSubmit={postUsereedback}>
          <Row style={{ paddingBottom: 10 }}>
            <Col lg={12} md={12} sm={12}>
              <select defaultValue="" onChange={handleJobRoleChange}>
                <option value="" disabled>
                  Select Job Role
                </option>
                {jobRoles &&
                  jobRoles.length > 0 &&
                  jobRoles.map((role) => (
                    <option key={role} value={role}>
                      {role}
                    </option>
                  ))}
              </select>
            </Col>
          </Row>
          <Row style={{ paddingBottom: 10, marginTop: 10 }}>
            <Col xs={12} md={12} lg={12}>
              <textarea
                placeholder="Feedback:"
                type="text"
                rows="4"
                cols="75"
                value={feedback}
                onChange={handleFeedbackChange}
              ></textarea>
            </Col>
          </Row>

          <div style={{ width: "100%", textAlign: "right" }}>
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    </Container>
  );
}

export default Sentry.withErrorBoundary(UserFeedbackModal, {
  fallback: <ErrorFallbackComponent />,
});
