import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import Divider from "@mui/material/Divider";
import { AWS_URL } from "../../assets/Constants";
import DatePicker from "react-datepicker";
import moment from "moment";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import "./EditableForm.css";
import Loader from "../../components/UI/Loader";

const EditableForm = ({ selectedBrandMeals }) => {
  const [loading, setLoading] = useState(false);
  const [showFileInput, setShowFileInput] = useState({
    logo: false,
    logo_map: false,
    image: false,
    site_layout: false,
    electricial_certificate: false,
    fire_safety_certificate: false,
    working_with_childrens_check: false,
    work_cover_insurance: false,
    motor_vehicle_insurance: false,
    liablity_certificate: false,
    gas_certificate: false,
    other_uploads: false,
    food_refistration_docs: false,
    food_safety_certificate: false,
    truck_images: false,
    staff_uniform_pictures: false,
    kisok_marketing_promo_images: false,
  });

  const { register, handleSubmit } = useForm({
    defaultValues: selectedBrandMeals,
  });

  const [
    liablity_certificate_expiry_date,
    set_liability_certificate_expiry_date,
  ] = useState(
    selectedBrandMeals.liablity_certificate_expiry_date
      ? new Date(selectedBrandMeals.liablity_certificate_expiry_date)
      : new Date()
  );

  const [
    gas_certificate_expiry_date,
    set_gas_certificate_expiry_date,
  ] = useState(
    selectedBrandMeals.gas_certificate_expiry_date
      ? new Date(selectedBrandMeals.gas_certificate_expiry_date)
      : new Date()
  );

  const [motor_vehicle_expiry_date, set_motor_vehicle_expiry_date] = useState(
    selectedBrandMeals.motor_vehicle_expiry_date
      ? new Date(selectedBrandMeals.motor_vehicle_expiry_date)
      : new Date()
  );

  const [work_cover_expiry_date, set_work_cover_expiry_date] = useState(
    selectedBrandMeals.work_cover_expiry_date
      ? new Date(selectedBrandMeals.work_cover_expiry_date)
      : new Date()
  );

  const onSubmit = async (data) => {
    data["liablity_certificate_expiry_date"] = moment(
      liablity_certificate_expiry_date
    ).format("DD MMM YYYY");
    data["gas_certificate_expiry_date"] = moment(
      gas_certificate_expiry_date
    ).format("DD MMM YYYY");
    data["work_cover_expiry_date"] = moment(work_cover_expiry_date).format(
      "DD MMM YYYY"
    );
    data["motor_vehicle_expiry_date"] = moment(
      motor_vehicle_expiry_date
    ).format("DD MMM YYYY");

    const formData = new FormData();

    function appendFileField(fieldName) {
      if (data[fieldName] instanceof FileList && data[fieldName].length > 0) {
        for (let i = 0; i < data[fieldName].length; i++) {
          const file = data[fieldName][i];
          const modifiedFile = new File(
            [file],
            `modified_${fieldName}_${i}_${selectedBrandMeals.id}`,
            {
              type: file.type,
            }
          );
          formData.append(fieldName, modifiedFile);
        }
      }
    }

    formData.append("id", selectedBrandMeals.id);
    formData.append(
      "liability_certificate_expiry_date",
      data.liablity_certificate_expiry_date
    );
    formData.append(
      "gas_certificate_expiry_date",
      data.gas_certificate_expiry_date
    );
    formData.append("work_cover_expiry_date", data.work_cover_expiry_date);
    formData.append(
      "motor_vehicle_expiry_date",
      data.motor_vehicle_expiry_date
    );

    appendFileField("logo");
    appendFileField("logo_map");
    appendFileField("image");
    appendFileField("site_layout");
    appendFileField("electricial_certificate");
    appendFileField("liablity_certificate");
    appendFileField("gas_certificate");
    appendFileField("other_uploads");
    appendFileField("food_refistration_docs");
    appendFileField("fire_safety_certificate");
    appendFileField("working_with_childrens_check");
    appendFileField("work_cover_insurance");
    appendFileField("motor_vehicle_insurance");
    appendFileField("food_safety_certificate");
    appendFileField("kisok_marketing_promo_images");
    // Append new fields
    appendFileField("truck_images");
    appendFileField("staff_uniform_pictures");
    setLoading(true);

    try {
      const response = await axios.post(
        config.BASE_URI + apiPaths.uploadOperatorDocuments,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setLoading(false);
      if (response.data.success === true) {
        alert("Vendor Documents have been added successfully!");
        window.location.reload();
      } else {
        alert("Vendor Details could not be added. Please try again.");
        window.location.reload();
      }
    } catch (error) {
      setLoading(false);
      alert("Vendor Details could not be added. Please try again.");
      window.location.reload();
    }
  };

  return (
    <form
      className="form-container"
      onSubmit={handleSubmit(onSubmit)}
      encType="multipart/form-data"
      style={{ position: "relative" }}
    >
      <Loader loading={loading} />
      <div>
        Note: Please upload all documents marked as{" "}
        <span style={{ color: "red" }}>*</span> before submitting your EOI.
        Event Organisers Won't be able to accept your applications if you
        haven't added the required documents.
      </div>
      <Divider />

      <div className="form-field">
        <label htmlFor="logo" className="form-label">
          Logo:<span style={{ color: "red" }}>*</span>
        </label>
        {selectedBrandMeals.logo ? (
          <>
            <a
              className="form-link"
              href={AWS_URL + "/" + selectedBrandMeals.logo}
              target="_blank"
            >
              View/Download
            </a>
            {!showFileInput?.logo ? (
              <div
                onClick={() => {
                  setShowFileInput((st) => ({ ...st, logo: true }));
                }}
                className="form-link"
              >
                Change Uploaded File
              </div>
            ) : null}
          </>
        ) : (
          <div></div>
        )}
        {!selectedBrandMeals.logo ||
        (selectedBrandMeals.logo && showFileInput.logo) ? (
          <input
            className="form-input"
            type="file"
            id="logo"
            name="logo"
            {...register("logo")}
            style={{
              backgroundColor: selectedBrandMeals.logo ? "#f3f1ef" : "#ff8d70",
            }}
          />
        ) : null}
      </div>

      <div className="form-field">
        <label htmlFor="logo_map" className="form-label">
          Banner Image (Hero shot of your top selling food items for website
          ordering):
        </label>
        {selectedBrandMeals.logo_map ? (
          <>
            <a
              className="form-link"
              href={AWS_URL + "/" + selectedBrandMeals.logo_map}
              target="_blank"
            >
              View/Download
            </a>
            {!showFileInput?.logo_map ? (
              <div
                onClick={() => {
                  setShowFileInput((st) => ({ ...st, logo_map: true }));
                }}
                className="form-link"
              >
                Change Uploaded File
              </div>
            ) : null}
          </>
        ) : (
          <div></div>
        )}
        {!selectedBrandMeals.logo_map ||
        (selectedBrandMeals.logo_map && showFileInput.logo_map) ? (
          <input
            className="form-input"
            type="file"
            id="logo_map"
            name="logo_map"
            {...register("logo_map")}
            style={{
              backgroundColor: selectedBrandMeals.logo_map
                ? "#f3f1ef"
                : "#ff8d70",
            }}
          />
        ) : null}
      </div>

      <div className="form-field">
        <label htmlFor="image" className="form-label">
          Image (Photo of your outlet):
        </label>
        {selectedBrandMeals.image ? (
          <>
            <a
              className="form-link"
              href={AWS_URL + "/" + selectedBrandMeals.image}
              target="_blank"
            >
              View/Download
            </a>
            {!showFileInput?.image ? (
              <div
                onClick={() => {
                  setShowFileInput((st) => ({ ...st, image: true }));
                }}
                className="form-link"
              >
                Change Uploaded File
              </div>
            ) : null}
          </>
        ) : (
          <div></div>
        )}
        {!selectedBrandMeals.image ||
        (selectedBrandMeals.image && showFileInput.image) ? (
          <input
            className="form-input"
            type="file"
            id="image"
            name="image"
            {...register("image")}
            style={{
              backgroundColor: selectedBrandMeals.image ? "#f3f1ef" : "#ff8d70",
            }}
          />
        ) : null}
      </div>

      <div className="form-field">
        <label htmlFor="site_layout" className="form-label">
          Site Layout:
        </label>
        {selectedBrandMeals.site_layout ? (
          <>
            <a
              className="form-link"
              href={AWS_URL + "/" + selectedBrandMeals.site_layout}
              target="_blank"
            >
              View/Download
            </a>
            {!showFileInput?.site_layout ? (
              <div
                onClick={() => {
                  setShowFileInput((st) => ({ ...st, site_layout: true }));
                }}
                className="form-link"
              >
                Change Uploaded File
              </div>
            ) : null}
          </>
        ) : (
          <div></div>
        )}
        {!selectedBrandMeals.site_layout ||
        (selectedBrandMeals.site_layout && showFileInput.site_layout) ? (
          <input
            className="form-input"
            type="file"
            id="site_layout"
            name="site_layout"
            {...register("site_layout")}
            style={{
              backgroundColor: selectedBrandMeals.site_layout
                ? "#f3f1ef"
                : "#ff8d70",
            }}
          />
        ) : null}
      </div>

      <div className="form-field">
        <label htmlFor="electricial_certificate" className="form-label">
          Electricial Certificate:
        </label>
        {selectedBrandMeals.electricial_certificate ? (
          <>
            <a
              className="form-link"
              href={AWS_URL + "/" + selectedBrandMeals.electricial_certificate}
              target="_blank"
            >
              View/Download
            </a>
            {!showFileInput?.electricial_certificate ? (
              <div
                onClick={() => {
                  setShowFileInput((st) => ({
                    ...st,
                    electricial_certificate: true,
                  }));
                }}
                className="form-link"
              >
                Change Uploaded File
              </div>
            ) : null}
          </>
        ) : (
          <div></div>
        )}
        {!selectedBrandMeals.electricial_certificate ||
        (selectedBrandMeals.electricial_certificate &&
          showFileInput.electricial_certificate) ? (
          <input
            className="form-input"
            type="file"
            id="electricial_certificate"
            name="electricial_certificate"
            {...register("electricial_certificate")}
            style={{
              backgroundColor: selectedBrandMeals.electricial_certificate
                ? "#f3f1ef"
                : "#ff8d70",
            }}
          />
        ) : null}
      </div>

      <div className="form-field">
        <label htmlFor="fire_safety_certificate" className="form-label">
          Fire Safety Certificate:
        </label>
        {selectedBrandMeals.fire_safety_certificate ? (
          <>
            <a
              className="form-link"
              href={AWS_URL + "/" + selectedBrandMeals.fire_safety_certificate}
              target="_blank"
            >
              View/Download
            </a>
            {!showFileInput?.fire_safety_certificate ? (
              <div
                onClick={() => {
                  setShowFileInput((st) => ({
                    ...st,
                    fire_safety_certificate: true,
                  }));
                }}
                className="form-link"
              >
                Change Uploaded File
              </div>
            ) : null}
          </>
        ) : (
          <div></div>
        )}
        {!selectedBrandMeals.fire_safety_certificate ||
        (selectedBrandMeals.fire_safety_certificate &&
          showFileInput.fire_safety_certificate) ? (
          <input
            className="form-input"
            type="file"
            id="fire_safety_certificate"
            name="fire_safety_certificate"
            {...register("fire_safety_certificate")}
            style={{
              backgroundColor: selectedBrandMeals.fire_safety_certificate
                ? "#f3f1ef"
                : "#ff8d70",
            }}
          />
        ) : null}
      </div>

      <div className="form-field">
        <label htmlFor="working_with_childrens_check" className="form-label">
          Working With Childrens Check:
        </label>
        {selectedBrandMeals.working_with_childrens_check ? (
          <>
            <a
              className="form-link"
              href={
                AWS_URL + "/" + selectedBrandMeals.working_with_childrens_check
              }
              target="_blank"
            >
              View/Download
            </a>
            {!showFileInput?.working_with_childrens_check ? (
              <div
                onClick={() => {
                  setShowFileInput((st) => ({
                    ...st,
                    working_with_childrens_check: true,
                  }));
                }}
                className="form-link"
              >
                Change Uploaded File
              </div>
            ) : null}
          </>
        ) : (
          <div></div>
        )}
        {!selectedBrandMeals.working_with_childrens_check ||
        (selectedBrandMeals.working_with_childrens_check &&
          showFileInput.working_with_childrens_check) ? (
          <input
            className="form-input"
            type="file"
            id="working_with_childrens_check"
            name="working_with_childrens_check"
            {...register("working_with_childrens_check")}
            style={{
              backgroundColor: selectedBrandMeals.working_with_childrens_check
                ? "#f3f1ef"
                : "#ff8d70",
            }}
          />
        ) : null}
      </div>

      <div className="form-field">
        <label htmlFor="work_cover_insurance" className="form-label">
          Work Cover Insurance:
        </label>
        {selectedBrandMeals.work_cover_insurance ? (
          <>
            <a
              className="form-link"
              href={AWS_URL + "/" + selectedBrandMeals.work_cover_insurance}
              target="_blank"
            >
              View/Download
            </a>
            {!showFileInput?.work_cover_insurance ? (
              <div
                onClick={() => {
                  setShowFileInput((st) => ({
                    ...st,
                    work_cover_insurance: true,
                  }));
                }}
                className="form-link"
              >
                Change Uploaded File
              </div>
            ) : null}
          </>
        ) : (
          <div></div>
        )}
        {!selectedBrandMeals.work_cover_insurance ||
        (selectedBrandMeals.work_cover_insurance &&
          showFileInput.work_cover_insurance) ? (
          <input
            className="form-input"
            type="file"
            id="work_cover_insurance"
            name="work_cover_insurance"
            {...register("work_cover_insurance")}
            style={{
              backgroundColor: selectedBrandMeals.work_cover_insurance
                ? "#f3f1ef"
                : "#ff8d70",
            }}
          />
        ) : null}
      </div>

      <div className="form-field">
        <label htmlFor="motor_vehicle_insurance" className="form-label">
          Motor Vehicle Insurance:
        </label>
        {selectedBrandMeals.motor_vehicle_insurance ? (
          <>
            <a
              className="form-link"
              href={AWS_URL + "/" + selectedBrandMeals.motor_vehicle_insurance}
              target="_blank"
            >
              View/Download
            </a>
            {!showFileInput?.motor_vehicle_insurance ? (
              <div
                onClick={() => {
                  setShowFileInput((st) => ({
                    ...st,
                    motor_vehicle_insurance: true,
                  }));
                }}
                className="form-link"
              >
                Change Uploaded File
              </div>
            ) : null}
          </>
        ) : (
          <div></div>
        )}
        {!selectedBrandMeals.motor_vehicle_insurance ||
        (selectedBrandMeals.motor_vehicle_insurance &&
          showFileInput.motor_vehicle_insurance) ? (
          <input
            className="form-input"
            type="file"
            id="motor_vehicle_insurance"
            name="motor_vehicle_insurance"
            {...register("motor_vehicle_insurance")}
            style={{
              backgroundColor: selectedBrandMeals.motor_vehicle_insurance
                ? "#f3f1ef"
                : "#ff8d70",
            }}
          />
        ) : null}
      </div>

      <div className="form-field">
        <label htmlFor="liablity_certificate" className="form-label">
          Liability Certificate:<span style={{ color: "red" }}>*</span>
        </label>
        {selectedBrandMeals.liablity_certificate ? (
          <>
            <a
              className="form-link"
              href={AWS_URL + "/" + selectedBrandMeals.liablity_certificate}
              target="_blank"
            >
              View/Download
            </a>
            {!showFileInput?.liablity_certificate ? (
              <div
                onClick={() => {
                  setShowFileInput((st) => ({
                    ...st,
                    liablity_certificate: true,
                  }));
                }}
                className="form-link"
              >
                Change Uploaded File
              </div>
            ) : null}
          </>
        ) : (
          <div></div>
        )}
        {!selectedBrandMeals.liablity_certificate ||
        (selectedBrandMeals.liablity_certificate &&
          showFileInput.liablity_certificate) ? (
          <input
            className="form-input"
            type="file"
            id="liablity_certificate"
            name="liablity_certificate"
            {...register("liablity_certificate")}
            style={{
              backgroundColor: selectedBrandMeals.liablity_certificate
                ? "#f3f1ef"
                : "#ff8d70",
            }}
          />
        ) : null}
      </div>

      <div className="form-field">
        <label htmlFor="gas_certificate" className="form-label">
          Gas Certificate:
        </label>
        {selectedBrandMeals.gas_certificate ? (
          <>
            <a
              className="form-link"
              href={AWS_URL + "/" + selectedBrandMeals.gas_certificate}
              target="_blank"
            >
              View/Download
            </a>
            {!showFileInput?.gas_certificate ? (
              <div
                onClick={() => {
                  setShowFileInput((st) => ({ ...st, gas_certificate: true }));
                }}
                className="form-link"
              >
                Change Uploaded File
              </div>
            ) : null}
          </>
        ) : (
          <div></div>
        )}
        {!selectedBrandMeals.gas_certificate ||
        (selectedBrandMeals.gas_certificate &&
          showFileInput.gas_certificate) ? (
          <input
            className="form-input"
            type="file"
            id="gas_certificate"
            name="gas_certificate"
            {...register("gas_certificate")}
            style={{
              backgroundColor: selectedBrandMeals.gas_certificate
                ? "#f3f1ef"
                : "#ff8d70",
            }}
          />
        ) : null}
      </div>

      <div className="form-field">
        <label htmlFor="other_uploads" className="form-label">
          Other Uploads:
        </label>
        {selectedBrandMeals.other_uploads ? (
          <>
            <a
              className="form-link"
              href={AWS_URL + "/" + selectedBrandMeals.other_uploads}
              target="_blank"
              rel="noopener noreferrer"
            >
              View/Download
            </a>
            {!showFileInput?.other_uploads ? (
              <div
                onClick={() => {
                  setShowFileInput((st) => ({ ...st, other_uploads: true }));
                }}
                className="form-link"
              >
                Change Uploaded File
              </div>
            ) : null}
          </>
        ) : (
          <div></div>
        )}
        {!selectedBrandMeals.other_uploads ||
        (selectedBrandMeals.other_uploads && showFileInput.other_uploads) ? (
          <input
            className="form-input"
            type="file"
            id="other_uploads"
            name="other_uploads"
            {...register("other_uploads")}
            style={{
              backgroundColor: selectedBrandMeals.other_uploads
                ? "#f3f1ef"
                : "#ff8d70",
            }}
          />
        ) : null}
      </div>

      <div className="form-field">
        <label htmlFor="food_refistration_docs" className="form-label">
          Certificate of registration of food premises:
          <span style={{ color: "red" }}>*</span>
        </label>
        {selectedBrandMeals.food_refistration_docs ? (
          <>
            <a
              className="form-link"
              href={AWS_URL + "/" + selectedBrandMeals.food_refistration_docs}
              target="_blank"
            >
              View/Download
            </a>
            {!showFileInput?.food_refistration_docs ? (
              <div
                onClick={() => {
                  setShowFileInput((st) => ({
                    ...st,
                    food_refistration_docs: true,
                  }));
                }}
                className="form-link"
              >
                Change Uploaded File
              </div>
            ) : null}
          </>
        ) : (
          <div></div>
        )}
        {!selectedBrandMeals.food_refistration_docs ||
        (selectedBrandMeals.food_refistration_docs &&
          showFileInput.food_refistration_docs) ? (
          <input
            className="form-input"
            type="file"
            id="food_refistration_docs"
            name="food_refistration_docs"
            {...register("food_refistration_docs")}
            style={{
              backgroundColor: selectedBrandMeals.food_refistration_docs
                ? "#f3f1ef"
                : "#ff8d70",
            }}
          />
        ) : null}
      </div>

      <div className="form-field">
        <label htmlFor="food_safety_certificate" className="form-label">
          Food Safety Certificate:<span style={{ color: "red" }}>*</span>
        </label>
        {selectedBrandMeals.food_safety_certificate ? (
          <>
            <a
              className="form-link"
              href={AWS_URL + "/" + selectedBrandMeals.food_safety_certificate}
              target="_blank"
            >
              View/Download
            </a>
            {!showFileInput?.food_safety_certificate ? (
              <div
                onClick={() => {
                  setShowFileInput((st) => ({
                    ...st,
                    food_safety_certificate: true,
                  }));
                }}
                className="form-link"
              >
                Change Uploaded File
              </div>
            ) : null}
          </>
        ) : (
          <div></div>
        )}
        {!selectedBrandMeals.food_safety_certificate ||
        (selectedBrandMeals.food_safety_certificate &&
          showFileInput.food_safety_certificate) ? (
          <input
            className="form-input"
            type="file"
            id="food_safety_certificate"
            name="food_safety_certificate"
            {...register("food_safety_certificate")}
            style={{
              backgroundColor: selectedBrandMeals.food_safety_certificate
                ? "#f3f1ef"
                : "#ff8d70",
            }}
          />
        ) : null}
      </div>

      {/* New fields for Truck Images and Staff Uniform Pictures */}
      <div className="form-field">
        <label htmlFor="truck_images" className="form-label">
          Truck Images (You can upload multiple images):
        </label>
        <input
          className="form-input"
          type="file"
          id="truck_images"
          name="truck_images"
          multiple
          {...register("truck_images")}
          style={{
            backgroundColor: selectedBrandMeals.truck_images
              ? "#f3f1ef"
              : "#ff8d70",
          }}
        />
      </div>

      <div className="form-field">
        <label htmlFor="staff_uniform_pictures" className="form-label">
          Staff Uniform Pictures (You can upload multiple images):
        </label>
        <input
          className="form-input"
          type="file"
          id="staff_uniform_pictures"
          name="staff_uniform_pictures"
          multiple
          {...register("staff_uniform_pictures")}
          style={{
            backgroundColor: selectedBrandMeals.staff_uniform_pictures
              ? "#f3f1ef"
              : "#ff8d70",
          }}
        />
      </div>

      {/* Kiosk Marketing DOcuments  */}

      <div className="form-field">
        <label htmlFor="kisok_marketing_promo_images" className="form-label">
          Kiosk Marketing Promo Images (these images will be displayed as a
          slide show you can always update them) Promotional Content Area:
          Width: 1080 pixels & Height: 1536 pixels, please design accordingly
        </label>
        <input
          className="form-input"
          type="file"
          id="kisok_marketing_promo_images"
          name="kisok_marketing_promo_images"
          multiple
          {...register("kisok_marketing_promo_images")}
          style={{
            backgroundColor: selectedBrandMeals.kisok_marketing_promo_images
              ? "#f3f1ef"
              : "#ff8d70",
          }}
        />
      </div>

      {/* Date Pickers for Expiry Dates */}
      <div className="form-field">
        <label htmlFor="motor_vehicle_expiry_date" className="form-label">
          Motor Insurance Expiry Date:
        </label>
        <div className="date-picker-container">
          <DatePicker
            dateFormat={"dd/MM/yyyy"}
            selected={motor_vehicle_expiry_date}
            onChange={(date) => set_motor_vehicle_expiry_date(date)}
          />
        </div>
      </div>

      <div className="form-field">
        <label htmlFor="work_cover_expiry_date" className="form-label">
          Work Cover Insurance Expiry Date:
        </label>
        <div className="date-picker-container">
          <DatePicker
            dateFormat={"dd/MM/yyyy"}
            selected={work_cover_expiry_date}
            onChange={(date) => set_work_cover_expiry_date(date)}
          />
        </div>
      </div>

      <div className="form-field">
        <label
          htmlFor="liablity_certificate_expiry_date"
          className="form-label"
        >
          Liability Certificate Expiry Date:
        </label>
        <div className="date-picker-container">
          <DatePicker
            dateFormat={"dd/MM/yyyy"}
            selected={liablity_certificate_expiry_date}
            onChange={(date) => set_liability_certificate_expiry_date(date)}
          />
        </div>
      </div>

      <div className="form-field">
        <label htmlFor="gas_certificate_expiry_date" className="form-label">
          Gas Certificate Expiry Date:
        </label>
        <div className="date-picker-container">
          <DatePicker
            dateFormat={"dd/MM/yyyy"}
            selected={gas_certificate_expiry_date}
            onChange={(date) => set_gas_certificate_expiry_date(date)}
          />
        </div>
      </div>

      <button className="form-button" type="submit">
        Submit
      </button>
    </form>
  );
};

export default EditableForm;
