import React, { Component } from "react";
import { connect } from "react-redux";
import "./style.css";
import "date-fns";
import AdminNavBar from "../../components/UI/AdminNavBar/index";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import { Bar, Pie } from "react-chartjs-2";
import DatePicker from "react-datepicker";
import moment from "moment";
import config from "../../services/apiConfig";
import "react-datepicker/dist/react-datepicker.css";
import { apiPaths } from "../../services/apiPath";
import {
  store,
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import Modal from "react-bootstrap/Modal";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import * as Sentry from "@sentry/react";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import Loader from "../../components/UI/Loader";

class StockAdminAddStockBundleScreen extends Component {
  state = {
    loading: false,
    isSuperAdminLoggedIn: getFromLocalStorage("isSuperAdminLoggedIn"),
    superAdminEmail:
      getFromLocalStorage("superAdminEmail") &&
      getFromLocalStorage("superAdminEmail") != ""
        ? getFromLocalStorage("superAdminEmail")
        : "",
    superAdminPassword:
      getFromLocalStorage("superAdminPassword") &&
      getFromLocalStorage("superAdminPassword") != ""
        ? getFromLocalStorage("superAdminPassword")
        : "",
    stockAdminLoggedIn: getFromLocalStorage("stockAdminLoggedIn"),
    isOperatorLoggedIn: false,
    qty: 0,
    selectedStockListId: 0,
    stockList: [],
    meal_name: "",
    meal_id: 0,
    adminKitchacoLocation:
      getFromLocalStorage("adminKitchacoLocation") != "undefined" &&
      getFromLocalStorage("adminKitchacoLocation") != "" &&
      getFromLocalStorage("adminKitchacoLocation") != null
        ? getFromLocalStorage("adminKitchacoLocation")
        : 0,
  };

  componentDidMount() {
    const { isSuperAdminLoggedIn } = this.state;
    if (isSuperAdminLoggedIn == "true") {
      var url_params = window.location.href.split("?")[1];
      var meal_name = url_params.split("&meal_name=")[1];
      var meal_id = url_params.split("meal_id=")[1];
      meal_id = meal_id.split("&meal_name=")[0];
      this.setState({
        meal_name: decodeURIComponent(meal_name),
        meal_id: meal_id,
      });
      this.fetchStockListFromServer();
    } else {
      window.location.href = "/admin";
    }
  }

  fetchStockListFromServer = () => {
    const {
      adminKitchacoLocation,
      superAdminEmail,
      superAdminPassword,
    } = this.state;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      email: superAdminEmail,
      password: superAdminPassword,
      kitchaco: adminKitchacoLocation,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(config.BASE_URI + apiPaths.stockList, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          this.setState({ stockList: result.data });
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        alert("Oops, Something went wrong! Please try again later");
      });
  };

  addStockBundleOnServer = (stock_bundle) => {
    const { meal_id, selectedStockListId, qty } = this.state;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      meal_id: meal_id,
      stock_list_id: selectedStockListId,
      qty: qty,
      query_type: "add_stock_bundle",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    this.setState({ loading: true });
    fetch(config.BASE_URI + apiPaths.admin.adminData, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        this.setState({ loading: false });
        if (result.success) {
          alert("Added successfully!");
          window.location.href = "/stock-admin-bundle";
        } else {
          alert(result.error);
        }
      });
  };

  render() {
    const { isSuperAdminLoggedIn } = this.state;
    return (
      <>{isSuperAdminLoggedIn == "true" ? this.renderMainComponent() : null}</>
    );
  }

  renderMainComponent() {
    const {
      isSuperAdminLoggedIn,
      qty,
      stockList,
      selectedStockListId,
      meal_name,
    } = this.state;
    return (
      <div>
        <AdminNavBar isSuperAdminLoggedIn={isSuperAdminLoggedIn}></AdminNavBar>
        <Container
          style={{
            borderWidth: 1,
            borderColor: "grey",
            borderStyle: "solid",
            borderRadius: 5,
            padding: 10,
            position: "relative",
          }}
        >
          <Loader loading={this.state.loading} />
          <Row style={{ marginTop: 10 }}>
            <Col xs={12} md={12} lg={12}>
              <h5>Add Stock Bundle</h5>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} lg={12}>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Meal</Form.Label>
                  <Form.Control type="text" readOnly value={meal_name} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Qty</Form.Label>
                  <Form.Control
                    type="text"
                    value={qty}
                    onChange={(e) => {
                      this.setState({ qty: e.target.value });
                    }}
                  />
                </Form.Group>
                <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                  <Form.Label>Stock Item</Form.Label>
                  {stockList && stockList.length > 0 ? (
                    <Form.Control
                      as="select"
                      size="sm"
                      custom
                      onChange={(e) => {
                        this.setState({ selectedStockListId: e.target.value });
                      }}
                      defaultValue={selectedStockListId}
                    >
                      <option value={0}>-</option>
                      {stockList.map((location, k) => {
                        return (
                          <option key={k} value={stockList[k].id}>
                            {stockList[k].name +
                              " " +
                              stockList[k].qty_type__qty_type}
                          </option>
                        );
                      })}
                    </Form.Control>
                  ) : null}
                </Form.Group>
                <Button
                  variant="primary"
                  onClick={() => {
                    this.addStockBundleOnServer();
                  }}
                  type="button"
                >
                  Submit
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
    paymentStatus: state.appReducer.paymentStatus,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(StockAdminAddStockBundleScreen, {
    fallback: <ErrorFallbackComponent />,
  })
);
