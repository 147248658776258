import React, { useState } from "react";
import Container from "@mui/material/Container";
import { useFormik } from "formik";
import Button from "@mui/material/Button";
import SupplierInformation from "./SupplierInformation";
import SupplierForm from "./SupplierForm";
import config from "../../../services/apiConfig";
import { apiPaths } from "../../../services/apiPath";
import { useDispatch } from "react-redux";
import { createEOIEvent } from "../../../actions/EventOrganiser/action";
import {
  store,
  saveToLocalStorage,
  deleteFromLocalStorage,
  getFromLocalStorage,
} from "../../../store";
import "./style.css";
import Loader from "../Loader";

const SupplierPage = ({ handleClose, isOrganiser, nextStep }) => {
  const [openSuccess, setOpenSuccess] = useState(true);
  const [openFail, setOpenFail] = useState(true);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const url = config.BASE_URI + apiPaths.supplierApplication;
  const formik = useFormik({
    initialValues: {
      companyName:
        getFromLocalStorage("companyName") !== ""
          ? getFromLocalStorage("companyName")
          : "",
      companyLocation:
        getFromLocalStorage("companyLocation") !== ""
          ? getFromLocalStorage("companyLocation")
          : "",
      contactPersonName:
        getFromLocalStorage("contactPersonName") !== ""
          ? getFromLocalStorage("contactPersonName")
          : "",
      emailEOI:
        getFromLocalStorage("emailEOI") !== ""
          ? getFromLocalStorage("emailEOI")
          : "",

      warehouseAddress:
        getFromLocalStorage("warehouseAddress") !== ""
          ? getFromLocalStorage("warehouseAddress")
          : "",
      companyDescription:
        getFromLocalStorage("companyDescription") !== ""
          ? getFromLocalStorage("")
          : "",
      service: "",

      eventName:
        getFromLocalStorage("eventName") !== ""
          ? getFromLocalStorage("eventName")
          : "",
      eventLogo: [],
      emailEOI:
        getFromLocalStorage("emailEOI") !== ""
          ? getFromLocalStorage("emailEOI")
          : "",
      numberOfEvents:
        getFromLocalStorage("numberOfEvents") !== ""
          ? getFromLocalStorage("numberOfEvents")
          : "",
      numberOfVendors:
        getFromLocalStorage("numberOfVendors") !== ""
          ? getFromLocalStorage("numberOfVendors")
          : "",
      abnNumber:
        getFromLocalStorage("abnNumber") !== ""
          ? getFromLocalStorage("abnNumbere")
          : "",
      eventContract: [],
      siteArea:
        getFromLocalStorage("siteArea") !== ""
          ? getFromLocalStorage("siteArea")
          : "",
      eventLocation: "",
      contactNumber:
        getFromLocalStorage("contactNumber") !== ""
          ? getFromLocalStorage("contactNumber")
          : "",
      eventFile: [],
    },
    initialTouched: {
      eventNumberOf10Amp: false,
      eventNumberOf15Amp: false,
      eventNumberOf20Amp: false,
      eventNumberOf32Amp: false,
      waterAccess: false,
      bumpInDateTime: false,
      bumpOutDateTime: false,
      eventStartDateTime: false,
      eventEndDateTime: false,
      firstName: false,
      lastName: false,
      service: false,

      emailEOI: false,
      eventName: false,
      contactNumber: false,
      siteArea: false,
      eventLocation: false,
      abnNumber: false,
      eventFile: false,
      eventLogo: false,
      eventContract: false,
      numberOfVendors: false,
      numberOfEvents: false,
    },

    // validate: (values) => {
    //   const errors = {};
    //   if (!isOrganiser) {
    //     if (!values.siteArea || values.siteArea.length === 0) {
    //       errors.siteArea = "*Site Area is Required";
    //     }
    //     if (!values.abnNumber || values.abnNumber.length === 0) {
    //       errors.abnNumber = "* ABN Bumber is Required";
    //     }
    //     if (values.abnNumber.length > 11) {
    //       errors.abnNumber = " *Please enter correct ABN Number";
    //     }
    //     if (!values.contactNumber || values.contactNumber.length === 0) {
    //       errors.contactNumber = "*Last Name is Required";
    //     }

    //     if (!values.eventLocation || values.eventLocation.length === 0) {
    //       errors.eventLocation = "*Company Location is Required";
    //     }

    //     if (!values.eventName || values.eventName.length === 0) {
    //       errors.eventName = "*Event Name is Required";
    //     }
    //     if (!values.email || values.email.length === 0) {
    //       errors.email = "*Email ID is Required";
    //     }
    //     if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    //       errors.email = "*Please enter valid email address";
    //     }
    //     if (!values.numberOfEvents || values.numberOfEvents.length === 0) {
    //       errors.numberOfEvents = "*Number of events is Required";
    //     }

    //     if (values.numberOfEvents < 0) {
    //       errors.numberOfEvents = "*Event number cannot be negative ";
    //     }

    //     if (!values.numberOfVendors || values.numberOfVendors.length === 0) {
    //       errors.numberOfVendors = "*Number of Vendors is Required";
    //     }

    //     if (values.numberOfVendors < 0) {
    //       errors.numberOfVendors = "*Vendor number cannot be negative ";
    //     }

    //     if (!values.eventFile || values.eventFile.length === 0) {
    //       errors.eventFile = "*Organisation terms and conditions is Required";
    //     }

    //     if (!values.eventLogo || values.eventLogo.length === 0) {
    //       errors.eventLogo = "*Organisation logo is Required";
    //     }
    //     if (!values.eventContract || values.eventContract.length === 0) {
    //       errors.eventContract = "*Organisation contract orm is Required";
    //     }
    //     return errors;
    //   } else {
    //     if (!values.waterAccess || values.waterAccess.length === 0) {
    //       errors.waterAccess = " * Watter access requirement is required";
    //     }
    //     if (!values.siteArea || values.siteArea.length === 0) {
    //       errors.siteArea = " * Site Area is required";
    //     }

    //     if (!values.firstName || values.firstName.length === 0) {
    //       errors.firstName = " * First Name is required";
    //     }
    //     if (!values.lastName || values.lastName.length === 0) {
    //       errors.lastName = " * last Name is required";
    //     }

    //     if (!values.service || values.service.length === 0) {
    //       errors.service = " * Service is required";
    //     }

    //     if (
    //       !values.eventStartDateTime ||
    //       values.eventStartDateTime.length === 0
    //     ) {
    //       errors.eventStartDateTime = " *Event Time is required";
    //     }

    //     if (!values.eventEndDateTime || values.eventEndDateTime.length === 0) {
    //       errors.eventEndDateTime = " *Event Time is required";
    //     }

    //     if (!values.bumpInDateTime || values.bumpInDateTime.length === 0) {
    //       errors.bumpInDateTime = " *Bump in date time is required";
    //     }
    //     if (!values.bumpOutDateTime || values.bumpOutDateTime.length === 0) {
    //       errors.bumpOutDateTime = " *Bump in date time is required";
    //     }

    //     if (!values.siteArea || values.siteArea.length === 0) {
    //       errors.siteArea = "*Site Area is Required";
    //     }

    //     if (!values.abnNumber || values.abnNumber.length === 0) {
    //       errors.abnNumber = "* ABN Bumber is Required";
    //     }
    //     if (values.abnNumber.length > 11) {
    //       errors.abnNumber = " *Please enter correct ABN Number";
    //     }
    //     if (!values.contactNumber || values.contactNumber.length === 0) {
    //       errors.contactNumber = "*Last Name is Required";
    //     }

    //     if (!values.eventName || values.eventName.length === 0) {
    //       errors.eventName = "*Event Name is Required";
    //     }
    //     if (!values.email || values.email.length === 0) {
    //       errors.email = "*Email is Required";
    //     }
    //     if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    //       errors.email = "*Please enter valid email address";
    //     }

    //     if (!values.eventFile || values.eventFile.length === 0) {
    //       errors.eventFile = "*Organisation terms and conditions is Required";
    //     }

    //     if (!values.eventLogo || values.eventLogo.length === 0) {
    //       errors.eventLogo = "*Organisation logo is Required";
    //     }
    //     return errors;
    //   }
    // },

    onSubmit: (values) => {
      const raw = {
        organisation_logo: values.eventLogo[0],
        company_name: values.eventName,
        warehouse_address: values.warehouseAddress.formatted_address,
        company_email: values.emailEOI,
        company_abn_number: values.abnNumber,
        company_location: values.warehouseAddress.formatted_address,
        organisation_phone_number: values.contactNumber,
        company_description: values.description,
      };
      const formData = new FormData();
      Object.entries(raw).map((item) => {
        formData.append(`${item[0]}`, item[1]);
      });

      const requestOptions = {
        method: "POST",
        body: formData,
        redirect: "follow",
      };

      if (!isOrganiser) {
        setLoading(true);
        try {
          fetch(url, requestOptions).then((response) => {
            setLoading(false);
            if (response.status == 200) {
              alert("Application Submitted Successful");
              handleClose();
              setOpenSuccess(true);
            }
          });
        } catch (error) {
          setLoading(false);
          handleClose();
          setOpenFail(true);
        }
      } else {
        dispatch(createEOIEvent(values));
        nextStep();
      }
    },

    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <Container style={{ position: "relative" }}>
      <Loader loading={loading} />
      <form onSubmit={formik.handleSubmit}>
        {isOrganiser ? (
          <SupplierInformation formik={formik} />
        ) : (
          <SupplierInformation
            formik={formik}
            getOrganiserStatus={isOrganiser}
          />
        )}

        <Button
          type="submit"
          variant="contained"
          style={{
            marginTop: 20,
            marginBottom: 20,
            backgroundColor: "#2959a5",
          }}
          className="button-submit"
        >
          {!isOrganiser ? <>SUBMIT APPLICATION</> : <>NEXT</>}
        </Button>
      </form>
    </Container>
  );
};

export default SupplierPage;
