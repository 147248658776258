import React, { useEffect, useRef, useState } from "react";
import { getFromLocalStorage } from "../../../store";
import config from "../../../services/apiConfig";
import { apiPaths } from "../../../services/apiPath";
import DatePicker from "react-datepicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import * as Sentry from "@sentry/react";
import vehicleImg from "../../../assets/img/vehicle-highlight.png";
import personImg from "../../../assets/img/person-highlight.png";

import "./style.css";
import ImageHighlighter from "./ImageHighlighter";

import { Controller, useForm } from "react-hook-form";
import { format } from "date-fns";
import Axios from "axios";
import VendorNavBar from "../Component/VendorNavBar";
import Loader from "../../../components/UI/Loader";

const AccidentLogs = () => {
  const vehicleImgRef = useRef(null);
  const personImgRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const [incidentType, setIncidentType] = useState("");
  const [reportedTo, setReportedTo] = useState("");
  const [location, setLocation] = useState("");
  const [incidentDateTime, setIncidentDateTime] = useState("");
  const [relation, setRelation] = useState("");
  const [description, setDescription] = useState("");
  const [note, setNote] = useState("");
  const [attachment, setAttachment] = useState({});
  const [treatmentType, setTreatmentType] = useState("");
  const [vehicleDamageImg, setVehicleDamageImg] = useState({});
  const [personInjuryImg, setPersonInjuryImg] = useState({});
  const [firstAidDescription, setFirstAidDescription] = useState("");
  const [firstAiderName, setFirstAiderName] = useState("");
  const [fistAiderContact, setFistAiderContact] = useState("");
  const [wasEmployeeOnDuty, setWasEmployeeOnDuty] = useState(false);
  const [howLongOnDuty, setHowLongOnDuty] = useState("");
  const [didEmployeeReturn, setDidEmployeeReturn] = useState(false);
  const [didEmployeeStopWork, setDidEmployeeStopWork] = useState(false);
  const [stoppedWorkDateTime, setStoppedWorkDateTime] = useState(new Date());
  const [wasReportedToSupervisor, setWasReportedToSupervisor] = useState(false);
  const [supervisorName, setSupervisorName] = useState("");
  const [witnessStatement, setWitnessStatement] = useState("");
  const [witnessName, setWitnessName] = useState("");
  const [witnessContact, setWitnessContact] = useState("");
  const [witnessRelation, setWitnessRelation] = useState("");

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      supervisorName: "",
      date: new Date(),
      name: "",
      incidentType: "",
      reportedTo: "",
      Location: "",
      incidentDateTime: "",
      relation: "",
      description: "",
      note: "",
      attachment: null,
      treatmentType: "",
      vehicleDamageImg: null,
      personInjuryImg: null,
      firstAidDescription: "",
      firstAiderName: "",
      firstAiderContact: "",
      wasEmployeeOnDuty: false,
      howLongOnDuty: "",
      didEmployeeReturn: false,
      didEmployStopWork: false,
      stoppedWorkDateTime: "",
      wasReportedToSupervisor: false,
      suppervisorName: false,
      witnessStatement: "",
      witnessName: "",
      witnessContact: "",
      witnessRelation: "",
    },
  });

  const handleIncidentType = (event) => {
    setIncidentType(event.target.value);
  };
  const handleRelationChange = (event) => {
    setRelation(event.target.value);
  };

  //   const postEditCustomerProfile = async (event) => {
  //     event.preventDefault();
  //     const url = config.BASE_URI + apiPaths.customerData;
  //     var myHeaders = new Headers();
  //     myHeaders.append("Content-Type", "application/json");

  //     var raw = JSON.stringify({
  //       payload: {
  //         body: {
  //           query_type: "edit_vendor_profile",
  //           vendor_id: vendorId,
  //           first_name: firstName,
  //           last_name: lastName,
  //           email: email,
  //           phone: phone,
  //           state: state,
  //         },
  //       },
  //     });
  //     var requestOptions = {
  //       method: "POST",
  //       headers: myHeaders,
  //       body: raw,
  //       redirect: "follow",
  //     };
  //     fetch(url, requestOptions)
  //       .then((response) => response.json())
  //       .then((dataResponse) => {
  //         if (dataResponse.success === true) {
  //           alert("Profile updated successfully!");
  //           window.location.reload();
  //         }
  //       })
  //       .catch((error) => {
  //         Sentry.captureException(error);
  //         console.error(error);
  //       });
  //   };

  const submitAccidentReport = async (data) => {
    console.log(data, "DATA");

    const formData = new FormData();

    const operatorData =
      getFromLocalStorage("Operator") &&
      JSON.parse(getFromLocalStorage("Operator"))
        ? JSON.parse(getFromLocalStorage("Operator"))[0]
        : {};

    formData.append(
      "fillerName",
      operatorData && operatorData.first_name ? operatorData.first_name : ""
    );
    formData.append("fillerPosition", "OPERATOR");
    formData.append(
      "fillerContact",
      operatorData && operatorData.email ? operatorData.email : ""
    );
    formData.append(
      "operator_id",
      operatorData && operatorData.id ? operatorData.id : ""
    );

    formData.append("name", data.name);
    formData.append("date", format(new Date(), "dd MMM yyyy"));
    formData.append(
      "incidentType",
      data.incidentType == "other" ? data.otherIncidentType : incidentType
    );
    formData.append("reportedTo", data.reportedTo);
    formData.append("location", data.location);
    formData.append("incidentDateTime", data.incidentDateTime);
    formData.append(
      "relation",
      data.relation == "other" ? data.otherRelation : relation
    );
    formData.append("description", data.description);
    formData.append("note", data.note);
    formData.append(
      "attachment",
      data.attachment && data.attachment.length ? data.attachment[0] : null
    );
    formData.append("treatmentType", data.treatmentType);
    formData.append("firstAidDescription", data.firstAidDescription);
    formData.append("firstAiderName", data.firstAiderName);
    formData.append("firstAiderContact", data.firstAiderContact);
    formData.append(
      "wasEmployeeOnDuty",
      data.wasEmployeeOnDuty == true || data.wasEmployeeOnDuty == "true"
        ? true
        : false
    );
    formData.append("howLongOnDuty", data.howLongOnDuty);
    formData.append(
      "didEmployeeReturn",
      data.didEmployeeReturn == true || data.didEmployeeReturn == "true"
        ? true
        : false
    );
    formData.append(
      "didEmployeeStopWork",
      data.didEmployeeStopWork == true || data.didEmployeeStopWork == "true"
        ? true
        : false
    );
    formData.append("stoppedWorkDateTime", data.stoppedWorkDateTime);
    formData.append(
      "wasReportedToSupervisor",
      data.wasReportedToSupervisor == true ||
        data.wasReportedToSupervisor == "true"
        ? true
        : false
    );
    formData.append("supervisorName", data.supervisorName);
    formData.append("witnessStatement", data.witnessStatement);
    formData.append("witnessName", data.witnessName);
    formData.append("witnessContact", data.witnessContact);
    formData.append(
      "witnessRelation",
      data.witnessRelation == "other"
        ? data.otherWitnessRelation
        : witnessRelation
    );

    vehicleImgRef.current.toBlob((blob) => {
      formData.append("vehicleDamageImg", blob);
      personImgRef.current.toBlob((blob) => {
        formData.append("personInjuryImg", blob);
        console.log("INJURY");
        console.log("DATA");
        formData.forEach((value, key) => {
          console.log(`${key}: ${value}`);
        });
        const requestOptions = {
          method: "POST",
          body: formData,
          redirect: "follow",
        };

        const url = config.BASE_URI + apiPaths.incidentLog;

        // Axios.post(config.BASE_URI + apiPaths.incidentLog, formData, {
        //   headers: {
        //     "Content-Type": "multipart/form-data",
        //   },
        // })
        setLoading(true);
        fetch(url, requestOptions)
          .then((res) => res.json())
          .then((response) => {
            setLoading(false);
            console.log("RES", response);
            // Handle response
            if (response.success) {
              alert("Accident Log Submitted Successfully!");
              window.location.reload();
              // Optionally, handle additional success actions like redirecting
            } else {
              alert("Error please try again!");
            }
          })
          .catch((error) => {
            setLoading(false);
            console.error("Submission error:", error);
            alert("Error please try again!");
          });
      });
    });
  };

  return (
    <>
      <VendorNavBar />
      <h2 className="accident-page-heading">Accident Report</h2>
      <div className="form-style-5" style={{ position: "relative" }}>
        <Loader loading={loading} />
        <form
          className="log-form"
          onSubmit={handleSubmit(submitAccidentReport)}
        >
          <h5 className="text-red-500">Section 1</h5>
          <div className="control">
            <label>Incident Type</label>
            <select
              value={incidentType}
              {...register("incidentType", {
                onChange: (e) => handleIncidentType(e),
              })}
            >
              <option value="" disabled>
                Select Type
              </option>
              <option value="injury">Injury</option>
              <option value="illness">Illness</option>
              <option value="security">Security</option>
              <option value="near miss">
                Near Miss - Person or property Damage
              </option>
              <option value="property">Property</option>
              <option value="vehicle">Vehicle</option>
              <option value="environment">Environment</option>
              <option value="other">Other</option>
            </select>

            {incidentType === "other" ? (
              <input {...register("otherIncidentType")} />
            ) : null}
          </div>

          <div className="control">
            <label htmlFor="reportedTo">Incident Reported to:</label>
            <input
              id="reportedTo"
              name="reportedTo"
              {...register("reportedTo")}
            />
          </div>
          <div className="control">
            <label htmlFor="location">Location:</label>
            <input id="location" name="location" {...register("location")} />
          </div>
          <div className="control">
            <label htmlFor="name">Full Name of affected Person:</label>
            <input id="name" name="name" {...register("name")} />
          </div>

          <div className="control">
            <label htmlFor="relation">Relationship to AMFVG:</label>
            <select
              value={relation}
              {...register("relation", {
                onChange: (e) => handleRelationChange(e),
              })}
            >
              <option value="" disabled>
                Select Relationship
              </option>
              <option value="AMFVG Employee">AMFVG Employee</option>

              <option value="other">Other</option>
            </select>

            {relation === "other" ? (
              <input {...register("otherRelation")} />
            ) : null}
          </div>
          <div className="control">
            <label>Description</label>
            <textarea
              placeholder=" (describe clearly how the incident occurred and the events that led up to the occurrence of the incident) What tasks was the affected person completing at the time?"
              type="text"
              rows="4"
              cols="75"
              {...register("description")}
            ></textarea>
          </div>

          <div className="control">
            <p>Accident Date and Time</p>
            <Controller
              name="incidentDateTime"
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <DatePicker
                  selected={value}
                  onChange={onChange} // Send value to hook form
                  onBlur={onBlur} // Notify when the input is blurred
                  ref={ref} // Pass ref to the date picker
                  dateFormat={"dd/MM/yyyy hh:mm"}
                  // Set your desired date format
                  placeholderText="Select a date"
                  showTimeInput
                />
              )}
            />
            {/* <DatePicker
            // {...register("incidentDateTime")}
            onChange={(date) => setIncidentDateTime(date)}
            selected={incidentDateTime || new Date()}
            dateFormat={"dd/MM/yyyy hh:mm A"}
            showTimeInput
          /> */}
          </div>
          <div className="control">
            <label>Note</label>
            <textarea
              placeholder=""
              type="text"
              rows="4"
              cols="75"
              {...register("note")}
            ></textarea>
          </div>
          <div className="control">
            <label htmlFor="attachment">Attach Video, Image or Document</label>
            <input
              className="form-input"
              type="file"
              id="attachment"
              name="attachment"
              {...register("attachment")}
            />
          </div>
          <div className="control">
            <label>Indicate Treatment Given</label>
            <select {...register("treatmentType")}>
              <option value="" disabled>
                Select Treatment Type
              </option>
              <option value="First Aid">First Aid</option>
              <option value="Sent to Hospital">Sent to Hospital</option>
              <option value="Refused Treatment">Refused Treatment</option>
              <option value="Referred to a Doctor">Referred to a Doctor</option>
              <option value="Ambulance Attended">Ambulance Attended</option>
              <option value="Nothing Required">Nothing Required</option>
            </select>
          </div>

          <div className="control">
            <b>To be completed where vehicle damage has occurred.</b>
            <label>Please indicate damage on the diagram below</label>

            <ImageHighlighter ref={vehicleImgRef} imgSrc={vehicleImg} />
          </div>

          <div className="control">
            <b>To be completed where the person is injured or ill</b>
            <label>Please highlight affected body part.</label>

            <ImageHighlighter ref={personImgRef} imgSrc={personImg} />
          </div>
          <div className="control">
            <label>Indicate what type of First Aid was administered:</label>
            <textarea
              type="text"
              rows="4"
              cols="75"
              {...register("firstAidDescription")}
            ></textarea>
          </div>
          <div className="control">
            <label htmlFor="firstAiderName">Full Name of First Aider:</label>
            <input
              id="firstAiderName"
              name="firstAiderName"
              {...register("firstAiderName")}
            />
          </div>
          <div className="control">
            <label htmlFor="firstAiderContact">First Aider Phone Number:</label>
            <input
              id="firstAiderContact"
              name="firstAiderContact"
              {...register("firstAiderContact")}
            />
          </div>

          <hr />

          <h5>Section 2: To be completed for AMFVG Employee only</h5>
          <div className="control">
            <label>
              {" "}
              Was the employee on duty at the time of the incident:
            </label>
            <select
              {...register("wasEmployeeOnDuty", {
                onChange: (e) => {
                  setWasEmployeeOnDuty(e.target.value);
                  // console.log(e.target.value);
                },
              })}
            >
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </select>
          </div>
          {wasEmployeeOnDuty == true || wasEmployeeOnDuty == "true" ? (
            <>
              <div className="control">
                <label htmlFor="howLongOnDuty">
                  How long was the employee on duty before the incident
                  occurred:
                </label>
                <input
                  id="howLongOnDuty"
                  name="howLongOnDuty"
                  {...register("howLongOnDuty")}
                />
              </div>
              <div className="control">
                <label>
                  Did the employee return to work after the incident?:
                </label>
                <select {...register("didEmployeeReturn")}>
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </select>
              </div>
              <div className="control">
                <label>Did the employee cease work after the incident?:</label>
                <select {...register("didEmployStopWork")}>
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </select>
              </div>
              <div className="control">
                <p>Date & Time employee ceased work</p>
                <Controller
                  name="stoppedWorkDateTime"
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <DatePicker
                      selected={value}
                      onChange={onChange} // Send value to hook form
                      onBlur={onBlur} // Notify when the input is blurred
                      ref={ref} // Pass ref to the date picker
                      dateFormat={"dd/MM/yyyy hh:mm"}
                      // Set your desired date format
                      placeholderText="Select a date"
                      showTimeInput
                    />
                  )}
                />
              </div>
            </>
          ) : null}
          <div className="control">
            <label> Was this reported to a supervisor:</label>
            <select
              value={wasReportedToSupervisor}
              {...register("wasReportedToSupervisor", {
                onChange: (e) => {
                  setWasReportedToSupervisor(e.target.value);
                  console.log(e.target.value);
                },
              })}
            >
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </select>
          </div>
          {wasReportedToSupervisor == true ||
          wasReportedToSupervisor == "true" ? (
            <div className="control">
              <label htmlFor="supervisorName">Full Name of Supervisor:</label>
              <input
                id="supervisorName"
                name="supervisorName"
                {...register("supervisorName")}
              />
            </div>
          ) : null}

          <hr />

          <h5>
            Section 3: To be completed if any person witnessed the incident
          </h5>
          <div className="control">
            <label>Witness statement: </label>
            <textarea
              placeholder="(describe clearly the witnesses’ version of the incident)"
              type="text"
              rows="4"
              cols="75"
              {...register("witnessStatement")}
            ></textarea>
          </div>
          <div className="control">
            <label htmlFor="witnessName">Full Name of Witness:</label>
            <input
              id="witnessName"
              name="witnessName"
              {...register("witnessName")}
            />
          </div>
          <div className="control">
            <label htmlFor="witnessContact">Witness Phone Number:</label>
            <input
              id="witnessContact"
              name="witnessContact"
              {...register("witnessContact")}
            />
          </div>
          <div className="control">
            <label htmlFor="withnessRelation">Is the Witness a:</label>
            <select
              value={witnessRelation}
              {...register("witnessRelation", {
                onChange: (e) => {
                  setWitnessRelation(e.target.value);
                },
              })}
            >
              <option value="" disabled>
                Select Relationship
              </option>
              <option value="AMFVG Employee">AMFVG Employee</option>

              <option value="other">Other</option>
            </select>
            {witnessRelation == "other" ? (
              <input {...register("otherWitnessRelation")} />
            ) : null}
          </div>
          <hr />

          <button type="submit">Submit Accident Report</button>
        </form>
      </div>
    </>
  );
};
export default AccidentLogs;
