import React from "react";

const Loader = ({ loading }) => {
  return loading === true ? (
    <div
      style={{
        textAlign: "center",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
        position: "absolute",
        top: 0,
        left: 0,
        background: "rgba(255,255,255,0.7)",
        zIndex: 100000,
      }}
    >
      <img src={require("../../../assets/img/loading.gif")}></img>
    </div>
  ) : null;
};

export default Loader;
