import React, { useState } from "react";

const ChatimeEventsForm = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [attendees, setAttendees] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !firstName ||
      !lastName ||
      !phone ||
      !email ||
      !eventDate ||
      !attendees ||
      !eventDescription
    ) {
      alert("All fields are required!");
      return;
    }

    setIsLoading(true);

    // Email message content
    const message = `
      <table style='width:100%;'>
        <tr><td><b>First Name:</b></td><td>${firstName}</td></tr>
        <tr><td><b>Last Name:</b></td><td>${lastName}</td></tr>
        <tr><td><b>Phone:</b></td><td>${phone}</td></tr>
        <tr><td><b>Email:</b></td><td>${email}</td></tr>
        <tr><td><b>Event Date:</b></td><td>${eventDate}</td></tr>
        <tr><td><b>Number of Attendees:</b></td><td>${attendees}</td></tr>
        <tr><td><b>Event Description:</b></td><td>${eventDescription}</td></tr>
      </table>
    `;

    const emailPayload = {
        key: "KqogytxmJIhoq_Cd3Wxqmg",
        template_name: "v-2-2020-chatime-brand",
        template_content: [
          {
            name: "YOUR_MERGETAG",
            content: "<p>Testing</p>",
          },
        ],
        message: {
          html: "<p>Example HTML content</p>",
          text: "Example text content",
          subject: "Chatime Events Enquiry",
          from_email: "noreply@wherethetruck.at",
          from_name: "Chatime Events",
          to: [
            {
              email: "events@rusitigroup.com.au",
              name: "Jane",
              type: "to",
            },
          ],
          headers: {
            "Reply-To": "noreply@wherethetruck.at",
          },
          important: false,
          track_opens: null,
          track_clicks: null,
          auto_text: null,
          auto_html: null,
          inline_css: null,
          url_strip_qs: null,
          preserve_recipients: null,
          view_content_link: null,
          tracking_domain: null,
          signing_domain: null,
          return_path_domain: null,
          merge: true,
          merge_language: "mailchimp",
          global_merge_vars: [
            {
              name: "YOUR_MERGETAG",
              content: message,
            },
          ],
        },
      };

    try {
      const response = await fetch(
        "https://mandrillapp.com/api/1.0/messages/send-template.json",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(emailPayload),
        }
      );

      const result = await response.json();

      if (response.ok) {
        alert("Form submitted successfully! The details have been sent.");
        setFirstName("");
        setLastName("");
        setPhone("");
        setEmail("");
        setEventDate("");
        setAttendees("");
        setEventDescription("");
      } else {
        console.error("Error sending email:", result);
        alert("Failed to send the form. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.header}>Chatime Events Form</h1>
      <form onSubmit={handleSubmit} style={styles.form}>
        <img
          src="https://www.erinafair.com.au/siteassets/cha-time.jpg"
          alt="Chatime Events"
          style={styles.image}
        />
        <div style={styles.inputGroup}>
          <label>First Name:</label>
          <input
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            style={styles.input}
          />
        </div>
        <div style={styles.inputGroup}>
          <label>Last Name:</label>
          <input
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            style={styles.input}
          />
        </div>
        <div style={styles.inputGroup}>
          <label>Phone Number:</label>
          <input
            type="tel"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            style={styles.input}
          />
        </div>
        <div style={styles.inputGroup}>
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={styles.input}
          />
        </div>
        <div style={styles.inputGroup}>
          <label>Event Date:</label>
          <input
            type="date"
            value={eventDate}
            onChange={(e) => setEventDate(e.target.value)}
            style={styles.input}
          />
        </div>
        <div style={styles.inputGroup}>
          <label>Number of Attendees:</label>
          <input
            type="number"
            value={attendees}
            onChange={(e) => setAttendees(e.target.value)}
            style={styles.input}
          />
        </div>
        <div style={styles.inputGroup}>
          <label>Event Description:</label>
          <textarea
            value={eventDescription}
            onChange={(e) => setEventDescription(e.target.value)}
            style={styles.textarea}
          ></textarea>
        </div>
        <button type="submit" style={styles.submitButton} disabled={isLoading}>
          {isLoading ? "Submitting..." : "Submit"}
        </button>
      </form>
    </div>
  );
};

// Inline styles
const styles = {
  container: {
    maxWidth: "600px",
    margin: "0 auto",
    padding: "20px",
    fontFamily: "Arial, sans-serif",
  },
  header: {
    textAlign: "center",
    color: "#333",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  inputGroup: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
  },
  input: {
    padding: "10px",
    fontSize: "16px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  textarea: {
    padding: "10px",
    fontSize: "16px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    minHeight: "100px",
  },
  submitButton: {
    padding: "10px 20px",
    fontSize: "16px",
    color: "#fff",
    backgroundColor: "#007bff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  image: {
    width: "100%",
    height: "auto",
    marginBottom: "20px",
  },
};

export default ChatimeEventsForm;
