import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

// Importing the Bootstrap CSS
/* eslint-disable react/no-array-index-key */
import "bootstrap/dist/css/bootstrap.min.css";
import LandingScreen from "./screens/EventOrganiserOverviewScreen/LandingScreen";
import MainLanding from "./screens/EventOrganiserOverviewScreen/MainLanding";
import OperatorLandingScreen from "./screens/VendorApplyEventScreen/OperatorLandingScreen";
import { Provider } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { store } from "./store/index";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import MenuScreen from "./screens/MenuScreen";
import CheckoutScreen from "./screens/CheckoutScreen";
import CheckoutOldScreen from "./screens/CheckoutScreenOld";
import DriverScreen from "./screens/DriverScreen";
import OrdersScreen from "./screens/OrdersScreen";
import ProfilePage from "./screens/ProfilePage";
import DriverApp from "./screens/DriverApp";
import SalesPage from "./screens/VendorProfile/SalesPage";
import EventScreen from "./screens/EventScreen";
import AllEventsScreen from "./screens/AllEventsScreen";
import BookATruckScreen from "./screens/BookATruckScreen";
import CateringPacksScreen from "./screens/CateringPacksScreen";
import PollScreen from "./screens/PollScreen";
import VendorResponseScreen from "./screens/VendorResponseScreen";
import CumulativeSales from "./screens/CumulativeSales";
import VendorNewRequestScreen from "./screens/VendorNewRequestScreen";
import AdminScreen from "./screens/AdminScreen";
import PaymentScreen from "./screens/PaymentScreen";
import AdminEmailsToVendorScreen from "./screens/AdminEmailsToVendorScreen";
import AdminDailyQuotes from "./screens/AdminDailyQuotes";
import VendorExistingRequestScreen from "./screens/VendorExistingRequestScreen";
import RedirectLinkComponent from "./screens/RedirectLinkComponent";
import AdminOperatorOrdersScreen from "./screens/AdminOperatorOrdersScreen";
import AdminDashboardScreen from "./screens/AdminDashboardScreen";
import CateringAnalytics from "./screens/CateringAnalytics";
import CompareVendors from "./screens/CompareVendors";
import AdminCreateEventScreen from "./screens/AdminCreateEventScreen";
import AdminCreateEventStockOrder from "./screens/AdminCreateEventStockOrder";
import AdminVendorCateringApplications from "./screens/AdminVendorCateringApplications";
import AdminVendorOrdersPage from "./screens/AdminVendorOrdersPage";
import EventMenuScreen from "./screens/EventMenuScreen";
import AdminAllEventsScreen from "./screens/AllEventsScreen";
//import AdminMenuEditScreen from './screens/AdminMenuEditScreen'
import EventOrganiserOrdersScreen from "./screens/EventOrganiserOrdersScreen";
import EventOrganiserOverviewScreen from "./screens/EventOrganiserOverviewScreen";
import SupplierOverviewScreen from "./screens/SupplierOverViewScreen";
import OrganiserScreen from "./screens/OrganiserScreen/organiserScreen";
import SupplierScreen from "./screens/SupplierLogin/supplierScreen";
import VendorApplyEventScreen from "./screens/VendorApplyEventScreen";
import TruckAnalytics from "./screens/TruckAnalytics";
import AppLinkScreen from "./screens/AppLinkScreen";
import CreateEvents from "./screens/CreateEvents";
import ViewPendingVendors from "./screens/EventOrganiserOverviewScreen/ViewPendingVendors";
import ViewPastEvents from "./screens/EventOrganiserOverviewScreen/ViewPastEvents";
import VendorRegisterScreen from "./screens/VendorApplyEventScreen/vendorRegisterScreen";
import RegisterPage from "./screens/RegisterScreen/index";
import ForgotPassword from "./screens/ForgotPassword/index";
// import AdminPendingMenuScreen from "./screens/AdminPendingMenuScreen/index.js";

import Contract from "./screens/ContractScreen";
import AdminPendingEventApplicationsScreen from "./screens/AdminPendingEventApplicationsScreen";
import AdminEventMenu from "./screens/AdminEventMenu";
import AdminPendingSupplierApplicationsScreen from "./screens/AdminPendingSupplierApplicationsScreen";
import AdminTxnReportScreen from "./screens/AdminTxnReportScreen/index.js";
import AdminSalesReportScreen from "./screens/AdminSalesReportScreen";
import StockScreen from "./screens/StockScreen";
import StockOrderScreen from "./screens/StockOrderScreen";
import StockAdminScreen from "./screens/StockAdminScreen";
import StockUpload from "./screens/StockUpload";
import AdminMembershipContracts from "./screens/AdminMembershipContracts";
import StockPortalPendingStockQuote from "./screens/StockPortalPendingStockQuote";
import StockPortalSupplierCatelogueList from "./screens/StockPortalSupplierCatelogueList";
import StockAdminUsageReportScreen from "./screens/StockAdminUsageReportScreen";
import StockAdminAddStockBundleScreen from "./screens/StockAdminAddStockBundleScreen";
import StockAdminEditStockBundleScreen from "./screens/StockAdminEditStockBundleScreen";
import StockAdminOrderEditScreen from "./screens/StockAdminOrderEditScreen";
import StockOrderEditScreen from "./screens/StockOrderEditScreen";
import StockAdminApprovedOrdersScreen from "./screens/StockAdminApprovedOrdersScreen";
import StockAdminStockListScreen from "./screens/StockAdminStockListScreen";
import StockCatelogueList from "./screens/StockCatelogueList";
import OperatorStockScreen from "./screens/OperatorStockScreen";
import OperatorStockOrderScreen from "./screens/OperatorStockOrderScreen";
import OperatorStockOrderEditScreen from "./screens/OperatorStockOrderEditScreen";
import AdminEditEventScreen from "./screens/AdminEditEventScreen";
import RegisterStall from "./screens/RegisterStall";
import VendorMenuAddScreen from "./screens/VendorMenuAddScreen";
import VendorMenuEditScreen from "./screens/VendorMenuEditScreen";
import AdminExternalOperatorsDashboardScreen from "./screens/AdminExternalOperatorsDashboardScreen";
import AdminCreateRunsheets from "./screens/AdminCreateRunsheets";
import AdminEventCalenderScreen from "./screens/AdminEventCalenderScreen";
import AdminCreateEventUtilityOrder from "./screens/AdminCreateEventUtilityOrder";
import AdminPayout from "./screens/AdminPayout";
import EventUtilityPurchases from "./screens/EventUtilityPurchases";
import VendorProfile from "./screens/VendorProfile";
import VendorViewPendingContracts from "./screens/VendorViewPendingContracts";
import OperatorSubaccount from "./screens/OperatorSubaccount";
import OperatorRequestStockQuote from "./screens/OperatorRequestStockQuote";
import StockPortalApprovedOrdersScreenQuotes from "./screens/StockPortalApprovedOrdersScreenQuotes";
import OperatorEventSales from "./screens/OperatorEventSales";
import OperatorRegoSales from "./screens/OperatorRegoSales";
import StockTransferRequestScreen from "./screens/StockTransferReauestsScreen";

import KioskLanding from "./screens/KioskLanding";

import OperatorSalesByEventScreen from "./screens/OperatorSalesByEventScreen";
import OperatorEventExpenses from "./screens/OperatorEventExpenses";
import OrganiserEventExpenses from "./screens/OrganiserEventExpenses";
import OrganiserEventContract from "./screens/OrganiserEventContract";
import OrganiserSubaccount from "./screens/OrganiserSubaccount";
import VendorOutline from "./screens/VendorOutline";
// import OperatorEventSales from "./screens/OperatorEventSales";
import OperatorPosDetailsScreen from "./screens/OperatorPosDetailsScreen";
import OperatorRunSheets from "./screens/OperatorRunSheets";
import OperatorAddMenu from "./screens/OperatorAddMenu";
import OperatorEditMenu from "./screens/OperatorEditMenu";
import AdminStockAnalytics from "./screens/AdminStockAnalytics";
import NoMatch from "./screens/NoMatch";
import AdminEmailsToCustomersScreen from "./screens/AdminEmailsToCustomersScreen";
import AdminCateringAnalyticsScreen from "./screens/AdminCateringAnalyticsScreen";
import AdminSalesBySiteArea from "./screens/AdminSalesBySiteArea";
import AdminEventCalenderMonth from "./screens/AdminEventCalenderMonth";
import SharableOperatorLinkScreen from "./screens/SharableOperatorLinkScreen";
import AdminProfitAndLossScreenByEvent from "./screens/AdminProfitAndLossScreenByEvent";
import AppPrivacyPolicy from "./screens/AppPrivacyPolicy";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import VendorStatementOfTradesScreen from "./screens/VendorStatementOfTradesScreen";
import OperatorPayouts from "./screens/OperatorPayouts";
import OrganiserPayout from "./screens/OrganiserPayout";
import OperatorTransactionsOverview from "./screens/OperatorTransactionsOverview/index.js";
import AdminSalesReportByEvent from "./screens/AdminSalesReportByEvent/index.js";
import VendorPublicLiablityWithIntrestedParty from "./screens/VendorPublicLiablityWithIntrestedParty/index.js";
import EventOrganiserFeedback from "./screens/EventOrganiserFeedback";
import OperatorContractScreen from "./screens/OperatorContractScreen";
import AdminLoginScreen from "./screens/AdminLoginScreen";
import {
  getFromLocalStorage,
  deleteFromLocalStorage,
  saveToLocalStorage,
} from "./store";
import SharedOutlet from "./screens/SharedOutletScreen";
import OperatorPostEventFeedbackScreen from "./screens/OperatorPostEventFeedbackScreen";

import RunnerOrdersScreen from "./screens/RunnerOrdersScreen/index.js";

import AdminShareMultipleStalls from "./screens/AdminShareMultipleStalls/index.js";
import AdminCateringScreenMultiplePacks from "./screens/AdminCateringScreenMultiplePacks/index.js";
import TruckManagerEventFeedbackScreen from "./screens/TruckManagerEventFeedbackScreen/index.js";
import OrganiserSiteAreaScreen from "./screens/OrganiserSiteAreaScreen/index.js";
import AdminEditRunsheets from "./screens/AdminEditRunsheets";
import CustomerOrders from "./screens/CustomerOrders";
import AdminStockOrderScreen from "./screens/AdminStockOrderScreen/index.js";
import AdminPostEventReportScreen from "./screens/AdminPostEventReportScreen/index.js";
import WTTSubscriptionPaymentScreen from "./screens/WTTSubscriptionPaymentScreen/index.js";
import SignaturePad from "./screens/ReactSignatureCanvas/index.js";
import NewScreen from "./screens/NewScreen";
import AdminSubaccount from "./screens/AdminSubaccount";
import OperatorPendingSubscription from "./screens/OperatorPendingSubscription";

import AdminEventStatementOfTradesScreen from "./screens/AdminEventStatementOfTradesScreen/index.js";
import EventOrganiserPostEventReport from "./screens/EventOrganiserPostEventReport/index.js";
import AdminEmailsToEventOrganisersScreen from "./screens/AdminEmailsToEventOrganisersScreen/index.js";
import AdminCateringPresentationsSendToCustomersScreen from "./screens/AdminCateringPresentationsSendToCustomersScreen/index.js";
import ImportEventsScreen from "./screens/EventOrganiserOverviewScreen/ImportEventsScreen/index.js";
import AdminApplyBulkToEvents from "./screens/AdminApplyBulkToEvents/index.js";
import EventsByVenueScreen from "./screens/EventsByVenueScreen/index.js";
import EventsByTruckScreen from "./screens/EventsByTruckScreen/index.js";
import SubscriptionForm from "./screens/VendorApplyEventScreen/SusbcriptionForm/index.js";
import OrganiserProfileScreen from "./screens/EventOrganiserProfileScreen/index.js";
import AdminUpdatePOSEvent from "./screens/AdminUpdatePOSEvent/index.js";
import AdminPreEventChecklistListScreen from "./screens/AdminPreEventChecklistListScreen/index.js";
import AdminPostEventChecklistListScreen from "./screens/AdminPostEventChecklistListScreen/index.js";
import AdminPOSKioskCodesScreen from "./screens/AdminPOSKioskCodesScreen/index.js";
import DepartmentAccessByEventScreen from "./screens/DepartmentAccessByEventScreen/index.js";
import HealthDepartmentAccessByEventScreen from "./screens/HealthDepartmentAccessByEventScreen/index.js";
import PreEventChecklist from "./screens/PreEventChecklist/index.js";
import PostEventChecklist from "./screens/PostEventChecklist/index.js";
import AccidentLogs from "./screens/VendorApplyEventScreen/AccidentLogs/index.js";
import ElectricalEquipmentScreen from "./screens/ElectricalEquipmentScreen/index.js";
import IncidentReportScreen from "./screens/IncidentReportScreen/index.js";
import PendingSubscriptionApplications from "./screens/PendingSubscriptionApplications/index.js";
import VendorStockInventory from "./screens/VendorStockInventory/index.js";
import VendorStoreRoom from "./screens/VendorStoreRoom/index.js";
import TruckStockInventoy from "./screens/TruckStockInventoy/index.js";
import VendorClosingStockScreen from "./screens/VendorClosingStockScreen/index.js";
import OperatorShareALinkScreen from "./screens/OperatorShareALinkScreen/index.js";
import OperatorApplicationScreen from "./screens/OperatorApplicationScreen/index.js";
import AdminPendingMenuScreen from "./screens/AdminPendingMenuScreen/index.js";
import VendorSalesPredictionScren from "./screens/VendorSalesPredictionScreen/index.jsx";
import AdminSalesPrediction from "./screens/AdminSalesPrediction/index.jsx";
import QueueSkipperLanding from "./screens/QueueSkipperLanding/index.js";
import OperatorMenuScreen from "./screens/OperatorMenuScreen/index.js";
import OperatorCateringScreen from "./screens/OperatorCateringScreen/index.js";
import OperatorDocuments from "./screens/OperatorDocuments/index.js";
import FAQPage from "./screens/FAQPage/index.js";
import VendorEOI from "./screens/VendorEOI/index.js";
import AdminRosterPage from "./screens/AdminRosterPage/index.js";
import AdminWorkforceManagementUsers from "./screens/AdminWorkforceManagementUsers/index.js";
import ChatimeEventsForm from "./screens/ChatimeEventsForm/index.js";
import VendorStockUsage from "./screens/VendorStockUsage/index.js";
import OperatorPOSEventScreen from "./screens/OperatorPOSEventScreen/index.js";

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route path="/" exact component={App} />
        <Route
          path="/truck/:truckId"
          component={() => <EventsByTruckScreen></EventsByTruckScreen>}
        />
        <Route
          path="/venue/:venueId"
          component={() => <EventsByVenueScreen></EventsByVenueScreen>}
        />
        <Route
          path="/events"
          component={() => <AllEventsScreen></AllEventsScreen>}
        />

        <Route path="/event:id" component={() => <EventScreen></EventScreen>} />
        <Route
          path="/register"
          component={() => (
            <>
              <RegisterPage></RegisterPage>
            </>
          )}
        />

        <Route
          path="/organiser-site-area"
          component={() => (
            <>
              <OrganiserSiteAreaScreen></OrganiserSiteAreaScreen>
            </>
          )}
        />
        <Route
          path="/truck-manager-feedback"
          component={() => (
            <>
              <TruckManagerEventFeedbackScreen></TruckManagerEventFeedbackScreen>
            </>
          )}
        />
        <Route
          path="/signature"
          component={() => (
            <>
              <SignaturePad></SignaturePad>
            </>
          )}
        />
        <Route
          path="/forgot-password"
          component={() => (
            <>
              <ForgotPassword></ForgotPassword>
            </>
          )}
        />
        <Route
          path="/admin-share-multiple-stalls"
          component={() => (
            <>
              <AdminShareMultipleStalls></AdminShareMultipleStalls>
            </>
          )}
        />

        <Route
          path="/operator-public-liablity-with-intrested-party"
          component={() => (
            <VendorPublicLiablityWithIntrestedParty></VendorPublicLiablityWithIntrestedParty>
          )}
        />
        <Route
          path="/operator-orders-overview"
          component={() => (
            <OperatorTransactionsOverview></OperatorTransactionsOverview>
          )}
        />
        <Route
          path="/shared-outlet"
          component={() => (
            <>
              <SharedOutlet></SharedOutlet>
            </>
          )}
        />
        <Route
          path="/admin-sales-prediction"
          component={() => (
            <>
              <AdminSalesPrediction></AdminSalesPrediction>
            </>
          )}
        />
        <Route
          path="/shared-link-application-calender"
          component={() => (
            <>
              <OperatorShareALinkScreen></OperatorShareALinkScreen>
            </>
          )}
        />

        <Route
          path="/operator-application-screen"
          component={() => (
            <>
              <OperatorApplicationScreen></OperatorApplicationScreen>
            </>
          )}
        />

        <Route
          path="/electrician-access"
          component={() => (
            <>
              <DepartmentAccessByEventScreen></DepartmentAccessByEventScreen>
            </>
          )}
        />
        <Route
          path="/health-department-access"
          component={() => (
            <>
              <HealthDepartmentAccessByEventScreen></HealthDepartmentAccessByEventScreen>
            </>
          )}
        />
        <Route
          path="/organiser-feedback"
          component={() => (
            <>
              <EventOrganiserFeedback></EventOrganiserFeedback>
            </>
          )}
        />
        <Route
          path="/admin-pre-event-checklist-list"
          component={() => (
            <>
              <AdminPreEventChecklistListScreen></AdminPreEventChecklistListScreen>
            </>
          )}
        />

        <Route
          path="/admin-post-event-checklist-list"
          component={() => (
            <>
              <AdminPostEventChecklistListScreen></AdminPostEventChecklistListScreen>
            </>
          )}
        />

        <Route
          path="/admin-bulk-apply"
          component={() => (
            <>
              <AdminApplyBulkToEvents></AdminApplyBulkToEvents>
            </>
          )}
        />
        <Route
          path="/operator-pos-details"
          component={() => (
            <OperatorPosDetailsScreen></OperatorPosDetailsScreen>
          )}
        />
        <Route
          path="/chatime-events"
          component={() => <ChatimeEventsForm></ChatimeEventsForm>}
        />

<Route
          path="/operator-create-pos-event"
          component={() => <OperatorPOSEventScreen></OperatorPOSEventScreen>}
        />
        <Route
          path="/admin-event-calender-month"
          component={() => <AdminEventCalenderMonth></AdminEventCalenderMonth>}
        />
        <Route
          path="/register-stall"
          component={() => (
            <>
              <RegisterStall></RegisterStall>
            </>
          )}
        />
        <Route
          path="/vendor-profile/profile"
          exact
          render={() => {
            const isOperatorLoggedIn = getFromLocalStorage(
              "isOperatorLoggedIn"
            );
            if (isOperatorLoggedIn === null || isOperatorLoggedIn === "false") {
              window.location.href = "/operator-login";
              return null;
            }
            saveToLocalStorage("activeProfileComponent", "profile");
            return <VendorProfile />;
          }}
        />
        <Route
          path="/vendor-profile/payment"
          exact
          render={() => {
            const isOperatorLoggedIn = getFromLocalStorage(
              "isOperatorLoggedIn"
            );
            const isOperatorAccountant = getFromLocalStorage(
              "isOperatorAccountant"
            );
            if (
              isOperatorAccountant === "true" ||
              isOperatorLoggedIn === "true"
            ) {
              saveToLocalStorage("activeProfileComponent", "payment");
              return <VendorProfile />;
            } else {
              console.log("innit");
              debugger;
              window.location.href = "/operator-login";
              return null;
            }
          }}
        />
        <Route
          path="/vendor-profile/membership"
          exact
          render={() => {
            const isOperatorLoggedIn = getFromLocalStorage(
              "isOperatorLoggedIn"
            );
            if (isOperatorLoggedIn === null || isOperatorLoggedIn === "false") {
              window.location.href = "/operator-login";
              return null;
            }
            saveToLocalStorage("activeProfileComponent", "membership");
            return <VendorProfile />;
          }}
        />
        <Route path="/sales-page" component={() => <SalesPage></SalesPage>} />
        <Route
          path="/create-events"
          component={() => <CreateEvents></CreateEvents>}
        />
        <Route
          path="/admin-stock-order-screen"
          component={() => <AdminStockOrderScreen></AdminStockOrderScreen>}
        />

        <Route
          path="/view-pending-vendors"
          component={() => <ViewPendingVendors></ViewPendingVendors>}
        />
        <Route
          path="/view-past-events"
          component={() => <ViewPastEvents></ViewPastEvents>}
        />
        <Route
          path="/import-events"
          component={() => <ImportEventsScreen></ImportEventsScreen>}
        />
        <Route
          path="/vendor-response-screen:id"
          component={() => <VendorResponseScreen></VendorResponseScreen>}
        />
        <Route
          path="/vendor-request-screen:id"
          component={() => <VendorNewRequestScreen></VendorNewRequestScreen>}
        />
        <Route
          path="/vendor-existing-request-screen:id"
          component={() => (
            <VendorExistingRequestScreen></VendorExistingRequestScreen>
          )}
        />
        <Route path="/vendor:id" component={() => <MenuScreen></MenuScreen>} />
        <Route
          path="/event-menu"
          component={() => <EventMenuScreen></EventMenuScreen>}
        />
        <Route
          path="/admin-pending-event-application-screen"
          component={() => (
            <AdminPendingEventApplicationsScreen></AdminPendingEventApplicationsScreen>
          )}
        />
        <Route
          path="/event-food/"
          component={() => <CustomerOrders></CustomerOrders>}
        />
        <Route
          path="/membership"
          component={() => (
            <WTTSubscriptionPaymentScreen></WTTSubscriptionPaymentScreen>
          )}
        />
        <Route
          path="/admin-roster-page"
          component={() => <AdminRosterPage></AdminRosterPage>}
        />
        <Route
          path="/admin-tanda-users"
          component={() => (
            <AdminWorkforceManagementUsers></AdminWorkforceManagementUsers>
          )}
        />

        <Route
          path="/operator-pre-event"
          component={() => <PreEventChecklist></PreEventChecklist>}
        />
        <Route
          path="/operator-post-event"
          component={() => <PostEventChecklist></PostEventChecklist>}
        />
        <Route
          path="/operations-logs"
          component={() => <AccidentLogs></AccidentLogs>}
        />
        <Route
          path="/predict-sales"
          component={() => (
            <VendorSalesPredictionScren></VendorSalesPredictionScren>
          )}
        />
        <Route
          path="/truck-stock-inventory"
          component={() => <TruckStockInventoy></TruckStockInventoy>}
        />
        <Route
          path="/operator-stock-inventory"
          component={() => <VendorStockInventory></VendorStockInventory>}
        />
        <Route
          path="/stock-usage"
          component={() => <VendorStockUsage></VendorStockUsage>}
        />
        <Route
          path="/operator-store-room"
          component={() => <VendorStoreRoom></VendorStoreRoom>}
        />
        <Route
          path="/closing-stock"
          component={() => (
            <VendorClosingStockScreen></VendorClosingStockScreen>
          )}
        />

        <Route
          path="/operator-electrical"
          component={() => (
            <ElectricalEquipmentScreen></ElectricalEquipmentScreen>
          )}
        />
        <Route
          path="/admin-post-event-report-screen"
          component={() => (
            <AdminPostEventReportScreen></AdminPostEventReportScreen>
          )}
        />
        <Route
          path="/admin-subaccount"
          component={() => <AdminSubaccount></AdminSubaccount>}
        />
        <Route
          path="/membership-confirmation"
          component={() => (
            <AdminMembershipContracts></AdminMembershipContracts>
          )}
        />
        <Route
          path="/organiser-payout"
          component={() => <OrganiserPayout></OrganiserPayout>}
        />
        <Route
          path="/update-events"
          component={() => <AdminUpdatePOSEvent></AdminUpdatePOSEvent>}
        />

        <Route
          path="/operator-post-event-report"
          component={() => (
            <OperatorPostEventFeedbackScreen></OperatorPostEventFeedbackScreen>
          )}
        />
        <Route
          path="/pending-subscriptions"
          component={() => (
            <OperatorPendingSubscription></OperatorPendingSubscription>
          )}
        />

        <Route
          path="/operator-payouts"
          component={() => <OperatorPayouts></OperatorPayouts>}
        />

        <Route
          path="/admin-sales-report-by-event"
          component={() => <AdminSalesReportByEvent></AdminSalesReportByEvent>}
        />
        <Route
          path="/admin-event-menu"
          component={() => <AdminEventMenu></AdminEventMenu>}
        />
        <Route
          path="/admin-pending-supplier-application-screen"
          component={() => (
            <AdminPendingSupplierApplicationsScreen></AdminPendingSupplierApplicationsScreen>
          )}
        />
        <Route
          path="/admin-vendor-catering-applications"
          component={() => (
            <AdminVendorCateringApplications></AdminVendorCateringApplications>
          )}
        />
        <Route
          path="/admin-events-calender-screen"
          component={() => (
            <AdminEventCalenderScreen></AdminEventCalenderScreen>
          )}
        />

        <Route
          path="/checkout"
          component={() => <CheckoutScreen></CheckoutScreen>}
        />
        <Route path="/orders" component={() => <OrdersScreen></OrdersScreen>} />
        <Route path="/profile" component={() => <ProfilePage></ProfilePage>} />

        <Route
          path="/pos-privacy-policy"
          component={() => <PrivacyPolicy></PrivacyPolicy>}
        />
        <Route
          path="/book-a-truck"
          component={() => <BookATruckScreen></BookATruckScreen>}
        />
        <Route
          path="/app-privacy-policy"
          component={() => <AppPrivacyPolicy></AppPrivacyPolicy>}
        />
        <Route
          path="/catering-pack:cuisine"
          component={() => <CateringPacksScreen></CateringPacksScreen>}
        />
        <Route
          path="/catering-pack#vendor_id:id"
          component={() => <CateringPacksScreen></CateringPacksScreen>}
        />
        <Route path="/poll:id" component={() => <PollScreen></PollScreen>} />
        {/* <Route path="/admin" component={() => <AdminScreen></AdminScreen>} /> */}
        <Route
          path="/admin-catering-applications"
          component={() => <AdminScreen></AdminScreen>}
        />
        <Route
          path="/admin-payout"
          component={() => <AdminPayout></AdminPayout>}
        />
        <Route
          path="/admin-orders"
          component={() => (
            <AdminOperatorOrdersScreen></AdminOperatorOrdersScreen>
          )}
        />
        <Route
          path="/quequ-skipper"
          component={() => <QueueSkipperLanding></QueueSkipperLanding>}
        />

        <Route
          path="/operator-sales-dashboard"
          component={() => <OperatorEventSales></OperatorEventSales>}
        />
        <Route
          path="/operator-rego-sales"
          component={() => <OperatorRegoSales></OperatorRegoSales>}
        />
        <Route
          path="/operator-event-expenses"
          component={() => <OperatorEventExpenses></OperatorEventExpenses>}
        />
        <Route
          path="/organiser-expenses"
          component={() => <OrganiserEventExpenses></OrganiserEventExpenses>}
        />
        <Route
          path="/organiser-post-event-report"
          component={() => (
            <EventOrganiserPostEventReport></EventOrganiserPostEventReport>
          )}
        />

        <Route
          path="/organiser-contract"
          component={() => <OrganiserEventContract></OrganiserEventContract>}
        />
        <Route
          path="/organiser-subaccount"
          component={() => <OrganiserSubaccount></OrganiserSubaccount>}
        />
        <Route
          path="/organiser-profile"
          component={() => <OrganiserProfileScreen></OrganiserProfileScreen>}
        />

        <Route
          path="/admin-profit-and-loss-screen-by-event"
          component={() => (
            <AdminProfitAndLossScreenByEvent></AdminProfitAndLossScreenByEvent>
          )}
        />
        <Route
          path="/operator-event-sales"
          component={() => (
            <OperatorSalesByEventScreen></OperatorSalesByEventScreen>
          )}
        />
        <Route
          path="/dashboard"
          component={() => <AdminDashboardScreen></AdminDashboardScreen>}
        />
        <Route
          path="/catering-analytics"
          component={() => <CateringAnalytics></CateringAnalytics>}
        />
        <Route
          path="/admin-edit-event"
          component={() => <AdminEditEventScreen></AdminEditEventScreen>}
        />
        <Route
          path="/compare-vendors"
          component={() => <CompareVendors></CompareVendors>}
        />
        <Route
          path="/preorder-screen/vendor=:vendorId&event=:eventId"
          component={() => <NewScreen></NewScreen>}
        />
        <Route
          path="/admin-create-event"
          component={() => <AdminCreateEventScreen></AdminCreateEventScreen>}
        />
        <Route
          path="/vendor-menu-add"
          component={() => <VendorMenuAddScreen></VendorMenuAddScreen>}
        />
        <Route
          path="/vendor-menu-edit"
          component={() => <VendorMenuEditScreen></VendorMenuEditScreen>}
        />
        <Route
          path="/admin-create-event-stock-order"
          component={() => (
            <AdminCreateEventStockOrder></AdminCreateEventStockOrder>
          )}
        />
        <Route
          path="/admin-orders-overview"
          component={() => <AdminVendorOrdersPage></AdminVendorOrdersPage>}
        />
        <Route
          path="/runner-orders"
          component={() => <RunnerOrdersScreen></RunnerOrdersScreen>}
        />
        <Route
          path="/admin-create-event-utility-order"
          component={() => (
            <AdminCreateEventUtilityOrder></AdminCreateEventUtilityOrder>
          )}
        />
        <Route
          path="/admin-emails-vendor"
          component={() => (
            <AdminEmailsToVendorScreen></AdminEmailsToVendorScreen>
          )}
        />
        <Route
          path="/admin-emails-organiser"
          component={() => (
            <AdminEmailsToEventOrganisersScreen></AdminEmailsToEventOrganisersScreen>
          )}
        />
        <Route
          path="/admin-incident-reports"
          component={() => <IncidentReportScreen></IncidentReportScreen>}
        />
        <Route
          path="/admin-pending-subscription"
          component={() => (
            <PendingSubscriptionApplications></PendingSubscriptionApplications>
          )}
        />

        <Route
          path="/admin-emails-catering-clients"
          component={() => (
            <AdminCateringPresentationsSendToCustomersScreen></AdminCateringPresentationsSendToCustomersScreen>
          )}
        />

        <Route
          path="/admin-pending-menu"
          component={() => <AdminPendingMenuScreen></AdminPendingMenuScreen>}
        />

        <Route
          path="/admin-daily-quotes"
          component={() => <AdminDailyQuotes></AdminDailyQuotes>}
        />

        <Route
          path="/admin-emails-customer"
          component={() => (
            <AdminEmailsToCustomersScreen></AdminEmailsToCustomersScreen>
          )}
        />
        <Route
          path="/payment"
          component={() => <PaymentScreen></PaymentScreen>}
        />
        <Route
          path="/link"
          component={() => <RedirectLinkComponent></RedirectLinkComponent>}
        />
        <Route path="/app" component={() => <AppLinkScreen></AppLinkScreen>} />
        <Route path="/organiser" exact component={() => <OrganiserScreen />} />
        <Route
          path="/supplier-login"
          exact
          component={() => <SupplierScreen />}
        />
        <Route
          path="/organiser-overview"
          component={() => <LandingScreen></LandingScreen>}
        />
        <Route
          path="/organiser-landing"
          component={() => <MainLanding></MainLanding>}
        />
        <Route
          path="/operator-landing"
          component={() => <OperatorLandingScreen></OperatorLandingScreen>}
        />
        <Route
          path="/subscribe"
          component={() => <SubscriptionForm></SubscriptionForm>}
        />
        <Route
          path="/supplier-overview"
          exact
          component={() => <SupplierOverviewScreen />}
        />
        <Route
          path="/admin-txn-report"
          component={() => <AdminTxnReportScreen></AdminTxnReportScreen>}
        />
        <Route
          path="/admin-sales-report"
          component={() => <AdminSalesReportScreen></AdminSalesReportScreen>}
        />
        <Route
          path="/organiser-orders"
          exact
          component={() => <EventOrganiserOrdersScreen />}
        />
        <Route
          path="/cumulative-sales/"
          component={() => <CumulativeSales></CumulativeSales>}
        />

        <Route
          path="/admin-create-event"
          component={() => <AdminCreateEventScreen></AdminCreateEventScreen>}
        />
        <Route
          path="/admin-all-events"
          component={() => <AdminAllEventsScreen></AdminAllEventsScreen>}
        />
        <Route path="/vendor/:id" component={VendorOutline} />
        <Route
          path="/admin-emails-vendor"
          component={() => (
            <AdminEmailsToVendorScreen></AdminEmailsToVendorScreen>
          )}
        />
        {/* <Route path="/admin-menu-edit" component={() => <AdminMenuEditScreen></AdminMenuEditScreen>} /> */}
        <Route
          path="/payment"
          component={() => <PaymentScreen></PaymentScreen>}
        />

        <Route
          path="/admin-catering-analytics"
          component={() => (
            <AdminCateringAnalyticsScreen></AdminCateringAnalyticsScreen>
          )}
        />

        <Route
          path="/admin-site-analytics"
          component={() => <AdminSalesBySiteArea></AdminSalesBySiteArea>}
        />

        <Route
          path="/link"
          component={() => <RedirectLinkComponent></RedirectLinkComponent>}
        />

        <Route path="/organiser" exact component={() => <OrganiserScreen />} />
        <Route
          path="/organiser-analytics"
          exact
          component={() => <EventOrganiserOverviewScreen />}
        />
        <Route
          path="/organiser-orders"
          exact
          component={() => <EventOrganiserOrdersScreen />}
        />

        <Route
          path="/apply-events/home"
          exact
          render={() => {
            const isOperatorLoggedIn = getFromLocalStorage(
              "isOperatorLoggedIn"
            );
            const isOperatorEventCoordinator = getFromLocalStorage(
              "isOperatorEventCoordinator"
            );
            if (
              (isOperatorLoggedIn === null || isOperatorLoggedIn === "false") &&
              (isOperatorEventCoordinator === null ||
                isOperatorEventCoordinator === "false")
            ) {
              window.location.href = "/operator-login";
              return null;
            }
            saveToLocalStorage("activeComponent", "home");
            return <VendorApplyEventScreen />;
          }}
        />

        <Route
          path="/apply-events/events-menu"
          exact
          component={() => <OperatorMenuScreen />}
        />
        <Route
          path="/apply-events/catering-menu"
          exact
          component={() => <OperatorCateringScreen />}
        />
        <Route
          path="/apply-events/documents"
          exact
          component={() => <OperatorDocuments />}
        />
        <Route
          path="/apply-events/applied-eois"
          exact
          component={() => <VendorEOI />}
          // render={() => {
          //   const isOperatorLoggedIn = getFromLocalStorage(
          //     "isOperatorLoggedIn"
          //   );
          //   const isOperatorEventCoordinator = getFromLocalStorage(
          //     "isOperatorEventCoordinator"
          //   );
          //   if (
          //     (isOperatorLoggedIn === null || isOperatorLoggedIn === "false") &&
          //     (isOperatorEventCoordinator === null ||
          //       isOperatorEventCoordinator === "false")
          //   ) {
          //     window.location.href = "/operator-login";
          //     return null;
          //   }
          //   saveToLocalStorage("activeComponent", "eoi");
          //   return <VendorApplyEventScreen />;
          // }}
        />

        <Route path="/apply-events/faqs" exact component={() => <FAQPage />} />
        <Route
          path="/truck:id"
          component={() => <TruckAnalytics></TruckAnalytics>}
        />
        <Route
          path="/operator-login"
          exact
          component={() => <VendorRegisterScreen />}
        />
        <Route path="/admin" exact component={() => <AdminLoginScreen />} />
        <Route path="/queueskipper" exact component={() => <KioskLanding />} />
        <Route
          path="/contract-generator"
          component={() => <Contract></Contract>}
        />
        <Route path="/stock" component={() => <StockScreen></StockScreen>} />
        <Route
          path="/request-stock-quote"
          component={() => (
            <OperatorRequestStockQuote></OperatorRequestStockQuote>
          )}
        />

        <Route
          path="/stock-orders"
          component={() => <StockOrderScreen></StockOrderScreen>}
        />
        <Route
          path="/stock-order-edit:id"
          component={() => <StockOrderEditScreen></StockOrderEditScreen>}
        />
        <Route
          path="/stock-admin"
          component={() => <StockAdminScreen></StockAdminScreen>}
        />
        <Route
          path="/stock-transfer-requests"
          component={() => (
            <StockTransferRequestScreen></StockTransferRequestScreen>
          )}
        />
        <Route
          path="/stock-upload"
          component={() => <StockUpload></StockUpload>}
        />
        <Route
          path="/stock-quotes-pending"
          component={() => (
            <StockPortalPendingStockQuote></StockPortalPendingStockQuote>
          )}
        />
        <Route
          path="/stock-supplier-catelog"
          component={() => (
            <StockPortalSupplierCatelogueList></StockPortalSupplierCatelogueList>
          )}
        />
        <Route
          path="/stock-admin-approved-orders"
          component={() => (
            <StockAdminApprovedOrdersScreen></StockAdminApprovedOrdersScreen>
          )}
        />
        <Route
          path="/stock-admin-order-edit:id"
          component={() => (
            <StockAdminOrderEditScreen></StockAdminOrderEditScreen>
          )}
        />
        <Route
          path="/admin-pending-menu"
          component={() => <AdminPendingMenuScreen></AdminPendingMenuScreen>}
        />
        <Route
          path="/stock-portal-past-quotes"
          component={() => (
            <StockPortalApprovedOrdersScreenQuotes></StockPortalApprovedOrdersScreenQuotes>
          )}
        />
        <Route
          path="/stock-admin-stocklist"
          component={() => (
            <StockAdminStockListScreen></StockAdminStockListScreen>
          )}
        />
        <Route
          path="/stock-catelogue-supplier-list"
          component={() => <StockCatelogueList></StockCatelogueList>}
        />
        <Route
          path="/stock-admin-stockbundle-add"
          component={() => (
            <StockAdminAddStockBundleScreen></StockAdminAddStockBundleScreen>
          )}
        />
        <Route
          path="/stockbundle-edit"
          component={() => (
            <StockAdminEditStockBundleScreen></StockAdminEditStockBundleScreen>
          )}
        />
        <Route
          path="/stock-admin-bundle"
          component={() => (
            <StockAdminUsageReportScreen></StockAdminUsageReportScreen>
          )}
        />
        <Route
          path="/utility-purchases"
          component={() => <EventUtilityPurchases></EventUtilityPurchases>}
        />
        <Route path="/stock" component={() => <StockScreen></StockScreen>} />
        <Route
          path="/stock-orders"
          component={() => <StockOrderScreen></StockOrderScreen>}
        />
        <Route
          path="/stock-order-edit:id"
          component={() => <StockOrderEditScreen></StockOrderEditScreen>}
        />
        <Route
          path="/stock-admin"
          component={() => <StockAdminScreen></StockAdminScreen>}
        />
        <Route
          path="/stock-admin-order-edit:id"
          component={() => (
            <StockAdminOrderEditScreen></StockAdminOrderEditScreen>
          )}
        />
        <Route
          path="/stock-admin-stocklist"
          component={() => (
            <StockAdminStockListScreen></StockAdminStockListScreen>
          )}
        />
        <Route
          path="/operator-contracts"
          component={() => <OperatorContractScreen></OperatorContractScreen>}
        />
        <Route
          path="/stock-admin-stockbundle-add"
          component={() => (
            <StockAdminAddStockBundleScreen></StockAdminAddStockBundleScreen>
          )}
        />
        <Route
          path="/stockbundle-edit"
          component={() => (
            <StockAdminEditStockBundleScreen></StockAdminEditStockBundleScreen>
          )}
        />
        <Route
          path="/stock-admin-bundle"
          component={() => (
            <StockAdminUsageReportScreen></StockAdminUsageReportScreen>
          )}
        />
        <Route
          path="/admin-external-operators"
          component={() => (
            <AdminExternalOperatorsDashboardScreen></AdminExternalOperatorsDashboardScreen>
          )}
        />
        <Route
          path="/admin-create-runsheets"
          component={() => <AdminCreateRunsheets></AdminCreateRunsheets>}
        />
        <Route
          path="/admin-edit-runsheets"
          component={() => <AdminEditRunsheets></AdminEditRunsheets>}
        />
        <Route
          path="/view-pending-contracts"
          component={() => (
            <VendorViewPendingContracts></VendorViewPendingContracts>
          )}
        />
        <Route
          path="/operator-subaccount"
          component={() => <OperatorSubaccount></OperatorSubaccount>}
        />
        <Route
          path="/operator-sots"
          component={() => (
            <VendorStatementOfTradesScreen></VendorStatementOfTradesScreen>
          )}
        />
        <Route
          path="/admin-sots"
          component={() => (
            <AdminEventStatementOfTradesScreen></AdminEventStatementOfTradesScreen>
          )}
        />
        <Route
          path="/admin-pos-kiosk-codes"
          component={() => (
            <AdminPOSKioskCodesScreen></AdminPOSKioskCodesScreen>
          )}
        />

        <Route
          path="/operator-post-event-feedback"
          component={() => (
            <OperatorPostEventFeedbackScreen></OperatorPostEventFeedbackScreen>
          )}
        />

        <Route
          path="/operator-run-sheets"
          component={() => <OperatorRunSheets></OperatorRunSheets>}
        />
        <Route
          path="/operator-add-menu:id"
          component={() => <OperatorAddMenu></OperatorAddMenu>}
        />
        <Route
          path="/operator-edit-menu:id"
          component={() => <OperatorEditMenu></OperatorEditMenu>}
        />
        <Route
          path="/admin-stock-analytics"
          component={() => <AdminStockAnalytics></AdminStockAnalytics>}
        />
        <Route
          path="/admin-catering-multiple"
          component={() => (
            <AdminCateringScreenMultiplePacks></AdminCateringScreenMultiplePacks>
          )}
        />
        <Route path="*" component={() => <NoMatch></NoMatch>} />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
