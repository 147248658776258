import { Button } from "@material-ui/core";
import React, { useRef } from "react";
import * as XLSX from "xlsx";
import PublishIcon from "@material-ui/icons/Publish";
// import "./style.css";

function FileInput({ setData, setHead, validate }) {
  const inputREf = useRef();
  const [selectedFile, setSelectedFile] = React.useState("");

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    setSelectedFile(file?.name ?? "");

    reader.onload = (event) => {
      const workbook = XLSX.read(event.target.result, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const sheetData = XLSX.utils.sheet_to_json(sheet);
      const header = XLSX.utils.sheet_to_json(sheet, {
        header: 1,
        range: 0,
      })[0];

      setHead(header);
      setData(sheetData);
      validate(sheetData, header);
    };

    reader.readAsBinaryString(file);
  };

  return (
    <div>
      <div>
        <input
          ref={inputREf}
          type="file"
          id="file-input"
          onChange={handleFileUpload}
          style={{ display: "none" }}
        />
        <label htmlFor="file-input" className="input-button">
          <Button
            size="large"
            onClick={() => {
              inputREf.current.click();
            }}
            variant="contained"
            color="primary"
            style={{ backgroundColor: "#2859a5" }}
          >
            <PublishIcon />
            Upload CSV File
          </Button>
        </label>
        {selectedFile ? (
          <div>
            <b>Selected file:</b> {selectedFile}
          </div>
        ) : (
          <div>
            <b style={{ color: "#F85A4F" }}>
              * Please upload a CSV file. Maximum file size: 10MB
            </b>
          </div>
        )}
      </div>
    </div>
  );
}

export default FileInput;
