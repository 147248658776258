import React, { useState } from "react";

import Footer from "../../../components/UI/Footer";
import CsvDownloadButton from "react-json-to-csv";
import * as XLSX from "xlsx";
import "./style.css";
import { Typography, Container, Paper, Button } from "@material-ui/core";
import ErrorFallbackComponent from "../../../screens/ErrorFallBackScreen";
import * as Sentry from "@sentry/react";
import { apiPaths } from "../../../services/apiPath";
import AdminNavBar from "../../../components/UI/AdminNavBar";
import { deleteFromLocalStorage, getFromLocalStorage } from "../../../store";
import config from "../../../services/apiConfig";
import { AWS_URL } from "../../../assets/Constants";
import Box from "@mui/material/Box";
import DataGrid from "./DataGrid";
import FileInput from "./FileInput";
import DoneIcon from "@material-ui/icons/Done";
import SendIcon from "@material-ui/icons/Send";
import ConfirmationDialog from "./ConfirmationDialog";

const ImportEventsScreen = () => {
  const [isSuperAdminLoggedIn, setIsSuperAdminLoggedIn] = useState("false");
  const [isOperatorLoggedIn, setIsOperatorLoggedIn] = useState("false");
  const [isOrganiserLoggedIn, setIsOrganiserLoggedIn] = useState(() =>
    getFromLocalStorage("isOrganiserLoggedIn") != "undefined" &&
    getFromLocalStorage("isOrganiserLoggedIn") != "" &&
    getFromLocalStorage("isOrganiserLoggedIn") != null
      ? getFromLocalStorage("isOrganiserLoggedIn")
      : "false"
  );
  const [vendorName, setVendorName] = useState(() =>
    getFromLocalStorage("vendorName") != "undefined" &&
    getFromLocalStorage("vendorName") != "" &&
    getFromLocalStorage("vendorName") != null
      ? getFromLocalStorage("vendorName")
      : ""
  );

  const [data, setData] = useState([]);
  const [head, setHead] = useState([]);
  const [errors, setErrors] = useState([]);

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const logout = () => {
    deleteFromLocalStorage("isOperatorLoggedIn", "false");
    deleteFromLocalStorage("isOrganiserLoggedIn", "false");
    deleteFromLocalStorage("vendorName", "");
    deleteFromLocalStorage("email", "");
    deleteFromLocalStorage("password", "");
    deleteFromLocalStorage("selectedEventId", "0");

    setIsSuperAdminLoggedIn("false");
    setIsOrganiserLoggedIn("false");
    setIsOperatorLoggedIn("false");

    window.location.replace("/events");
  };

  const validateData = (data, head) => {
    const newErrors = [];
    data.forEach((row, index) => {
      const missingColumns = [];

      head.forEach((column) => {
        if (!row[column]) {
          missingColumns.push(`"${column}"`);
        }
      });
      if (missingColumns.length > 0) {
        newErrors.push(
          `Row ${index + 1}: Missing values in ${missingColumns.join(
            ", "
          )} columns`
        );
      }
    });
    setErrors(newErrors);
  };

  const handleDataValidation = () => {
    validateData(data, head);
  };

  const handleOpenDialog = () => {
    setShowConfirmationDialog(true);
  };

  const handleCloseDialog = () => {
    setShowConfirmationDialog(false);
  };

  const handleSubmitData = () => {
    console.log(JSON.stringify(data));

    return;

    const url = config.devUrl + apiPaths.updateEventsDirectory;
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "",
          data: data,
        },
      },
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((response) => {
          if (response.success == true) {
            alert("Apply successed!");
            this.setState({
              confirmVendors: [],
            });
            this.getTargetedEvents(this.state.selectEvent.id);
            this.getTargetedAcceptedEvents(this.state.selectEvent.id);
          } else {
            alert("The application is failed. Please try later");
            this.setState({
              confirmVendors: [],
            });
            this.getTargetedEvents(this.state.selectEvent.id);
          }
        });
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
      alert("The application is failed. Please try later");
      this.setState({ confirmVendors: [] });
    } finally {
      handleCloseDialog();
    }
  };

  return (
    <>
      <div>
        <AdminNavBar
          style={{ margin: "auto" }}
          isSuperAdminLoggedIn={isSuperAdminLoggedIn}
          isOrganiserLoggedIn={isOrganiserLoggedIn}
          logout={logout}
          vendorName={vendorName}
        ></AdminNavBar>

        <Container style={{ minHeight: "600px" }}>
          <Paper
            elevation={3}
            square
            style={{
              margin: "auto",
              marginTop: 20,
              padding: "2rem",
              width: "100%",
            }}
          >
            <Box className="header-container">
              <Typography
                variant="h3"
                style={{ color: "#2859a5", fontWeight: 500 }}
              >
                CSV Import and Send
              </Typography>
              <Typography variant="caption">
                Easily import your CSV files, verify their data, and send them
                securely to our API.
              </Typography>
            </Box>
            <Box>
              <FileInput
                setData={setData}
                setHead={setHead}
                validate={validateData}
              />

              <DataGrid
                data={data}
                head={head}
                setData={setData}
                validate={handleDataValidation}
              />
            </Box>
            {data && data.length ? (
              <Box className={"validation-container"}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "6px",
                  }}
                >
                  <Button
                    onClick={handleDataValidation}
                    variant="contained"
                    color="primary"
                  >
                    <DoneIcon /> Validate Data
                  </Button>
                  {errors && errors.length ? null : (
                    <p style={{ color: "#5cb85c" }}>All Data is Valid.</p>
                  )}
                </div>
                {errors && errors.length ? (
                  <ul style={{ listStyleType: "initial" }}>
                    {errors.map((error) => (
                      <li key={error}>{error}</li>
                    ))}
                  </ul>
                ) : null}
              </Box>
            ) : null}
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "1rem",
              }}
            >
              <Button
                disabled={(errors && errors.length) || !data || !data.length}
                size="large"
                variant="contained"
                onClick={handleOpenDialog}
                style={{
                  backgroundColor:
                    (errors && errors.length) || !data || !data.length
                      ? "#80c280"
                      : "#5cb85c",
                  color: "white",
                }}
                startIcon={<SendIcon />}
              >
                Send Data
              </Button>
            </Box>
          </Paper>
          <ConfirmationDialog
            open={showConfirmationDialog}
            handleClose={handleCloseDialog}
            handleSubmit={handleSubmitData}
          />
        </Container>
        <Footer />
      </div>
    </>
  );
};

export default Sentry.withErrorBoundary(ImportEventsScreen, {
  fallback: <ErrorFallbackComponent />,
});
