import React from 'react';
import {
  Container,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Grid,
  Card,
  CardContent,
  Typography,
} from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { green, red } from '@mui/material/colors';
import { getFromLocalStorage, saveToLocalStorage } from '../../store';
import * as Sentry from '@sentry/react';
import config from '../../services/apiConfig';
import { apiPaths } from '../../services/apiPath';
import AdminNavBar from '../../components/UI/AdminNavBar/index.js';

class AdminStockAnalytics extends React.Component {
  state = {
    isSuperAdminLoggedIn:
      getFromLocalStorage('isSuperAdminLoggedIn') !== 'undefined' &&
      getFromLocalStorage('isSuperAdminLoggedIn') !== '' &&
      getFromLocalStorage('isSuperAdminLoggedIn') !== null
        ? getFromLocalStorage('isSuperAdminLoggedIn')
        : 'false',
    isStockAdminLoggedIn:
      getFromLocalStorage('isStockAdminLoggedIn') !== 'undefined' &&
      getFromLocalStorage('isStockAdminLoggedIn') !== '' &&
      getFromLocalStorage('isStockAdminLoggedIn') !== null
        ? getFromLocalStorage('isStockAdminLoggedIn')
        : 'false',
    email:
      getFromLocalStorage('email') !== 'undefined' &&
      getFromLocalStorage('email') !== '' &&
      getFromLocalStorage('email') !== null
        ? getFromLocalStorage('email')
        : '',
    password:
      getFromLocalStorage('password') !== 'undefined' &&
      getFromLocalStorage('password') !== '' &&
      getFromLocalStorage('password') !== null
        ? getFromLocalStorage('password')
        : '',
    selectedEventId:
      getFromLocalStorage('selectedEventId') !== 'undefined' &&
      getFromLocalStorage('selectedEventId') !== '' &&
      getFromLocalStorage('selectedEventId') !== null
        ? getFromLocalStorage('selectedEventId')
        : '0',
    selectedVendorId: '0',
    selectedDate: '-1',
    events: [],
    vendors: [],
    dateRange: [],
    stock_order_data_obj: {},
    total_sales: 0,
    total_waste_percentage: 0,
    total_stock_cost_percentage: 0,
    total_percentage_of_stock_used: 0,
    total_cost_for_stock_used: 0,
    total_waste_sum_for_event: 0,
    system_generated_stock_used_percentage: 0,
    system_generated_stock_cost: 0,
    isLoading: false,
  };

  componentDidMount() {
    const { isSuperAdminLoggedIn, isStockAdminLoggedIn } = this.state;
    if (isSuperAdminLoggedIn === 'true' || isStockAdminLoggedIn === 'true') {
      this.getAllEvents();
    } else {
      window.location.href = '/admin';
    }
  }

  getAllEvents = () => {
    const { email, password } = this.state;
    this.setState({ isLoading: true });
    const url = `${config.BASE_URI}${apiPaths.adminData}`;
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: 'get_all_events',
          email: email,
          password: password,
        },
      },
    });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success === true) {
          this.setState({
            events: dataResponse.events.sort((a, b) =>
              new Date(b.start_date) - new Date(a.start_date)
            ),
          });
        }
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };

  getVendorsInEvent = () => {
    const { email, password, selectedEventId } = this.state;
    this.setState({ isLoading: true });

    const url = `${config.BASE_URI}${apiPaths.adminData}`;
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: 'get_vendors_in_event',
          email: email,
          password: password,
          event: selectedEventId,
        },
      },
    });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success === true) {
          this.setState(
            {
              vendors: dataResponse.vendors,
              selectedVendorId: '0',
            },
            () => {
              this.getDateRange();
              this.getOrders();
            }
          );
        }
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };

  getDateRange = () => {
    const { events, selectedEventId } = this.state;
    const selectedEvent = events.find((event) => event.id === selectedEventId);
    if (selectedEvent) {
      const startDate = new Date(selectedEvent.start_date);
      const endDate = new Date(selectedEvent.end_date);
      const dates = [];

      let currDate = startDate;
      while (currDate <= endDate) {
        dates.push(currDate.toISOString().split('T')[0]);
        currDate.setDate(currDate.getDate() + 1);
      }

      this.setState({ dateRange: dates });
    }
  };

  getOrders = () => {
    const { email, password, selectedEventId, selectedVendorId, selectedDate } = this.state;
    this.setState({ isLoading: true });

    const url = `${config.BASE_URI}${apiPaths.adminData}`;
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: 'get_stock_analytics',
          email: email,
          password: password,
          event: selectedEventId,
          vendor: selectedVendorId,
          selected_date: selectedDate,
        },
      },
    });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success === true) {
          this.calculateMetrics(dataResponse);
        }
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };

  calculateMetrics = (dataResponse) => {
    const total_sales = dataResponse.total_sales || 0;
    const total_waste_cost = dataResponse.total_waste_sum || 0;
    const total_cost_for_stock_used = dataResponse.total_cost_for_stock_used || 0;
    const stock_orders = dataResponse.stock_order_data_obj || {};

    const total_waste_percentage = total_sales ? ((total_waste_cost / total_sales) * 100).toFixed(2) : 0;
    const total_stock_cost_percentage = total_sales ? ((dataResponse.stock_orders.total__sum / total_sales) * 100).toFixed(2) : 0;
    const total_percentage_of_stock_used = total_sales ? ((total_cost_for_stock_used / total_sales) * 100).toFixed(2) : 0;

    let system_generated_stock_used_total = 0;
let system_generated_stock_cost_total = 0;

Object.keys(stock_orders).forEach((productName) => {
  const product = stock_orders[productName];

  // Ensure that ingredient_usage exists and total_used is a valid number
  if (product.ingredient_usage?.total_used && !isNaN(product.ingredient_usage.total_used)) {
    let systemGeneratedCost;

    if (product.ingredient_usage.weight > 0) {
      systemGeneratedCost = product.ingredient_usage.price / product.ingredient_usage.weight * product.ingredient_usage.total_used;
    } else {
      systemGeneratedCost = parseFloat(product.ingredient_usage.price) * parseFloat(product.ingredient_usage.total_used);
    }

    // Accumulate the total system-generated stock cost
    system_generated_stock_cost_total += systemGeneratedCost;
  }
});
    const system_generated_stock_used_percentage = total_sales
      ? ((system_generated_stock_cost_total / total_sales) * 100).toFixed(2)
      : 0;
    console.log("system stock cost",system_generated_stock_cost_total)

    this.setState({
      stock_order_data_obj: stock_orders,
      total_sales: total_sales,
      total_waste_percentage: total_waste_percentage,
      total_stock_cost_percentage: total_stock_cost_percentage,
      total_percentage_of_stock_used: total_percentage_of_stock_used,
      total_cost_for_stock_used: total_cost_for_stock_used,
      total_waste_sum_for_event: total_waste_cost,
      system_generated_stock_used_percentage: system_generated_stock_used_percentage,
      system_generated_stock_cost: system_generated_stock_cost_total,
    });
  };

  handleEventChange = (event) => {
    this.setState({ selectedEventId: event.target.value }, () => {
      saveToLocalStorage('selectedEventId', event.target.value);
      this.getVendorsInEvent();
    });
  };

  handleVendorChange = (event) => {
    this.setState({ selectedVendorId: event.target.value }, () => {
      saveToLocalStorage('selectedVendorId', event.target.value);
      this.getOrders();
    });
  };

  handleDateChange = (event) => {
    this.setState({ selectedDate: event.target.value }, () => {
      this.getOrders();
    });
  };

  renderVariance(variance) {
    if (variance > 0) {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', color: green[500] }}>
          <ArrowDropUpIcon />
          <Typography variant="body2">{variance.toFixed(2)}</Typography>
        </Box>
      );
    } else if (variance < 0) {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', color: red[500] }}>
          <ArrowDropDownIcon />
          <Typography variant="body2">{variance.toFixed(2)}</Typography>
        </Box>
      );
    } else {
      return <Typography variant="body2">{variance.toFixed(2)}</Typography>;
    }
  }

  renderMetrics() {
    const {
      total_sales,
      total_waste_percentage,
      total_stock_cost_percentage,
      total_percentage_of_stock_used,
      total_cost_for_stock_used,
      total_waste_sum_for_event,
      system_generated_stock_used_percentage,
      system_generated_stock_cost,
    } = this.state;

    return (
      <Grid container spacing={3} sx={{ my: 2 }}>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6">Total Sales</Typography>
              <Typography variant="h4">${total_sales.toFixed(2)}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6">Total Waste Percentage</Typography>
              <Typography variant="h4">{total_waste_percentage}%</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6">Stock Cost Percentage</Typography>
              <Typography variant="h4">{total_stock_cost_percentage}%</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6">Stock Used Percentage</Typography>
              <Typography variant="h4">{total_percentage_of_stock_used}%</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6">Total Cost for Stock Used</Typography>
              <Typography variant="h4">${total_cost_for_stock_used.toFixed(2)}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6">Total Waste Cost</Typography>
              <Typography variant="h4">${total_waste_sum_for_event.toFixed(2)}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6">System Generated Stock Used %</Typography>
              <Typography variant="h4">{system_generated_stock_used_percentage}%</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6">System Generated Stock Cost</Typography>
              <Typography variant="h4">${system_generated_stock_cost.toFixed(2)}</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }

  renderTableData() {
    const { stock_order_data_obj, total_sales } = this.state;

    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="stock order table">
          <TableHead>
            <TableRow>
              <TableCell>Product Name</TableCell>
              <TableCell align="right">Price Per Unit</TableCell>
              <TableCell align="right">Stock Ordered</TableCell>
              <TableCell align="right">Closing Stock Qty</TableCell>
              <TableCell align="right">Waste Stock Qty</TableCell>
              <TableCell align="right">Stock Used</TableCell>
              <TableCell align="right">System Calculated Usage</TableCell>
              <TableCell align="right">Stock Left</TableCell>
              <TableCell align="right">System Recommended Stock Left</TableCell>
              <TableCell align="right">Total Cost For Stock Used</TableCell>
              <TableCell align="right">% of Stock Used Compared to Total Sales</TableCell>
              <TableCell align="right">Variance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(stock_order_data_obj).map((productName) => {
              const product = stock_order_data_obj[productName];
              const systemRecommendedStockLeft = product.system_recommended_stock_left;
              const stockLeftUnit = systemRecommendedStockLeft?.unit === 'Grams' ? 'unit' : systemRecommendedStockLeft?.unit;

              const variance = product.stock_left - (product.ingredient_usage?.total_used ?? 0);

              return (
                <TableRow key={productName}>
                  <TableCell component="th" scope="row">
                    {product.name}
                  </TableCell>
                  <TableCell align="right">{product.price}</TableCell>
                  <TableCell align="right">{product.stock_ordered}</TableCell>
                  <TableCell align="right">{product.closing_stock_qty}</TableCell>
                  <TableCell align="right">{product.wasted_stock}</TableCell>
                  <TableCell align="right">{product.stock_left}</TableCell>
                  <TableCell align="right">
                    {product.ingredient_usage?.total_used ?? 'N/A'}{' '}
                    {product.ingredient_usage?.unit === 'Grams' ? 'unit' : product.ingredient_usage?.unit ?? ''}
                  </TableCell>
                  <TableCell align="right">{product.remaining_stock}</TableCell>
                  <TableCell align="right">
                    {systemRecommendedStockLeft?.amount ?? 'N/A'}{' '}
                    {stockLeftUnit ?? ''}
                  </TableCell>
                  <TableCell align="right">
                    {Number(product.price * product.stock_left).toFixed(2)}
                  </TableCell>
                  <TableCell align="right">
                    %
                    {Number(
                      ((product.price * product.stock_left) / total_sales) * 100
                    ).toFixed(2)}
                  </TableCell>
                  <TableCell align="right">{this.renderVariance(variance)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  renderHome() {
    const { isLoading, events, vendors, dateRange, selectedEventId, selectedVendorId, selectedDate } = this.state;

    return (
      <Container maxWidth="lg">
        <Box sx={{ my: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Stock Order Data
          </Typography>

          <FormControl fullWidth sx={{ my: 2 }}>
            <InputLabel id="select-event-label">Select Event</InputLabel>
            <Select
              labelId="select-event-label"
              id="select-event"
              value={selectedEventId}
              label="Select Event"
              onChange={this.handleEventChange}
            >
              <MenuItem value="0">Select Event</MenuItem>
              {events.map((event) => (
                <MenuItem key={event.id} value={event.id}>
                  {event.name} - {event.start_date}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ my: 2 }}>
            <InputLabel id="select-vendor-label">Select Vendor</InputLabel>
            <Select
              labelId="select-vendor-label"
              id="select-vendor"
              value={selectedVendorId}
              label="Select Vendor"
              onChange={this.handleVendorChange}
            >
              <MenuItem value="0">All Vendors</MenuItem>
              {vendors.map((vendor) => (
                <MenuItem key={vendor.user_ptr_id} value={vendor.user_ptr_id}>
                  {vendor.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ my: 2 }}>
            <InputLabel id="select-date-label">Select Date</InputLabel>
            <Select
              labelId="select-date-label"
              id="select-date"
              value={selectedDate}
              label="Select Date"
              onChange={this.handleDateChange}
            >
              <MenuItem value="-1">All Days</MenuItem>
              {dateRange.map((date) => (
                <MenuItem key={date} value={date}>
                  {new Date(date).toDateString()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {this.renderMetrics()}

          {isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
              <CircularProgress />
            </Box>
          ) : (
            this.renderTableData()
          )}
        </Box>
      </Container>
    );
  }

  render() {
    const { isSuperAdminLoggedIn, isStockAdminLoggedIn } = this.state;

    return (
      <div>
        <AdminNavBar
          isSuperAdminLoggedIn={isSuperAdminLoggedIn}
          isStockAdminLoggedIn={isStockAdminLoggedIn}
          logout={this.logout}
        />
        {isSuperAdminLoggedIn || isStockAdminLoggedIn ? (
          this.renderHome()
        ) : (
          (window.location.href = '/admin')
        )}
      </div>
    );
  }
}

export default Sentry.withErrorBoundary(AdminStockAnalytics, {
  fallback: <div>An error occurred</div>,
});
