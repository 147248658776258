import React, { useEffect, useState } from "react";
import { Modal, Accordion, Button } from "react-bootstrap";
import "../style.css";
import StallDetails from "./ModalComponent/StallDetails";
import StallRequirements from "./ModalComponent/StallRequirements";
import { useFormik } from "formik";
import WaterRequirements from "./ModalComponent/WaterRequirements";
import PowerRequirements from "./ModalComponent/PowerRequirements";
import ElectricalAppliance from "./ModalComponent/ElectricalAppliance";
import MenuItems from "./ModalComponent/MenuItems";
import OtherComments from "./ModalComponent/OtherComments";
import OtherUploads from "./ModalComponent/OtherUploads";
import config from "../../../services/apiConfig";
import { apiPaths } from "../../../services/apiPath";
import { Redirect } from "react-router-dom";
import {
  getFromLocalStorage,
  deleteFromLocalStorage,
  saveToLocalStorage,
} from "../../../store";
import * as Sentry from "@sentry/react";
import Loader from "../../../components/UI/Loader";
const url = config.BASE_URI + apiPaths.creatingVendors;

const validate = (values) => {
  const errors = {};
  if (!values.logoFile || values.logoFile.length === 0) {
    errors.logoFile = "Required";
  }
  if (!values.stallName) {
    errors.stallName = "Required";
  }
  if (!values.city) {
    errors.stallName = "Required";
  }
  if (!values.email) {
    errors.stallName = "Required";
  }
  if (!values.eventPhotos || values.eventPhotos.length === 0) {
    errors.eventPhotos = "Required";
  }
  if (!values.contactNumber || values.contactNumber.length === 0) {
    errors.contactNumber = "Required";
  }

  if (!values.stallDescription) {
    errors.stallDescription = "Required";
  }

  if (!values.siteLayoutFile || values.siteLayoutFile.length === 0) {
    errors.siteLayoutFile = "Required";
  }

  // if (!values.truckType) {
  //   errors.truckType = "Required";
  // }

  if (!values.vansWidth) {
    errors.vansWidth = "Required";
  }

  if (!values.vansDepth) {
    errors.vansDepth = "Required";
  }

  if (!values.vansHeight) {
    errors.vansHeight = "Required";
  }

  if (!values.exitDoorPos) {
    errors.exitDoorPos = "Required";
  }

  if (!values.coolRoomWidth && values.isCoolerRequired) {
    errors.coolRoomWidth = "Required";
  }

  if (!values.coolRoomDepth && values.isCoolerRequired) {
    errors.coolRoomDepth = "Required";
  }

  if (!values.fireSource) {
    errors.fireSource = "Required";
  }

  if (!values.waterAccess) {
    errors.waterAccess = "Required";
  }

  if (
    values.numberOf10Amp &&
    !/^[0-9]\d*(\.\d+)?$/i.test(values.numberOf10Amp)
  ) {
    errors.numberOf10Amp = "Invalid Number";
  }

  if (
    values.numberOf20Amp &&
    !/^[0-9]\d*(\.\d+)?$/i.test(values.numberOf20Amp)
  ) {
    errors.numberOf20Amp = "Invalid Number";
  }

  if (
    values.numberOf15Amp &&
    !/^[0-9]\d*(\.\d+)?$/i.test(values.numberOf15Amp)
  ) {
    errors.numberOf15Amp = "Invalid Number";
  }

  if (
    values.numberOf32Amp &&
    !/^[0-9]\d*(\.\d+)?$/i.test(values.numberOf32Amp)
  ) {
    errors.numberOf32Amp = "Invalid Number";
  }

  let electricalAppliancesList = values.electricalAppliances;
  // if (electricalAppliancesList.length === 0) {
  //   errors.noElectricalAppliancesList = "Required";
  // }

  if (values.menu.length === 0) {
    errors.noMenu = "Required";
  }

  if (!values.foodReg) {
    errors.foodReg = "Required";
  }

  if (values.fireSource === "gas" && !values.gasCert) {
    errors.gasCert = "Required";
  }

  for (let i = 0; i < electricalAppliancesList.length; i++) {
    if (!errors.electricalAppliances) {
      errors.electricalAppliances = [];
    }
    errors.electricalAppliances[i] = errors.electricalAppliances[i]
      ? errors.electricalAppliances[i]
      : {};
    for (let key in electricalAppliancesList[i]) {
      if (!electricalAppliancesList[i][key] && key !== "idx") {
        errors.electricalAppliances[i][key] = "Required";
      } else if (
        key == "watts" &&
        electricalAppliancesList[i][key] &&
        !/^[1-9]\d*(\.\d+)?$/i.test(values.watts)
      ) {
        errors.electricalAppliances[i][key] = "Invalid Number";
      }
    }
  }

  let menuList = values.menu;
  // for (let i = 0; i < menuList.length; i++) {
  //   if (!errors.menu) {
  //     errors.menu = [];
  //   }
  //   errors.menu[i] = errors.menu[i] ? errors.menu[i] : {};
  //   // for (let key in menuList[i]) {
  //   //   if (!menuList[i][key] && key !== "idx") {
  //   //     errors.menu[i][key] = "Required";
  //   //    }
  //   //   //  else if (
  //   //   //   key == "prices" &&
  //   //   //   electricalAppliancesList[i][key] &&
  //   //   //   !/^[1-9]\d*(\.\d+)?$/i.test(values.prices)
  //   //   // ) {
  //   //   //   errors.electricalAppliances[i][key] = "Invalid Number";
  //   //   // }
  //   // }
  // }

  if (!values.electricalCert) {
    errors.electricalCert = "Required";
  }

  if (!values.liabilityCert) {
    errors.liabilityCert = "Required";
  }

  return errors;
};

const RegModal = (props) => {
  const { show, handleClose, itemDetail, viewOnly, operator } = props;
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      logoFile: itemDetail?.logo || [],
      contactNumber: "",
      email: itemDetail?.email || "",
      city: itemDetail?.hometown || "",
      delivery: itemDetail?.delivery || false,
      delivery_fee: itemDetail?.delivery_fee || 0,
      minimunSpend: itemDetail?.food_truck_street_min_spend || 0,
      stallName: itemDetail?.title || "",
      abnNumber: itemDetail?.abn_number || "",
      eventPhotos: [],
      stallDescription: itemDetail?.bio || "",
      siteLayoutFile: [],
      vansWidth: itemDetail?.truck_width || 0,
      vansDepth: itemDetail?.truck_depth || 0,
      vansHeight: itemDetail?.truck_height || 0,
      exitDoorPos: itemDetail?.truck_exit || "",
      isCoolerRequired: itemDetail?.is_cooler_required || "",
      coolRoomWidth: itemDetail?.cooler_width || 0,
      coolRoomDepth: itemDetail?.cooler_width || 0,
      fireSource: itemDetail?.fire_source || "",
      waterAccess: itemDetail?.water_access || "",
      numberOf10Amp: itemDetail?.number_of_10_amp || 0,
      numberOf15Amp: itemDetail?.number_of_15_amp || 0,
      numberOf20Amp: itemDetail?.number_of_20_amp || 0,
      numberOf32Amp: itemDetail?.number_of_32_amp || 0,
      electricalAppliances: [],
      menu: [],
      electricalCert: [],
      foodReg: itemDetail?.foodReg || [],
      isPricesAlteredAllowed: itemDetail?.is_prices_altered_allowed || "",
      otherComments: itemDetail?.other_comments || "",
      liabilityCert: itemDetail?.liability_certificate || [],
      gasCert: itemDetail?.gas_certificate || [],
      otherUploads: itemDetail?.otherUploads || [],
    },
    initialTouched: {
      contactNumber: false,
      email: false,
      city: false,
      delivery: false,
      delivery_fee: false,
      logoFile: false,
      stallName: false,
      eventPhotos: false,
      stallDescription: false,
      siteLayoutFile: false,
      vansWidth: false,
      vansDepth: false,
      vansHeight: false,
      exitDoorPos: false,
      isCoolerRequired: false,
      coolRoomWidth: false,
      coolRoomDepth: false,
      fireSource: false,
      waterAccess: false,
      electricalAppliances: [
        {
          idx: false,
          description: false,
          watts: false,
          testDate: false,
          dueDate: false,
        },
      ],
      menu: [
        {
          idx: false,
          cuisineType: false,
          productName: false,
          description: false,
          itemCategory: false,
          dietary: false,
          prices: false,
        },
      ],
      electricalCert: false,
      foodReg: false,
      isPricesAlteredAllowed: false,
      otherComments: false,
      liabilityCert: false,
      gasCert: false,
      otherUploads: false,
    },
    validate,
    onSubmit: (values) => {
      const formData = new FormData();

      const raw = {
        email: values.email,
        operator_id: JSON.parse(getFromLocalStorage("Operator"))[0].id,
        title: values.stallName,
        hometown: values.city,
        bio: values.stallDescription,
        logo: values.logoFile[0],
        location: "SRID=4326;POINT (144.5929945 -37.8969398)",
        accepting_orders: true,
        meals: JSON.stringify(values.menu),
        abn_number: values.abnNumber,
        site_layout: values.siteLayoutFile[0],
        truck_width: parseFloat(values.vansWidth).toFixed(2),
        truck_depth: parseFloat(values.vansDepth).toFixed(2),
        truck_height: parseFloat(values.vansHeight).toFixed(2),
        truck_exit: values.exitDoorPos,
        is_cooler_required: values.isCoolerRequired,
        cooler_width: parseFloat(values.coolRoomWidth).toFixed(2),
        cooler_depth: parseFloat(values.coolRoomDepth).toFixed(2),
        fire_source: values.fireSource,
        water_access: values.waterAccess,
        number_of_10_amp: values.numberOf10Amp,
        number_of_15_amp: values.numberOf15Amp,
        number_of_20_amp: values.numberOf20Amp,
        number_of_32_amp: values.numberOf32Amp,
        is_prices_altered_allowed: values.isPricesAlteredAllowed,
        other_comments: values.otherComments,
        electricial_certificate: values.electricalCert[0],
        liablity_certificate: values.liabilityCert[0],
        gas_certificate: values.gasCert[0],
        other_uploads: values.otherUploads[0],
        food_registration_docs: values.foodReg[0],
      };

      Object.entries(raw).map((item) => {
        formData.append(`${item[0]}`, item[1]);
      });

      if (itemDetail) {
        const updateUrl = config.BASE_URI + apiPaths.updateVendors;
        const requestOptions = {
          method: "POST",
          body: formData,
          redirect: "follow",
        };
        setLoading(true);
        try {
          fetch(updateUrl, requestOptions)
            .then((response) => response.json())
            .then((response) => {
              setLoading(false);
              if (response.success == true) {
                alert("Vendor has been updated successfully!!");
                handleClose();
              } else {
                alert("Sorry Vendor could not be updated, Please Try Again");
                handleClose();
              }
            });
        } catch (error) {
          setLoading(false);
          Sentry.captureException(error);
          console.error(error);
          alert("Vendor has been updated successfully!!");
          handleClose();
        }
      } else {
        const requestOptions = {
          method: "POST",
          body: formData,
          redirect: "follow",
        };

        setLoading(true);
        try {
          fetch(url, requestOptions)
            .then((response) => response.json())
            .then((response) => {
              if (response.success == true) {
                alert("Stall has been added successfully!!");
                handleClose();
                var url = config.BASE_URI + apiPaths.adminData;
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                var raw = JSON.stringify({
                  payload: {
                    body: {
                      query_type: "admin_login",
                      email: JSON.parse(getFromLocalStorage("Operator"))[0]
                        .email,
                      password: JSON.parse(getFromLocalStorage("Operator"))[0]
                        .password,
                    },
                  },
                });
                var requestOptions = {
                  method: "POST",
                  headers: myHeaders,
                  body: raw,
                  redirect: "follow",
                };

                fetch(url, requestOptions)
                  .then((response) => response.json())
                  .then((dataResponse) => {
                    setLoading(false);
                    if (
                      dataResponse.success == true &&
                      dataResponse.data[0].is_operator == true
                    ) {
                      saveToLocalStorage(
                        "Operator",
                        JSON.stringify(dataResponse.data)
                      );
                    }
                  })
                  .catch((error) => {
                    setLoading(false);
                    Sentry.captureException(error);
                    console.error(error);
                  })
                  .finally(() => {
                    setLoading(false);
                    // Finally, reload the page
                    if (getFromLocalStorage("isOperatorLoggedIn") === "false") {
                      // Redirect to "/operator-login" if the condition is met
                      window.location.href = "/operator-login";
                    } else {
                      // Redirect to "/apply-events/home" if the condition is not met
                      window.location.href = "/apply-events/home";
                    }
                  });
              } else {
                setLoading(false);
                alert(
                  "Stall couldn't be created Please contact hello@wherethetruck.com.au."
                );
              }
            });
        } catch (error) {
          setLoading(false);
          Sentry.captureException(error);
          console.error(error);
          alert(
            "Stall couldn't be created Please contact hello@wherethetruck.com.au."
          );
        }
      }
    },

    // validateOnBlur: false,
    // validateOnChange: false,
  });
  // const vendorDetails = (event) => {
  //   var url = config.BASE_URI + apiPaths.adminData;
  //   var myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");

  //   var raw = JSON.stringify({
  //     payload: {
  //       body: {
  //         query_type: "fetch_vendor_details",
  //         vendor_id: JSON.parse(getFromLocalStorage("Operator"))[0].id,
  //       },
  //     },
  //   });
  //   var requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: "follow",
  //   };

  //   fetch(url, requestOptions)
  //     .then((response) => response.json())
  //     .then((dataResponse) => {
  //       debugger;
  //       if (
  //         dataResponse.success == true &&
  //         dataResponse.data[0].is_operator == true
  //       ) {
  //         saveToLocalStorage("Operator", JSON.stringify(dataResponse.data));
  //       }
  //     })
  //     .catch((error) => {
  // Sentry.captureException(error);
  // console.error(error);
  //     })
  //     .finally(() => {
  //       // Finally, reload the page
  //       // window.location.href = '/apply-events';
  //     });
  // };
  return (
    <Modal size={"lg"} show={show} onHide={handleClose}>
      {!viewOnly ? (
        <Modal.Header closeButton>
          <Modal.Title>REGISTER STALL TO EVENT</Modal.Title>
        </Modal.Header>
      ) : (
        <Modal.Header closeButton>
          <Modal.Title>Vendor Information</Modal.Title>
        </Modal.Header>
      )}

      <form onSubmit={formik.handleSubmit} style={{ position: "relative" }}>
        <Loader loading={loading} />
        <Modal.Body>
          <Accordion key="1">
            <StallDetails formik={formik} viewOnly={viewOnly} />
          </Accordion>

          <Accordion key="2">
            <StallRequirements formik={formik} viewOnly={viewOnly} />
          </Accordion>

          <Accordion key="3">
            <WaterRequirements formik={formik} viewOnly={viewOnly} />
          </Accordion>

          <Accordion key="4">
            <PowerRequirements formik={formik} viewOnly={viewOnly} />
          </Accordion>

          <Accordion key="5">
            <ElectricalAppliance formik={formik} viewOnly={viewOnly} />
          </Accordion>

          <Accordion key="6">
            <MenuItems formik={formik} viewOnly={viewOnly} />
          </Accordion>

          <Accordion key="7">
            <OtherComments formik={formik} viewOnly={viewOnly} />
          </Accordion>

          <Accordion key="8">
            <OtherUploads formik={formik} viewOnly={viewOnly} />
          </Accordion>
        </Modal.Body>

        {!viewOnly && (
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                handleClose();
              }}
            >
              Close
            </Button>
            <Button type="submit" variant="primary">
              Submit
            </Button>
          </Modal.Footer>
        )}
      </form>
    </Modal>
  );
};

export default RegModal;
