import React, { Component } from "react";
import { connect } from "react-redux";
import AdminNavBar from "../../components/UI/AdminNavBar";
import Footer from "../../components/UI/Footer";
import * as Sentry from "@sentry/react";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import {
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import {
  FormControl,
  InputGroup,
  Container,
  Button,
  Row,
  Col,
  Form,
  Modal,
  Card,
} from "react-bootstrap";
import moment from "moment";
import TextField from "@material-ui/core/TextField";

import PlacesAutocomplete from "react-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { apiPaths } from "../../services/apiPath";
import config from "../../services/apiConfig";
import { BUSY_TIMES } from "./utils";
import ImageUploader from "react-images-upload";
import { ShareSocial } from "react-share-social";
import Loader from "../../components/UI/Loader";
import VendorNavBar from "../VendorApplyEventScreen/Component/VendorNavBar";
class AdminCreateEventScreen extends Component {
  state = {
    isSubmitting: false,
    searchVendor: "",
    isOperatorLoggedIn:
      getFromLocalStorage("isOperatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isOperatorLoggedIn") != "" &&
      getFromLocalStorage("isOperatorLoggedIn") != null
        ? getFromLocalStorage("isOperatorLoggedIn")
        : "false",
    isAccountantLoggedIn:
      getFromLocalStorage("isAccountantLoggedIn") != "undefined" &&
      getFromLocalStorage("isAccountantLoggedIn") != "" &&
      getFromLocalStorage("isAccountantLoggedIn") != null
        ? getFromLocalStorage("isAccountantLoggedIn")
        : "false",
    isStockAdminLoggedIn:
      getFromLocalStorage("isStockAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isStockAdminLoggedIn") != "" &&
      getFromLocalStorage("isStockAdminLoggedIn") != null
        ? getFromLocalStorage("isStockAdminLoggedIn")
        : "false",
    isEventCoordinatorLoggedIn:
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != null
        ? getFromLocalStorage("isEventCoordinatorLoggedIn")
        : "false",
    email:
      getFromLocalStorage("email") != "undefined" &&
      getFromLocalStorage("email") != "" &&
      getFromLocalStorage("email") != null
        ? getFromLocalStorage("email")
        : "",
    password:
      getFromLocalStorage("password") != "undefined" &&
      getFromLocalStorage("password") != "" &&
      getFromLocalStorage("password") != null
        ? getFromLocalStorage("password")
        : "",
    startDate:
      getFromLocalStorage("startDate") != "undefined" &&
      getFromLocalStorage("startDate") != "" &&
      getFromLocalStorage("startDate") != null &&
      getFromLocalStorage("startDate").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("startDate"))
        : new Date(),
    endDate:
      getFromLocalStorage("endDate") != "undefined" &&
      getFromLocalStorage("endDate") != "" &&
      getFromLocalStorage("endDate") != null &&
      getFromLocalStorage("endDate").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("endDate"))
        : new Date(),
    eventName: "",
    eventType: "public",
    eventStatus: "active",
    siteArea: 0,
    allSites: [],
    eventStartDateTime: "",
    eventEndDateTime: "",
    busyStartTime: "02:00 AM",
    busyEndTime: "02:00 AM",
    eventLocation: "",
    coverImage: "",
    onlineOrdering: true,
    selectedVendorList: [],
    selectedVendorName: [],
    vendorList: [],
    tempVendorList: [],
    eventDescription: "",
    coverImage: [],
    showSuccess: false,
    eventId: -1,
    errorArray: [],
    nonMembersCommisiion: "0",
    membersCommission: "0",
    siteFees: "0",
  };

  handleChange = (eventLocation) => {
    const { dispatch } = this.props;
    this.setState({ eventLocation: eventLocation }, () => {});
  };

  handleSelect = (eventLocation) => {
    const { dispatch } = this.props;
    geocodeByAddress(eventLocation)
      .then((results) => {
        this.setState(
          { eventLocation: results[0].formatted_address },
          () => {}
        );
        getLatLng(results[0]);
      })
      .then((latLng) => {})
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };

  onDrop = (pictureFiles, pictureDataURLs) => {
    this.setState(
      {
        coverImage: pictureDataURLs,
      },
      () => {}
    );
  };

  getAllVendors = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.adminData;
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_operator_details_for_stall_ids",
          operator_id: JSON.parse(getFromLocalStorage("Operator"))[0].id,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.success == true) {
          this.setState({
            vendorList: response.stalls,
            tempVendorList: response.stalls,
          });
        }
      });
  };

  getVendorsInEvent = () => {
    const {
      email,
      password,
      selectedEventId,
      isOperatorLoggedIn,
      isSuperAdminLoggedIn,
      selectedVendorId,
    } = this.state;
    this.setState({ isLoading: false });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    if (isOperatorLoggedIn == true) {
    } else {
      var raw = JSON.stringify({
        payload: {
          body: {
            query_type: "get_all_site_areas",
          },
        },
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((dataResponse) => {
          console.log(dataResponse.vendors);
          this.setState({ allSites: dataResponse.vendors }, () => {});
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.error(error);
          this.setState({ isLoading: false });
        });
    }
  };

  logout = () => {
    deleteFromLocalStorage("isSuperAdminLoggedIn", "false");
    deleteFromLocalStorage("isEventCoordinatorLoggedIn", "false");
    deleteFromLocalStorage("isAccountantLoggedIn", "false");
    deleteFromLocalStorage("isStockAdminLoggedIn", "false");
    this.setState({ isEventCoordinatorLoggedIn: "false" });
    this.setState({ isAccountantLoggedIn: "false" });
    this.setState({ isStockAdminLoggedIn: "false" });
    this.setState({ isSuperAdminLoggedIn: "false" });
    window.location.reload();
  };

  saveEvent = () => {
    this.setState({ isSubmitting: true });
    const {
      eventStartDateTime,
      eventEndDateTime,
      busyStartTime,
      busyEndTime,
      eventLocation,
      eventName,
      eventType,
      eventStatus,
      siteArea,
      allSites,
      coverImage,
      onlineOrdering,
      selectedVendorList,
      selectedVendorName,
      vendorList,
      eventDescription,
      membersCommission,
      nonMembersCommisiion,
      siteFees,
    } = this.state;

    if (
      eventType == 0 ||
      eventName == "" ||
      eventLocation == "" ||
      eventStartDateTime == "" ||
      eventEndDateTime == "" ||
      eventStatus == 0 ||
      selectedVendorList.length <= 0
    ) {
      alert("Please enter all the required fields");
      this.setState({ isSubmitting: false });
      return;
    }
    if (siteArea == "0") {
      alert("Are you sure you don't want to select the site area?");
    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.adminData;
    var stringVendor = selectedVendorList.map((i) => i.toString());
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "save_event",
          event_name: eventName,
          event_type: eventType,
          event_status: eventStatus,
          site_area: siteArea,
          start_date_time: eventStartDateTime,
          end_date_time: eventEndDateTime,
          busy_start_time: busyStartTime,
          busy_end_time: busyEndTime,
          event_location: eventLocation,
          description: eventDescription,
          cover_image: coverImage,
          vendors: stringVendor,
          members_commission: membersCommission,
          non_members_commission: nonMembersCommisiion,
          site_fees: siteFees,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((response) => {
          if (response.success == true) {
            this.setState({
              showSuccess: true,
              eventId: response.eventId,
              errorArray: response.menu_error,
            });
          }
          this.setState({ isSubmitting: false });
        });
    } catch (error) {
      this.setState({ isSubmitting: false });
      Sentry.captureException(error);
      console.error(error);
    }
  };

  componentDidMount() {
    const { isOperatorLoggedIn } = this.state;
    
      this.getAllVendors();
      this.getVendorsInEvent();
    
  }

  render() {
    const {
      
      isOperatorLoggedIn,
     
    } = this.state;
    return (
      <div>
        <VendorNavBar
         
        
        ></VendorNavBar>
         {this.renderHome()}
      </div>
    );
  }

  renderHome() {
    const {
      eventStartDateTime,
      searchVendor,
      eventEndDateTime,
      busyStartTime,
      busyEndTime,
      eventLocation,
      siteArea,
      allSites,
      eventName,
      eventType,
      eventStatus,
      coverImage,
      onlineOrdering,
      selectedVendorList,
      selectedVendorName,
      vendorList,
      eventDescription,
      nonMembersCommisiion,
      membersCommission,
      siteFees,
    } = this.state;
    return (
      <>
        <Container
          style={{
            borderWidth: 1,
            borderColor: "grey",
            borderStyle: "solid",
            borderRadius: 5,
            padding: 15,
            position: "relative",
          }}
        >
          <Loader loading={this.state.isSubmitting} />
          <Row>
            <Col xs={12} md={12} xs={12}>
              <p>
                Enter the event details in the form below to create the event
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} xs={12}>
              <Form>
                <Form.Group className="mb-3" controlId="formEventName">
                  <Form.Label>*Event Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Event Name"
                    value={eventName}
                    onChange={(e) => {
                      this.setState({ eventName: e.target.value });
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formEventType">
                  <Form.Label>*Event Type</Form.Label>
                  <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                    <Form.Control
                      as="select"
                      size="sm"
                      custom
                      value={eventType}
                      onChange={(e) => {
                        this.setState({ eventType: e.target.value });
                      }}
                    >
                      <option value={0}>Select Type</option>
                      <option value={"public"}>Public</option>
                      <option value={"private"}>Private</option>
                      <option value={"ftims"}>Food Truck In My Street</option>
                      <option value={"catering"}>Catering</option>
                    </Form.Control>
                  </Form.Group>
                  {/* <Form.Text className="text-muted">
                                        We'll never share your email with anyone else.
                                    </Form.Text> */}
                </Form.Group>
                <Form.Group className="mb-3" controlId="formEventStatus">
                  <Form.Label>Site Area</Form.Label>
                  <Form.Control
                    as="select"
                    size="sm"
                    custom
                    value={this.state.siteArea}
                    onChange={(e) => {
                      console.log(e.target.value);
                      this.setState({ siteArea: e.target.value });
                    }}
                  >
                    <option value="0">Select a Site</option>
                    {allSites && allSites.length > 0 ? (
                      allSites.map((site) => (
                        <option key={site.id} value={site.id}>
                          {site.site_name}
                        </option>
                      ))
                    ) : (
                      <option disabled>No sites available</option>
                    )}
                  </Form.Control>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formStartTime">
                  <Form.Label>*Start Date and Time</Form.Label>
                  <form noValidate>
                    <TextField
                      id="datetime-local"
                      type="datetime-local"
                      value={
                        eventStartDateTime &&
                        eventStartDateTime != null &&
                        eventStartDateTime.toString()
                      }
                      onChange={(event) => {
                        this.setState(
                          { eventStartDateTime: event.target.value },
                          () => {
                            var end = moment(
                              this.state.eventStartDateTime,
                              "YYYY-MM-DDTHH:mm"
                            ).add(1, "hours");
                            end = moment(end).format("YYYY-MM-DDTHH:mm");
                          }
                        );
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </form>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formStartTime">
                  <Form.Label>*End Date and Time</Form.Label>
                  <form noValidate>
                    <TextField
                      id="datetime-local"
                      type="datetime-local"
                      value={
                        eventEndDateTime &&
                        eventEndDateTime != null &&
                        eventEndDateTime.toString()
                      }
                      onChange={(event) => {
                        this.setState(
                          { eventEndDateTime: event.target.value },
                          () => {}
                        );
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </form>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBusyStartTime">
                  <Form.Label>*Busy Start Time</Form.Label>
                  <Form.Text className="text-muted">
                    Select the start time from when you don't want online orders
                    coming in
                  </Form.Text>
                  <Form.Control
                    as="select"
                    value={busyStartTime}
                    onChange={(e) =>
                      this.setState({ busyStartTime: e.target.value })
                    }
                  >
                    {BUSY_TIMES.map((time) => {
                      return <option value={time}>{time}</option>;
                    })}
                  </Form.Control>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBusyEndTime">
                  <Form.Text className="text-muted">
                    Select the end time after which the online order start to
                    come in again
                  </Form.Text>
                  <Form.Label>*Busy End Time</Form.Label>
                  <Form.Text className="text-muted">
                    Press and hold Ctrl/Cmd button on your keyboard to select
                    multiple
                  </Form.Text>
                  <Form.Control
                    as="select"
                    value={busyEndTime}
                    onChange={(e) =>
                      this.setState({ busyEndTime: e.target.value })
                    }
                  >
                    {BUSY_TIMES.map((time) => {
                      return <option value={time}>{time}</option>;
                    })}
                  </Form.Control>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formEventName">
                  <Form.Label>*Location</Form.Label>
                  <PlacesAutocomplete
                    value={eventLocation}
                    onChange={this.handleChange}
                    onSelect={this.handleSelect}
                    searchOptions={{
                      componentRestrictions: { country: "au" },
                    }}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <input
                          {...getInputProps({
                            placeholder: "Enter event location ",
                            className: "location-search-input",
                          })}
                        />
                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? {
                                  backgroundColor: "#fafafa",
                                  cursor: "pointer",
                                }
                              : {
                                  backgroundColor: "#ffffff",
                                  cursor: "pointer",
                                };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </Form.Group>
                <Form.Group
                  as={Col}
                  controlId="my_multiselect_field"
                  style={{ paddingLeft: 0 }}
                >
                  <Form.Label>*Participating Vendors</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text value={searchVendor}>
                      <img
                        style={{ width: 20 }}
                        src={require("../../assets/img/search.gif")}
                      ></img>
                    </InputGroup.Text>
                    <FormControl
                      placeholder="Search for vendors"
                      aria-label="Search for vendors"
                      onChange={(e) => {
                        var searchString = e.target.value;
                        if (searchString == "") {
                          this.setState({
                            vendorList: this.state.tempVendorList,
                          });
                        } else {
                          var result = vendorList.filter((obj) => {
                            if (
                              obj.title.search(new RegExp(searchString, "i")) !=
                              -1
                            ) {
                              return obj;
                            }
                          });
                          this.setState({ vendorList: result });
                        }
                      }}
                    />
                  </InputGroup>
                  {/* {selectedVendorName.length > 0 ? (
                    <Row style={{ marginTop: 10, marginBottom: 10 }}>
                      {selectedVendorName.map((vendorName) => {
                        return (
                          <Col style={{ marginTop: 10 }} xs={4} md={4} lg={4}>
                            <div
                              style={{
                                borderWidth: 1,
                                borderStyle: "solid",
                                borderColor: "black",
                              }}
                            >
                              {vendorName}
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  ) : null}                      */}
                  <Form.Check
                    type="checkbox"
                    id="clear"
                    label="Clear"
                    checked={
                      this.state.selectedVendorList &&
                      this.state.tempVendorList &&
                      this.state.selectedVendorList.length === 0
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        this.setState({ selectedVendorList: [] });
                      }
                    }}
                  />
                  <div style={{ height: "200px", overflowY: "auto" }}>
                    {this.state.vendorList.map((vendor) => (
                      <Form.Check
                        key={vendor.user_ptr_id}
                        type="checkbox"
                        id={`vendor-${vendor.user_ptr_id}`}
                        label={vendor.title}
                        checked={
                          this.state.selectedVendorList &&
                          this.state.vendorList &&
                          this.state.selectedVendorList.includes(
                            vendor.user_ptr_id
                          )
                        }
                        onChange={(e) => {
                          const vendorId = vendor.user_ptr_id;
                          const vendorName = vendor.title;
                          const selectedVendorList = this.state.selectedVendorList.slice();
                          if (e.target.checked) {
                            selectedVendorList.push(vendorId);
                            selectedVendorName.push(vendorName);
                          } else {
                            const index = selectedVendorList.indexOf(vendorId);
                            if (index >= 0) {
                              selectedVendorList.splice(index, 1);
                            }
                          }
                          this.setState({ selectedVendorList });
                        }}
                      />
                    ))}
                  </div>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={eventDescription}
                    onChange={(e) => {
                      this.setState({ eventDescription: e.target.value });
                    }}
                  />
                </Form.Group>

                {/* <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Members Commission</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Members Commission"
                    value={membersCommission}
                    onChange={(e) => {
                      this.setState({ membersCommission: e.target.value });
                    }}
                  />
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Site Fees</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Site Fees"
                    value={siteFees}
                    onChange={(e) => {
                      this.setState({ siteFees: e.target.value });
                    }}
                  />
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Non Members Commission</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Non Members Commission rate"
                    value={nonMembersCommisiion}
                    onChange={(e) => {
                      this.setState({ nonMembersCommisiion: e.target.value });
                    }}
                  />
                </Form.Group> */}

                <Form.Group>
                  <p>Upload cover photo for the event</p>
                  <ImageUploader
                    singleImage={true}
                    withIcon={true}
                    buttonText="Choose image"
                    onChange={(pictureFiles, pictureDataURLs) =>
                      this.onDrop(pictureFiles, pictureDataURLs)
                    }
                    withPreview={true}
                    imgExtension={[".jpg", ".jpeg", ".png"]}
                    maxFileSize={5242880}
                    label={"Upload cover photo. Max img size 5mb"}
                    buttonStyles={{
                      backgroundColor: "#2859a5",
                      color: "white",
                      fontWeight: "unset",
                    }}
                  />
                </Form.Group>
                <Button
                  variant="primary"
                  type="button"
                  onClick={() => {
                    this.saveEvent();
                  }}
                  disabled={this.state.isSubmitting} // Disable button when isSubmitting is true
                >
                  {this.state.isSubmitting ? "Submitting..." : "Submit"}
                </Button>
              </Form>
            </Col>
          </Row>
          {this.renderSuccess()}
        </Container>
        <Footer />
      </>
    );
  }
  renderSuccess() {
    const { showSuccess, eventId, errorArray } = this.state;
    var url = window.location.href.replace("/admin-create-event", "");
    return (
      <>
        <Modal
          show={showSuccess}
          onHide={() => {
            window.location.reload();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Submitted Successfully</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Card>
              <Card.Body variant="bottom" style={{ padding: 5 }}>
                <Row>
                  <Col xs={12}>
                    <p>Event has been submitted successfully.</p>
                    {errorArray.length > 0 ? (
                      <>
                        <p style={{ color: "red" }}>Warning</p>
                        {errorArray.map((item) => {
                          return (
                            <p style={{ color: "red" }}>{item["message"]}</p>
                          );
                        })}
                      </>
                    ) : null}

                    <ShareSocial
                      style={{ padding: 0 }}
                      url={url + "/event:" + eventId}
                      socialTypes={["facebook", "email", "linkedin", "twitter"]}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Modal.Body>

          <Modal.Footer></Modal.Footer>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(AdminCreateEventScreen, {
    fallback: <ErrorFallbackComponent />,
  })
);
