import React, { useState } from "react";
import {
  Button,
  Fab,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";

import "./style.css";

const TableButton = ({ icon, backgroundColor, onClick, tooltip }) => {
  return (
    <Tooltip placement="top" title={tooltip}>
      <button
        className="table-button"
        style={{
          backgroundColor: backgroundColor,
        }}
        onClick={onClick}
      >
        {icon}
      </button>
    </Tooltip>
  );
};

const DataGrid = ({ data, setData, head, validate }) => {
  const [editMode, setEditMode] = useState("");
  const [changedValue, setChangedValue] = useState("");
  return (
    <TableContainer component={Paper}>
      {data && data.length ? (
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {head.map((headItem) => (
                <TableCell style={{ fontWeight: "bold" }} key={headItem}>
                  {headItem}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.length
              ? data.map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {head.map((head, cellIndex) => (
                      <TableCell
                        key={row[head]}
                        style={{
                          backgroundColor: !!row[head]
                            ? "transparent"
                            : "#FFCDD2",
                        }}
                      >
                        {editMode !== `${rowIndex},${cellIndex}` ? (
                          !row[head] ? (
                            <Tooltip
                              placement="top"
                              title="This field cannot be empty!"
                            >
                              <div
                                className="cell-container"
                                style={{
                                  justifyContent: row[head]
                                    ? "space-between"
                                    : "flex-end",
                                }}
                              >
                                {row[head] ?? ""}
                                <TableButton
                                  icon={<EditIcon />}
                                  onClick={() => {
                                    setEditMode(`${rowIndex},${cellIndex}`);
                                  }}
                                  tooltip="Edit"
                                  backgroundColor={"#2859a5"}
                                />
                              </div>
                            </Tooltip>
                          ) : (
                            <div
                              className="cell-container"
                              style={{
                                justifyContent: row[head]
                                  ? "space-between"
                                  : "flex-end",
                              }}
                            >
                              {row[head] ?? ""}
                              <TableButton
                                icon={<EditIcon />}
                                onClick={() => {
                                  setEditMode(`${rowIndex},${cellIndex}`);
                                }}
                                tooltip="Edit"
                                backgroundColor={"#2859a5"}
                              />
                            </div>
                          )
                        ) : (
                          <div className="cell-container">
                            <input
                              onChange={(e) => {
                                setChangedValue(e.target.value);
                              }}
                              defaultValue={row[head]}
                            />
                            <TableButton
                              icon={<SaveIcon />}
                              onClick={() => {
                                setData((st) => {
                                  const tempData = [...st];
                                  tempData[rowIndex][head] = changedValue;

                                  return [...tempData];
                                });
                                setEditMode("");
                                setChangedValue("");
                                validate();
                              }}
                              tooltip="Save Changes"
                              backgroundColor={"#5cb85c"}
                            />
                            <TableButton
                              icon={<CloseIcon />}
                              onClick={() => {
                                setEditMode("");
                                setChangedValue("");
                              }}
                              tooltip="Cancel"
                              backgroundColor={"#FF5959"}
                            />
                          </div>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      ) : null}
    </TableContainer>
  );
};
export default DataGrid;
